import { Flex } from 'antd'
import { useEffect } from 'react'
import { View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { delay } from '#components/utils/delay'
import { tw } from '#components/utils/tw'

type ModalProps = {
  title?: string
  content?: string
  closeModal: () => void
  timeDelay?: number
  autoClose?: boolean
  handleTryEasier?: () => void
  handleContinue?: () => void
  isButton: boolean
}

export const LevelNotificationModal = ({
  closeModal,
  autoClose,
  timeDelay = 2500,
  title,
  content,
  handleTryEasier,
  handleContinue,
  isButton = false,
}: ModalProps) => {
  const waitingCloseModal = async () => {
    await delay(timeDelay)
    closeModal()
  }

  useEffect(() => {
    if (autoClose) {
      waitingCloseModal()
    }
  }, [])

  return (
    <Flex
      style={tw.style(
        'bg-white mx-10 p-4 rounded-xl flex-col items-center justify-center relative',
      )}
    >
      <View style={tw.style('pt-9 pb-8')}>
        <SystemIcon name='document' type='SVG' size={92} />
      </View>
      <View style={tw.style('w-full flex items-center justify-center')}>
        <Text
          specialType='Headline2'
          textAlign='center'
          color={tw.color('primary-400')}
        >
          {title}
        </Text>
      </View>
      <View
        style={tw.style('mt-4 pb-10 w-full flex items-center justify-center')}
      >
        <Text specialType='Title' textAlign='center' color={tw.color('text-2')}>
          {content}
        </Text>
      </View>
      {isButton && (
        <View style={tw`flex-row gap-x-2`}>
          <View style={tw`flex-1`}>
            <Button
              onPress={handleContinue}
              tone='secondary'
              size='medium'
              style={tw`w-40`}
            >
              Continue
            </Button>
          </View>
          <View style={tw`flex-1`}>
            <Button
              onPress={handleTryEasier}
              size='medium'
              style={tw`w-40`}
              tone='primary'
            >
              Try easier lesson
            </Button>
          </View>
        </View>
      )}
    </Flex>
  )
}
