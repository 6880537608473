import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { Image } from '#components/base/Image'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { toastWarning } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { S } from '#store'
import type { SearchTopicItem } from '#types/topic'

import { ActionSheetTopics } from './ActionSheetTopics'

type Props = {
  item: SearchTopicItem
  index: number
  color: string
  label: string
}

export const ItemTopic = observer(({ item, index, color, label }: Props) => {
  const { height } = useWindowDimensions()
  const isFocus = useIsFocused()
  const { openActionsheet } = useOverlay()

  useEffect(() => {
    if (isFocus && index === 0) {
      S.shared.percentageOfTopic({ topicId: item.id })
    }
  }, [isFocus])

  const handleSubmit = async () => {
    openActionsheet(ActionSheetTopics, {
      topicId: item.id,
    })
  }

  const handleToast = () => {
    toastWarning({ message: 'Complete the prior topic to unlock' })
  }

  return (
    <View style={tw.style(label ? 'gap-3' : '')}>
      {label && (
        <View
          style={[
            tw`px-4 py-1.5 bg-background-light-white rounded-2xl mx-4 mb-1 flex-row gap-1 items-center`,
            { alignSelf: 'flex-start' },
          ]}
        >
          <SystemIcon type='SAX' name='Notepad2' color={color} />
          <Text specialType='paragraph2'>{label}</Text>
        </View>
      )}
      <View style={tw.style('flex-col px-4')}>
        <TouchableOpacity
          onPress={() => {
            index === 0 ? handleSubmit() : handleToast()
          }}
          style={tw.style(
            'flex-row items-center justify-between rounded-3xl relative w-full overflow-hidden',
            // { aspectRatio: index === 0 ? 16 / 9 : 8 / 3 },
            index === 0 ? { height: height * 0.462 } : { aspectRatio: 8 / 3 },
          )}
        >
          {item.media && (
            <View style={tw`flex-1 z-1`}>
              <Image
                source={{ uri: item.media.url }}
                style={tw.style(
                  'rounded-3xl',
                  // { aspectRatio: index === 0 ? 16 / 9 : 8 / 3,}
                  index === 0
                    ? { height: height * 0.462 }
                    : { aspectRatio: 8 / 3 },
                )}
                resizeMode='cover'
              />
            </View>
          )}
          {index === 0 ? (
            <View
              style={tw.style(
                'absolute top-0 left-0 right-0 bottom-0 z-2 rounded-3xl',
              )}
            >
              <LinearGradientColor
                colorX='rgba(0, 0, 0, 0.32) 0%'
                colorY='rgba(0, 0, 0, 0.80) 50%'
              />
              <View style={tw`absolute bottom-4 inset-x-4`}>
                <View style={tw`gap-2 mb-10.5`}>
                  <Text
                    numberOfLines={2}
                    specialType='Headline1'
                    style={tw.style('text-text-4')}
                  >
                    {item.name}
                  </Text>
                  <Text
                    numberOfLines={2}
                    specialType='paragraph2'
                    color={tw.color('text-3')}
                  >
                    {item.description.length > 80
                      ? item.description.slice(0, 80) + '...'
                      : item.description}
                  </Text>
                </View>
                <Button onPress={handleSubmit}>Start lesson</Button>
              </View>
            </View>
          ) : (
            <View
              style={tw.style(
                'absolute top-0 left-0 right-0 bottom-0 z-2 rounded-3xl',
              )}
            >
              <LinearGradientColor
                colorX='rgba(0, 0, 0, 0.80) 0.19%'
                colorY='rgba(0, 0, 0, 0.56) 99.81%'
                degree={90}
              />
              <View
                style={tw`flex-1 absolute top-0 right-0 bottom-0 left-0 p-4 flex-row items-center`}
              >
                <View style={tw`flex-1 mr-2`}>
                  <Text
                    specialType='Headline1'
                    style={tw`text-text-2 opacity-40`}
                  >
                    {item.name}
                  </Text>
                </View>
                <ButtonIcon
                  icon={{
                    name: 'Key',
                    type: 'SAX',
                    size: 28,
                    color: tw.color('neutral-75'),
                  }}
                  tone='secondary'
                  size='medium'
                  onPress={handleToast}
                />
              </View>
            </View>
          )}
        </TouchableOpacity>
      </View>
    </View>
  )
})
