import type { TasksItem } from '#graphql/codegen'
import type { Level } from '#types/language'

export type Task = {
  id: string
  name: string
  description: string
}

export type TaskItem = Task & {
  subTask: Task[]
}

export const TASK_LEVEL_A1: TasksItem[] = [
  {
    description: '',
    id: '01J9B415AD24J272FXJYSQN3YR',
    name: 'Introduce your family',
    subTask: [
      {
        description: '',
        id: '01J9B415ADFEJ7V7V17BATJCWN',
        name: 'Say how many people are in your family.',
      },
      {
        description: '',
        id: '01J9B415AD096X70F6QCANA5WC',
        name: 'Name the family members.',
      },
      {
        description: '',
        id: '01J9B415ADGJMETXRWYJB62GFF',
        name: 'Describe one thing about each member.',
      },
    ],
  },
  {
    description: '',
    id: '01J9B415ADAYAWXE5MZ53EXF45',
    name: 'Talk about what you do together.',
    subTask: [
      {
        description: '',
        id: '01J9B415ADAZ7ZKF08PHMRY62M',
        name: 'Describe an activity you do as a family.',
      },
      {
        description: '',
        id: '01J9B415ADXXM42978XFY7THVP',
        name: 'Name your favorite family activity.',
      },
    ],
  },
  {
    description: '',
    id: '01J9B415ADCYSSANJ0263R70D7',
    name: 'Describe a family event.',
    subTask: [
      {
        description: '',
        id: '01J9B415ADWQ4MYT1XE2AZ8G9K',
        name: 'Say what the event is. ',
      },
      {
        description: '',
        id: '01J9B415AD3PMWQ3H490JH3EM7',
        name: 'Mention who attends the event.',
      },
      {
        description: '',
        id: '01J9B415ADN3P0XCQ4BYFZ5WGF',
        name: 'Share one memory from the event.',
      },
    ],
  },
]

export const TASK_LEVEL_A2: TasksItem[] = [
  {
    description: '',
    id: '01J9BH97RRRNVKXNT8VJ7ZNHK6',
    name: 'Describe your morning routine.',
    subTask: [
      {
        description: '',
        id: '01J9BH97RRC3RCVAEWQXY0WN3N',
        name: 'Say what time you wake up.',
      },
      {
        description: '',
        id: '01J9BH97RR8JE0WVSASFEPHS4H',
        name: 'List two activities you do in the morning.',
      },
      {
        description: '',
        id: '01J9BH97RRRPRZ95ER8C1TV6Y7',
        name: 'Mention how long you spend on your morning routine.',
      },
    ],
  },
  {
    description: '',
    id: '01J9BH97RR9EZ1BYQEKEXJSRYV',
    name: 'Talk about your evening routine.',
    subTask: [
      {
        description: '',
        id: '01J9BH97RRGST8JNC08RYMTHRA',
        name: 'Describe what you do after dinner.',
      },
      {
        description: '',
        id: '01J9BH97RRMXFPHGB4Y35ZJ8YQ',
        name: 'Mention any hobbies you do in the evening.',
      },
    ],
  },
  {
    description: '',
    id: '01J9BH97RRFRM7FP4V1HJZ7HVT',
    name: 'Discuss your weekend routine.',
    subTask: [
      {
        description: '',
        id: '01J9BH97RR8R6E5WFWG49TGQ2M',
        name: 'Describe how you spend your Saturdays.',
      },
      {
        description: '',
        id: '01J9BH97RRH1G9GA924QCDGMS5',
        name: 'Mention one special activity you do on weekends.',
      },
    ],
  },
]

export const TASK_LEVEL_B1: TasksItem[] = [
  {
    description: '',
    id: '01J9BHF9N2E0S6D998C2S7WPK5',
    name: 'Talk about your hotel booking.',
    subTask: [
      {
        description: '',
        id: '01J9BHF9N2HB0TPYY1141C5R2X',
        name: 'Say how long you will stay.',
      },
      {
        description: '',
        id: '01J9BHF9N2W03DF7MKXTXPV41D',
        name: 'State how many people are staying with you.',
      },
      {
        description: '',
        id: '01J9BHF9N2FAPG5Q1ECW1FA5JG',
        name: 'Explain the reason for your stay.',
      },
    ],
  },
  {
    description: '',
    id: '01J9BHF9N2205X8RJQCJ212HV9',
    name: 'Describe your room preferences.',
    subTask: [
      {
        description: '',
        id: '01J9BHF9N2CPWS3YC1M4AGG0EW',
        name: 'Specify the type of room you want.',
      },
      {
        description: '',
        id: '01J9BHF9N2XPFBTA8MRG7WX5AD',
        name: 'Mention any specific requirements for the room.',
      },
      {
        description: '',
        id: '01J9BHF9N21DPYP30P4VF0D5KK',
        name: 'Discuss any additional services needed.',
      },
    ],
  },
  {
    description: '',
    id: '01J9BHF9N2DC9RXXAG0XZWAGXQ',
    name: 'Talk about hotel facilities.',
    subTask: [
      {
        description: '',
        id: '01J9BHF9N2XRYAZ5DSTQGJGQJY',
        name: 'Mention the facilities you want to use.',
      },
      {
        description: '',
        id: '01J9BHF9N259THP051V1PTFE8B',
        name: 'Describe your expectations for these facilities.',
      },
      {
        description: '',
        id: '01J9BHF9N24MK8BSW9XWE6N2GG',
        name: 'Ask about the opening hours for the gym.',
      },
    ],
  },
]

export const TASK_LEVEL_B2: TasksItem[] = [
  {
    description: '',
    id: '01J9BHH1TYSJM77TV87CHBP940',
    name: 'Compare two education systems.',
    subTask: [
      {
        description: '',
        id: '01J9BHH1TYNSQ4FTFQ69J55XHK',
        name: 'Identify the two countries you are comparing.',
      },
      {
        description: '',
        id: '01J9BHH1TYHZ3PSX23DTAFH60Z',
        name: 'Describe one strength of each system.',
      },
      {
        description: '',
        id: '01J9BHH1TZBJ0566PA9ZVJ3R2F',
        name: 'Share your opinion on which system is better and why.',
      },
    ],
  },
  {
    description: '',
    id: '01J9BHH1TZE6YS1K00F749P326',
    name: 'Discuss your personal education experience.',
    subTask: [
      {
        description: '',
        id: '01J9BHH1TZBBKY1H77QR94XVJ5',
        name: 'Explain your favorite subject in school.',
      },
      {
        description: '',
        id: '01J9BHH1TZCZQ9X1C4AV68QJKZ',
        name: 'Share a memorable experience from your education.',
      },
      {
        description: '',
        id: '01J9BHH1TZTZ7GQY57MGATAS7N',
        name: 'Discuss how your education has influenced your career choice.',
      },
    ],
  },
  {
    description: '',
    id: '01J9BHH1TZA44QYK135739TJ9G',
    name: 'Talk about future education goals.',
    subTask: [
      {
        description: '',
        id: '01J9BHH1TZMQCWH8CNPX2N6DFZ',
        name: 'Describe any further education you want to pursue.',
      },
      {
        description: '',
        id: '01J9BHH1TZBJV3Z62ECS1TYNCN',
        name: 'Explain why further education is important to you.',
      },
      {
        description: '',
        id: '01J9BHH1TZ0R86MKH5CHCGYJKM',
        name: 'Mention any challenges you might face in achieving these goals.',
      },
    ],
  },
]

export type TaskDataItem = {
  scenario: string
  task: TasksItem[]
  userRole: string
  aiTutorRole: string
}

export type TaskData = {
  [key in Level]: TaskDataItem
}

export const TASK_DATA: TaskData = {
  A1: {
    scenario:
      'You and your new friend are having a casual conversation at a family gathering. Your new friend is interested in getting to know your family better.',
    task: TASK_LEVEL_A1,
    userRole: 'A new friend',
    aiTutorRole: 'A family member',
  },
  A2: {
    scenario:
      'You (user) and the AI tutor are practicing daily routines in an English class.',
    task: TASK_LEVEL_A2,
    userRole: 'A student',
    aiTutorRole: 'English teacher',
  },
  B1: {
    scenario:
      "It's almost holiday time and you want to book a room for your trip. You are on the phone with the hotel staff",
    task: TASK_LEVEL_B1,
    userRole: 'Customer',
    aiTutorRole: 'Hotel receptionist',
  },
  B2: {
    scenario:
      'You are participating in a discussion panel about different education systems from around the world. You will share your thoughts and insights on the systems in two countries.',
    task: TASK_LEVEL_B2,
    userRole: 'Panelist',
    aiTutorRole: 'Discussion moderator',
  },
}
