import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { z } from 'zod'

import { Button } from '#components/base/Button/Button'
import { Text } from '#components/base/Text'
import { FormField } from '#components/form/FormField'
import { InputPassword } from '#components/form/InputPassword'
import { toastError, toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { BackgroundAuth } from '#components/widgets/BackgroundAuth'
import { WarningPassword } from '#components/widgets/WarningPassword'
import type { AuthStackScreenProps } from '#navigator/types'

import { resolveForgotPassword } from './onResolveForgotPassword'

const FormSchema = z.object({
  password: z
    .string({ required_error: 'Password is required' })
    .min(6, { message: 'Password must be at least 6 characters' }),
})

export type LoginFormValues = z.infer<typeof FormSchema>

export const NewPassword = ({
  navigation,
  route,
}: AuthStackScreenProps<'NewPassword'>) => {
  const { control, watch } = useForm<LoginFormValues>({
    resolver: zodResolver(FormSchema),
    shouldFocusError: true,
  })
  const passwordValue = watch('password')
  const [isCheckPass, setIsCheckPass] = useState<boolean>(false)
  const id = route.params.id
  const secretKey = route.params?.secret
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      const response = await resolveForgotPassword(secretKey, id, passwordValue)
      setIsLoading(false)
      if (response) {
        navigation.pop(3)
        toastSuccess({
          message: 'You can login again with a new password',
        })
      } else {
        toastError({
          message: 'Password change unsuccessful',
        })
      }
    } catch (error) {
      toastError({
        message: 'Password change unsuccessful',
      })
    }
  }

  return (
    <BackgroundAuth isHeader>
      <View style={tw`items-center pt-8`}>
        <View style={tw`items-center gap-2 pb-8`}>
          <Text textAlign='center' specialType='Headline2'>
            New password
          </Text>

          <Text
            textAlign='center'
            specialType='Title'
            color={tw.color('text-2')}
          >
            Create a new password that is safe and easy to remember
          </Text>
        </View>
      </View>
      <View style={tw`gap-4 mb-12`}>
        <FormField name='password' control={control}>
          <InputPassword autoFocus onSubmitEditing={handleConfirm} />
        </FormField>
        <Button
          loading={isLoading}
          disabled={!isCheckPass}
          onPress={handleConfirm}
        >
          Continue
        </Button>
      </View>
      <WarningPassword
        password={passwordValue || ''}
        onAllConditionsMet={setIsCheckPass}
      />
    </BackgroundAuth>
  )
}
