import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { gql } from '#graphql/urql'
import { initRouteStack } from '#navigator/helpers'
import { onAppInit } from '#screens/App/onAppInit'

export const onLoginSubmit = async (values: {
  email: string
  password: string
}) => {
  const r = await gql.login(values)
  if (r.error || !r.data || r.data?.login?.user?.deactivate) {
    toastError({
      title: 'Error',
      message: r.data?.login?.user?.deactivate
        ? 'Your account has been locked'
        : r.error?.message,
    })
    return
  }
  if (r.data.login.user?.isAdmin) {
    await onAppInit({
      currentAuthSession: r.data.login,
    })
    await initRouteStack('Admin', { screen: 'AdminUsers' })
    toastSuccess({ message: 'Log in Success' })
  } else {
    toastError({ message: 'This account does not have permission' })
  }
}
