import { Form, Input, InputNumber, Select } from 'antd'
import type React from 'react'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import type { CurrencyCode, IntervalTime } from '#graphql/codegen'
import { useSearchPrice } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { currencyOption } from '#types/payment'
import type { SearchProductItems } from '#types/product'

type Prop = {
  selectedProduct?: SearchProductItems
  closeModal: () => void
  onOk: () => void
}

export const PaymentModal = ({ selectedProduct, closeModal, onOk }: Prop) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>(selectedProduct?.name ?? '')
  const [active, setActive] = useState({
    value: selectedProduct?.active ? 'True' : 'False',
    label: selectedProduct?.active ? 'True' : 'False',
  })
  const [price, setPrice] = useState<number>(
    selectedProduct?.price?.unitAmount ?? 0,
  )
  const [currency, setCurrency] = useState<{
    label: CurrencyCode
    value: CurrencyCode
  }>({
    label: (selectedProduct?.price?.currency as CurrencyCode) ?? 'USD',
    value: (selectedProduct?.price?.currency as CurrencyCode) ?? 'USD',
  })

  const [timeUnit, setTimeUnit] = useState<{
    label: IntervalTime
    value: IntervalTime
  }>({
    label:
      (selectedProduct?.price?.recurring?.interval as IntervalTime) ?? 'month',
    value:
      (selectedProduct?.price?.recurring?.interval as IntervalTime) ?? 'month',
  })

  const [amountTime, setAmountTime] = useState<number>(
    selectedProduct?.price?.recurring?.interval_count ?? 1,
  )

  const [searchPrice] = useSearchPrice({
    variables: {
      filter: { product: selectedProduct?.idProduct },
      order: ['type_asc'],
      page: {},
    },
  })

  const dataSearchPrice = searchPrice.data?.searchPrice ?? []

  const [discount, setDiscount] = useState(0)
  const [description, setDescription] = useState(selectedProduct?.description)

  useEffect(() => {
    if (dataSearchPrice[1] && dataSearchPrice[1]?.unitAmount !== 0) {
      setDiscount(
        ((dataSearchPrice[1]?.unitAmount - dataSearchPrice[0]?.unitAmount) /
          dataSearchPrice[1]?.unitAmount) *
          100 ?? 0,
      )
    }
  }, [dataSearchPrice])

  const handleModalOk = async () => {
    setLoading(true)
    try {
      let res
      if (selectedProduct) {
        res = await gql.updateProduct({
          id: selectedProduct.id,
          data: {
            name,
            description,
            active: active.value === 'Active',
          },
        })
        // await gql.updatePrice({
        //   id: selectedProduct.price?.id ?? '',
        //   data: {
        //     price_id: selectedProduct.price?.idPrice ?? '',
        //     // currency: currency.value,
        //     // unit_amount: price,
        //     recurring: {
        //       interval: timeUnit.value,
        //       interval_count: amountTime,
        //     },
        //   },
        // })
      } else {
        res = await gql.createProduct({
          data: {
            name,
            description,
          },
        })
        await gql.createPrice({
          data: {
            product: res.data?.createProduct.idProduct ?? '',
            currency: currency.value,
            unit_amount: price * (1 + discount / 100),
            recurring: {
              interval: timeUnit.value,
              interval_count: amountTime,
            },
          },
        })
        await gql.createPrice({
          data: {
            product: res.data?.createProduct.idProduct ?? '',
            currency: currency.value,
            unit_amount: price,
            recurring: {
              interval: timeUnit.value,
              interval_count: amountTime,
            },
          },
        })
      }

      if (res.error || !res.data) {
        toastError({ title: 'Error', message: res.error?.message })
        setLoading(false)
        return
      }
      toastSuccess({
        message: 'Update name successfully',
      })
      onOk()
      closeModal()
    } catch (error) {
      toastError({ message: 'Failed to handle name' })
    }
    setLoading(false)
  }

  const handleModalCancel = () => {
    closeModal()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && name && !loading) {
      handleModalOk()
    }
  }

  return (
    <ModalLayout
      title={selectedProduct ? 'Edit name' : 'Add new name'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={!name || loading}
      autoClose={false}
      isLoading={loading}
    >
      <Form layout='vertical' onKeyUp={handleKeyPress} style={tw`w- pt-2`}>
        <Form.Item label='Name'>
          <Input
            value={name}
            onChange={e => {
              setName(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item label='Description'>
          <Input
            value={description}
            onChange={e => {
              setDescription(e.target.value)
            }}
            style={tw`w-full`}
          />
        </Form.Item>
        {selectedProduct && (
          <Form.Item label='Status'>
            <Select
              value={active.value}
              options={[
                { value: 'Active', label: 'Active' },
                { value: 'Pending', label: 'Pending' },
              ]}
              onChange={e => {
                setActive({ value: e, label: e })
              }}
            />
          </Form.Item>
        )}
        <Form.Item label='Price'>
          <InputNumber
            value={price}
            onChange={e => {
              if (e) {
                setPrice(e)
              }
            }}
            disabled={!!selectedProduct}
            style={tw`w-full`}
          />
        </Form.Item>
        <Form.Item label='Currency'>
          <Select
            value={currency}
            labelInValue
            options={currencyOption}
            disabled={!!selectedProduct}
            onChange={e => {
              setCurrency({ value: e.value, label: e.label })
            }}
          />
        </Form.Item>
        <Form.Item label='Discount percent (%)'>
          <InputNumber
            value={discount}
            onChange={e => {
              if (e) {
                setDiscount(e)
              }
            }}
            disabled={!!selectedProduct}
            style={tw`w-full`}
          />
        </Form.Item>
        <Form.Item label='Time unit'>
          <Select
            value={timeUnit}
            labelInValue
            disabled={!!selectedProduct}
            options={[
              { value: 'month', label: 'month' },
              { value: 'year', label: 'year' },
            ]}
            onChange={e => {
              setTimeUnit({ value: e.value, label: e.label })
            }}
          />
        </Form.Item>
        <Form.Item label='Amount of time'>
          <InputNumber
            value={amountTime}
            min={1}
            onChange={e => {
              if (e) {
                setAmountTime(e)
              }
            }}
            disabled={!!selectedProduct}
            style={tw`w-full`}
          />
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
