import type { FC } from 'react'
import { useEffect } from 'react'
import { View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'

import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useDimensions } from '#components/utils/useDimensions'
import type { GameQuestionProps } from '#types/games'

export const SameOrDifferentQuestion: FC<GameQuestionProps> = ({
  question,
  onSwipe,
  onEndSwipe,
  currentIndex,
}) => {
  const { swipeThreshold, maxWidth, screenWidth, height } = useDimensions({
    padding: 16,
  })

  const maxHeight = height * (height < 670 ? 0.45 : 0.55)

  const translateX = useSharedValue(0)
  const rotation = useSharedValue(0)
  const cardOpacity = useSharedValue(1)

  useEffect(() => {
    translateX.value = withSpring(0)
    rotation.value = withSpring(0)
    cardOpacity.value = withSpring(1)
  }, [currentIndex])

  const panGesture = Gesture.Pan()
    .onUpdate(event => {
      translateX.value = event.translationX
      rotation.value = interpolate(
        translateX.value,
        [-screenWidth / 2, 0, screenWidth / 2],
        [-50, 0, 50],
      )
      if (translateX.value < -10) {
        onSwipe?.('left')
      } else if (translateX.value > 10) {
        onSwipe?.('right')
      } else {
        onSwipe?.('none')
      }
    })
    .onEnd(() => {
      if (translateX.value < -swipeThreshold) {
        cardOpacity.value = withSpring(0, {}, () => {
          runOnJS(onSwipeLeft)()
        })
      } else if (translateX.value > swipeThreshold) {
        cardOpacity.value = withSpring(0, {}, () => {
          runOnJS(onSwipeRight)()
        })
      } else {
        translateX.value = withSpring(0)
        rotation.value = withSpring(0)
        cardOpacity.value = withSpring(1)
        onSwipe?.('none')
      }
    })

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        { translateX: translateX.value },
        { rotate: `${rotation.value}deg` },
      ],
      opacity: cardOpacity.value,
    }),
    [translateX, rotation, cardOpacity],
  )

  const onSwipeLeft = () => {
    onEndSwipe?.('left')
  }
  const onSwipeRight = () => {
    onEndSwipe?.('right')
  }

  return (
    <GestureDetector gesture={panGesture}>
      <Animated.View
        style={[
          tw.style(
            'justify-center items-center bg-white rounded-3xl p-3 shadow-xl shadow-primary-400',
          ),
          { width: screenWidth },
          animatedStyle,
        ]}
      >
        <View style={tw.style('justify-center items-center flex-1 flex-col')}>
          <Image
            source={{ uri: question.media?.url || '' }}
            style={tw.style(
              'rounded-2xl',
              `w-[${maxWidth}px] h-[${maxHeight}px]`,
            )}
            resizeMode='cover'
          />
          <View
            style={tw`flex-col flex-1 items-center justify-center mx-4 mt-4`}
          >
            <Text specialType='Headline2' textAlign='center' numberOfLines={4}>
              {question.text}
            </Text>
          </View>
        </View>
      </Animated.View>
    </GestureDetector>
  )
}
