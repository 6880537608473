import { Avatar, Flex, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchBar } from '#admin/components/widgets/SearchBar'
import { Text } from '#components/base/Text'
import { getInitials } from '#components/utils/getInitials'
import { getUserName } from '#components/utils/getUserName'
import { tw } from '#components/utils/tw'
import { useCountUser, useSearchUser } from '#graphql/codegen'
import type { SearchUserItem } from '#types/user'

import { DropdownSetRole } from './DropdownSetRole'

export const Users = observer(() => {
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)

  const [d, refetchUser] = useSearchUser({
    variables: {
      filter: searchKeyword
        ? {
            OR: [
              {
                email_iLike: `%${searchKeyword}%`,
              },
              { name_iLike: `%${searchKeyword}%` },
            ],
          }
        : {},
      order: ['name_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [c] = useCountUser({
    variables: {
      filter: searchKeyword
        ? {
            OR: [
              {
                email_iLike: `%${searchKeyword}%`,
              },
              { name_iLike: `%${searchKeyword}%` },
            ],
          }
        : {},
    },
  })

  const reloadUser = () => {
    refetchUser({ requestPolicy: 'network-only' })
  }

  const columns: ColumnsType<SearchUserItem> = [
    {
      title: 'Avatar',
      dataIndex: 'Avatar',
      width: 100,
      key: 'Avatar',
      render: (_, record) => (
        <Flex gap='small' style={tw`pb-4 items-center`}>
          <Avatar
            style={tw.style('w-12 h-12 border-2 border-gray-300', {
              cursor: 'pointer',
            })}
            src={record?.thumbnail?.url}
          >
            {getInitials(record.name)}
          </Avatar>
        </Flex>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Typography>
          {getUserName({ name: record.name, email: record.email })}
        </Typography>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'email',
      render: (_, record) => record.email,
    },
    {
      title: 'Native language',
      dataIndex: 'NativeLanguage',
      key: 'NativeLanguage',
      width: 150,
      render: (_, record) => record.nativeLanguage,
    },
    {
      title: 'Language learn',
      dataIndex: 'LanguageLearn',
      key: 'LanguageLearn',
      width: 150,
      render: (_, record) => record.languageLearn,
    },
    {
      title: 'Level',
      dataIndex: 'Level',
      width: 70,
      key: 'Level',
      render: (_, record) => record.level.current,
    },
    {
      title: 'Role',
      dataIndex: 'isAdmin',
      width: 80,
      key: 'isAdmin',
      render: isAdmin => (isAdmin ? 'Admin' : 'User'),
    },
    {
      title: 'Status',
      dataIndex: 'deactivate',
      width: 110,
      key: 'deactivate',
      render: deactivate => (deactivate ? 'Deactivate' : 'Activate'),
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdAt',
    //   width: 180,
    //   key: 'createdAt',
    //   render: date => moment(date).format('MM/DD/YYYY HH:mm:ss'),
    // },
    {
      title: 'Actions',
      key: 'actions',
      width: 85,
      render: (_, record) => (
        <div style={tw`flex items-center justify-center`}>
          <DropdownSetRole
            record={record}
            key={record.id}
            reloadUser={reloadUser}
          />
        </div>
      ),
    },
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full`}>
      <Text style={tw`py-6 text-6`}>User</Text>
      <SearchBar
        keyword={(k: string) => {
          setCurrentPage(1)
          setSearchKeyword(k)
        }}
      />
      <PaginatedTable<SearchUserItem>
        dataSource={d.data?.searchUser || []}
        columns={columns}
        loading={d.fetching}
        total={(c.data?.countUser || 0) ?? 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollWidth={1300}
        scrollHeight={'60vh'}
      />
    </Flex>
  )
})
