import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { View } from 'react-native'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { TalkBubble } from '#components/widgets/ChatBubble'
import { Header } from '#components/widgets/Header'
import {
  useCountQuestion,
  useSearchTopicInUser,
  useSearchWordInUser,
} from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate, replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import { GameTimes } from '#types/games'

export const ReviewListening = observer(
  ({ route }: AppStackScreenProps<'ReviewListening'>) => {
    const topicId = route?.params?.topicId

    const isFocus = useIsFocused()

    useEffect(() => {
      if (isFocus) {
        S.shared.percentageOfTopic({ topicId })
      }
    }, [isFocus])

    const resetWordInUser = async () => {
      if (!d.data?.searchWordInUser) {
        return
      }

      await Promise.all(
        d.data.searchWordInUser.map(
          async i =>
            await gql.updateWordInUser({
              id: i.id,
              data: {
                times: GameTimes.TimeMin,
                percentListen: 0,
              },
            }),
        ),
      )
    }

    const [d] = useSearchWordInUser({
      variables: {
        filter: {
          topicId,
          userId: S.shared.currentUser?.id,
        },
      },
      requestPolicy: 'network-only',
    })

    const [c] = useCountQuestion({
      variables: {
        filter: {
          topicId,
          wordId: '',
        },
      },
    })

    const countTotal = c?.data?.countQuestion ?? 0

    const [u] = useSearchTopicInUser({
      variables: {
        filter: {
          topicId,
          userLearnId: S.shared.currentUser?.id,
        },
      },
      requestPolicy: 'network-only',
    })

    const handlePlayMiniGame = async () => {
      S.shared.setWrongNext(0)
      await resetWordInUser()
      if (u.data?.searchTopicInUser[0].percent !== 100) {
        await gql.updateTopicInUser({
          id: u.data?.searchTopicInUser[0].id ?? '',
          data: {
            percent: 0,
            topicId,
          },
        })
      }

      navigate('App', { screen: 'Games', params: { topicId, isPhrase: false } })
    }

    return (
      <View style={tw`flex-1`}>
        <Header
          onPressBack={() => {
            replace('Home', { screen: 'Topics' })
          }}
        />
        <View style={tw`flex-1 justify-center`}>
          <View style={tw`flex-row px-9`}>
            <View style={tw`flex-1 items-end w-68 h-31`}>
              <View style={tw`w-68 h-31`}>
                <TalkBubble
                  position='bottom'
                  text={
                    "Great work! You finished the tasks! Let's practice speaking. Are you Ready?"
                  }
                />
              </View>
            </View>
          </View>
          <View style={tw`justify-center items-center`}>
            <Image
              source={images.image_game}
              style={tw`rounded-full w-45 h-45 overflow-hidden`}
            />
          </View>
          <View style={tw`px-14 mt-13`}>
            {S.shared.point / countTotal >= 0.6 && (
              <Button style={tw`mb-5.5`} tone='primary'>
                <Text style={tw`text-white`} specialType='paragraph2'>
                  Speak with AI tutor
                </Text>
              </Button>
            )}
            <Button onPress={handlePlayMiniGame} tone='secondary'>
              <Text specialType='paragraph2'>Play mini-games again</Text>
            </Button>
          </View>
        </View>
      </View>
    )
  },
)
