import type { LanguageType, Level } from '#types/language'

export const FILTERS: { level_notILike: Level }[] = [
  { level_notILike: 'A1' },
  { level_notILike: 'A2' },
  { level_notILike: 'B1' },
  { level_notILike: 'B2' },
]

export const getFilter = (ul: Level, languageLearn: LanguageType) => {
  const i = FILTERS.findIndex(item => item.level_notILike === ul)
  const newFilters = [...FILTERS]
  return [
    ...newFilters.splice(0, i),
    {
      languageTopic: languageLearn,
      status: 'Active',
    },
  ]
}

export const getFilterCompleted = (
  ul: Level,
  languageLearn: LanguageType,
  userId: string,
) => ({
  AND: [
    ...getFilter(ul, languageLearn),
    {
      topicInUser_some: {
        percent: 100,
        userLearnId: userId,
      },
    },
  ],
})
