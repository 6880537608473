import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { Image } from '#components/base/Image'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountTopic, useSearchTopic } from '#graphql/codegen'
import { S } from '#store'
import type { LanguageType, Level } from '#types/language'
import type { SearchTopicItem } from '#types/topic'

import { Lesson } from './Lesson'
import { getFilterCompleted } from './utils'

export const CompletedRoute = observer(() => {
  const { width } = useWindowDimensions()
  const { openActionsheet } = useOverlay()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize] = useState(10)
  const [topics, setTopics] = useState<SearchTopicItem[]>([])

  const lv = (S.shared.currentUser?.level.current as Level) ?? 'A1'

  const [searchTopic, refetchTopic] = useSearchTopic({
    variables: {
      filter: getFilterCompleted(
        lv,
        (S.shared.currentUser?.languageLearn as LanguageType) ?? 'English',
        S.shared.currentUser?.id ?? '',
      ),
      order: ['level_asc', 'ordinalNumber_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [countData] = useCountTopic({
    variables: {
      filter: getFilterCompleted(
        lv,
        (S.shared.currentUser?.languageLearn as LanguageType) ?? 'English',
        S.shared.currentUser?.id ?? '',
      ),
    },
  })

  const count = countData.data?.countTopic ?? 0
  const { searchTopic: searchTopicData } = searchTopic.data ?? {}

  useEffect(() => {
    if (searchTopicData) {
      setTopics(prevTopics =>
        currentPage === 1
          ? searchTopicData ?? []
          : [...prevTopics, ...(searchTopicData ?? [])],
      )
    }
  }, [searchTopicData])

  const handleSubmit = (item: SearchTopicItem) => {
    openActionsheet(Lesson, { topicId: item.id })
  }

  const onRefresh = () => {
    setCurrentPage(1)
    refetchTopic({ requestPolicy: 'network-only' })
  }

  const onEndReached = () => {
    const totalLoadedItems = currentPage * pageSize
    if (totalLoadedItems <= count) {
      setCurrentPage(currentPage + 1)
    }
  }

  const renderItem = ({ item }: { item: SearchTopicItem }) => (
    <TouchableOpacity
      style={tw.style(
        'flex-col items-center rounded-3xl w-full overflow-hidden',
        { height: width * 0.5625, width: width - 32 },
      )}
      onPress={() => handleSubmit(item)}
    >
      {item.media && (
        <Image
          source={{ uri: item.media.url }}
          style={tw.style('rounded-3xl', {
            height: width * 0.5625,
            width: width - 32,
          })}
          resizeMode='cover'
        />
      )}
      <View
        style={tw.style(
          'absolute top-0 left-0 right-0 bottom-0 z-2 rounded-3xl',
        )}
      >
        <LinearGradientColor
          colorX='rgba(0, 0, 0, 0.32) 0%'
          colorY='rgba(0, 0, 0, 0.80) 50%'
        />
        <View style={tw`absolute bottom-4 inset-x-4`}>
          <Text
            numberOfLines={2}
            specialType='Headline1'
            style={tw.style('text-text-4')}
          >
            {item.name}
          </Text>
          <Text
            numberOfLines={2}
            specialType='paragraph2'
            color={tw.color('text-3')}
          >
            {item.description.length > 80
              ? item.description.slice(0, 80) + '...'
              : item.description}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )

  const renderItemSeparator = () => <View style={tw`h-3`} />

  return (
    <FlatList
      data={topics}
      renderItem={renderItem}
      keyExtractor={item => item.id}
      contentContainerStyle={tw`p-4`}
      ItemSeparatorComponent={renderItemSeparator}
      refreshing={searchTopic.fetching}
      onRefresh={onRefresh}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.7}
    />
  )
})
