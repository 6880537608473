import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { useAudioManager } from '#components/utils/audio/useAudioManager'
import { isValidAudioUrl } from '#components/utils/checkMedia'
import { tw } from '#components/utils/tw'
import type { WordOrPhraseItem } from '#types/word'

import { fetchDataWordOrPhrase } from './fetchDataWordOrPhrase'
import { Item } from './Item'

type Props = { topicId: string; isPhrase: boolean }

export const WordOrPhrase: FC<Props> = ({ topicId, isPhrase }) => {
  const [data, setData] = useState<WordOrPhraseItem[]>([])
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [audioUrl, setAudioUrl] = useState<string>('')

  const { play, stop } = useAudioManager({
    url: audioUrl,
  })

  useEffect(() => {
    fetchData()
  }, [topicId])

  useEffect(() => {
    if (refreshing) {
      fetchData()
    }
  }, [refreshing])

  const fetchData = async () => {
    const res = await fetchDataWordOrPhrase(topicId, isPhrase)
    setData(res)
    setRefreshing(false)
  }

  const onRefresh = () => {
    if (!refreshing) {
      setRefreshing(true)
    }
  }

  const onPressAudio = (item: WordOrPhraseItem) => {
    if (audioUrl) {
      stop()
    }
    const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
    const url = audios.length > 0 ? audios[0].media?.url || '' : ''
    if (url !== audioUrl) {
      setAudioUrl(url)
    } else {
      play(1)
    }
  }

  const renderItem = ({ item }: { item: WordOrPhraseItem; index: number }) => (
    <Item item={item} onPress={onPressAudio} />
  )
  const renderItemSeparator = () => (
    <View style={tw`bg-neutral-200 h-0.25 w-full my-2`} />
  )

  return (
    <View style={tw`flex-1 flex-col bg-background-light-1`}>
      <FlatList
        data={data}
        renderItem={renderItem}
        ItemSeparatorComponent={renderItemSeparator}
        refreshing={refreshing}
        onRefresh={onRefresh}
        contentContainerStyle={tw`m-4 p-4 bg-background-light-white rounded-2xl`}
      />
    </View>
  )
}
