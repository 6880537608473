import type { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { Avatar } from '#components/base/Avatar'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useSearchAiTutor } from '#graphql/codegen'
import { replace } from '#navigator/helpers'
import { S } from '#store'

import { ChatSetting } from './ChatSetting/ChatSetting'
import type { TaskDataItem } from './data'
import { Tasks } from './Tasks/Tasks'

type Props = { tasks: TaskDataItem; check: string[] }

export const Header: FC<Props> = ({ tasks, check }) => {
  const { openActionsheet } = useOverlay()
  const onPressBack = () => {
    replace('Home', { screen: 'Topics' })
  }

  const [aiTutor] = useSearchAiTutor({
    variables: { filter: { id: S.shared.currentUser?.aiTutorId } },
  })
  const [firstItem] = aiTutor.data?.searchAiTutor ?? []

  const onPressTaskSquare = () => {
    openActionsheet(Tasks, { data: tasks, check, aiTutor: firstItem })
  }

  const onPressSetting = () => {
    openActionsheet(ChatSetting, {})
  }
  return (
    <View style={tw`flex-row items-center h-15 bg-neutral-75 mb-1 shadow-sm`}>
      <TouchableOpacity
        style={tw`h-12 w-12 justify-center items-center`}
        onPress={onPressBack}
      >
        <SystemIcon type='SAX' name='ArrowLeft2' />
      </TouchableOpacity>
      <View style={tw`flex-1 flex-row items-center`}>
        <Avatar
          name={firstItem?.name}
          source={{ uri: firstItem?.thumbnail?.url }}
        />
        <View style={tw`ml-2`}>
          <Text specialType='Headline4'>{firstItem?.name}</Text>
        </View>
      </View>
      <TouchableOpacity
        style={tw`h-12 w-12 justify-center items-center`}
        onPress={onPressTaskSquare}
      >
        <SystemIcon type='SAX' name='TaskSquare' />
      </TouchableOpacity>
      <TouchableOpacity
        style={tw`h-12 w-12 justify-center items-center`}
        onPress={onPressSetting}
      >
        <SystemIcon type='SAX' name='Setting2' />
      </TouchableOpacity>
    </View>
  )
}
