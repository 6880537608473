import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

import { toastError, toastSuccess } from '#components/utils/Toast'
import { gql } from '#graphql/urql'
import { onAppInit } from '#screens/App/onAppInit'

export const onLoginGoogle = async () => {
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  try {
    const resGoogle = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(resGoogle)
    const token = credential?.accessToken ?? ''
    if (resGoogle && token) {
      const r = await gql.loginGoogle({
        data: {
          email: resGoogle.user.email ?? '',
          name: resGoogle.user.displayName ?? '',
          accessToken: token,
          photoURL: resGoogle.user.photoURL ?? '',
          emailVerified: resGoogle.user.emailVerified,
        },
      })
      if (r.error || !r.data || r.data.loginGoogle.user?.deactivate) {
        toastError({
          message: r.data?.loginGoogle.user?.deactivate
            ? 'Your account has been locked'
            : r.error?.message,
        })
        return
      }
      await onAppInit({
        currentAuthSession: r.data.loginGoogle,
      })
      toastSuccess({ message: 'Log in success' })
    } else {
      toastError({ message: 'Login Google failed' })
    }
  } catch (error) {
    toastError({ message: `Login failed: ${error}` })
  }
}
