import { TouchableOpacity, View } from 'react-native'

import type { IconSvgComponentProps } from '#components/base/IconSvg'
import { Spinner } from '#components/base/Spinner'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type Props = {
  loading?: boolean
  onPress?: () => void
  icon: IconSvgComponentProps
  text: string
  disable?: boolean
}

export const ButtonSocial = ({
  loading,
  onPress,
  icon,
  text,
  disable,
}: Props) => (
  <TouchableOpacity
    onPress={onPress}
    style={tw`p-3 w-full rounded-full bg-background-light-white items-center flex-row justify-center`}
    disabled={loading || disable}
  >
    {loading && (
      <View style={tw`mr-2`}>
        <Spinner style={tw`text-text-1`} />
      </View>
    )}
    <SystemIcon type='SVG' name={icon} size={32} />
    <Text style={tw`ml-2`} specialType='paragraph1'>
      {text}
    </Text>
  </TouchableOpacity>
)
