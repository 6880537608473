import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

import { useOverlay } from '#components/overlay/hooks'
import { GameContainer } from '#components/widgets/Games/GameContainer'
import { LevelNotificationModal } from '#components/widgets/LevelNotificationModal'
import { gql } from '#graphql/urql'
import { replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import { GameTimes } from '#types/games'
import type { LanguageType, Level } from '#types/language'
import { LanguageLabel, LevelDowngrade } from '#types/language'

import { useRandomGame } from './useRandomGame'

export const Games = observer(({ route }: AppStackScreenProps<'Games'>) => {
  const { isPhrase, topicId, isCompleted } = route.params

  const { openModal, closeModal } = useOverlay()
  const modalId = useRef<string>('')

  const {
    answers,
    currentIndex,
    onChangeWordsData,
    question,
    total,
    wordInUser,
    loading,
    onChangeIndex,
    topicData,
  } = useRandomGame({
    topicId,
    userId: S.shared.currentUser?.id || '',
    language:
      (S.shared.currentUser?.languageLearn as LanguageType) ||
      LanguageLabel.English,
    isPhrase,
    isCompleted,
  })

  const updateUser = async (times: number) => {
    try {
      await gql.updateWordInUser({
        id: wordInUser?.id ?? '',
        data: { times },
      })
    } catch (error) {
      console.error('Failed to update word in user:', error)
    }
  }

  const onChangeAnswer = async (isTrue: boolean) => {
    S.shared.checkWrongNext(isTrue)
    const newTimes = isTrue
      ? (wordInUser?.times || GameTimes.TimeMin) + 1
      : (wordInUser?.times || GameTimes.TimeMin) >= GameTimes.TimeHard
        ? GameTimes.TimeReset
        : GameTimes.TimeMin

    onChangeWordsData({ ...wordInUser, times: newTimes })
    if (!isCompleted) {
      await updateUser(newTimes)
    }
  }

  const handleTryEasier = async () => {
    const lv = S.shared.currentUser?.level.current ?? ''
    const newLevel = LevelDowngrade[lv]
    updateLevel(newLevel, newLevel !== 'A1')
    if (S.shared.currentUser) {
      S.shared.currentUser.level = { isShow: true, current: newLevel }
    }
    closeModal(modalId.current)
    await replace('Home', { screen: 'Topics' })
  }

  const handleContinue = async () => {
    closeModal(modalId.current)
    const lv = S.shared.currentUser?.level.current ?? ''
    updateLevel(lv, false)
  }

  const updateLevel = async (current: Level, isShow: boolean) => {
    await gql.updateUser({
      data: { level: { isShow, current } },
    })
  }

  const showSuggestDownGrade = () => {
    if (
      S.shared.wrongNext >= 5 &&
      S.shared.currentUser?.level.isShow === true &&
      S.shared.currentUser.level !== 'A1' &&
      !isCompleted
    ) {
      modalId.current = openModal(LevelNotificationModal, {
        title: 'Is this lesson too hard!.',
        content: 'We can jump to another lesson that`s closer to your level',
        autoClose: false,
        level: S.shared.currentUser.level,
        handleTryEasier,
        handleContinue,
        isButton: true,
      })
    }
  }

  return (
    <GameContainer
      currentIndex={currentIndex}
      total={total}
      question={question}
      answers={answers}
      loading={loading}
      onChangeIndex={onChangeIndex}
      onChangeAnswer={onChangeAnswer}
      showSuggestDownGrade={showSuggestDownGrade}
      type='Game'
      topic={topicData}
    />
  )
})
