import { DeleteOutlined } from '@ant-design/icons'
import { Avatar, Button, Flex, Input, Select } from 'antd'
import { useEffect, useState } from 'react'

import { Upload } from '#admin/components/widgets/Upload'
import { getInitials } from '#components/utils/getInitials'
import { toastError } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { useCurrentAuthSession } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { CurrentUser } from '#store/SharedStore'
import type { LanguageType } from '#types/language'

export const GeneralTab = () => {
  const account = useCurrentAuthSession({ variables: {} })
  const currentAccount = account[0].data?.currentAuthSession

  const [avatar, setAvatar] = useState<{ id: string; url: string }>()
  const [languageLearn, setLanguageLearn] = useState<LanguageType>(
    currentAccount?.user?.languageLearn as LanguageType,
  )
  const [name, setName] = useState(currentAccount?.user?.name)
  const [nativeLanguage, setNativeLanguage] = useState<LanguageType>(
    currentAccount?.user?.nativeLanguage as LanguageType,
  )
  const [isEditName, setIsEditName] = useState(false)
  const [isEditLanguageLearn, setIsEditLanguageLearn] = useState(false)
  const [isEditNativeLanguage, setIsEditNativeLanguage] = useState(false)

  useEffect(() => {
    if (currentAccount) {
      setAvatar({
        url: currentAccount?.user?.thumbnail?.url ?? '',
        id: currentAccount?.user?.thumbnailId ?? '',
      })
      setName(currentAccount?.user?.name ?? '')
      setLanguageLearn(
        (currentAccount?.user?.languageLearn ?? 'English') as LanguageType,
      )
      setNativeLanguage(
        (currentAccount?.user?.nativeLanguage ?? 'English') as LanguageType,
      )
    }
  }, [currentAccount])
  const handleEditData = async () => {
    const res = await gql.updateUser({
      data: {
        email: currentAccount?.user?.email,
        languageLearn,
        name,
        nativeLanguage,
        thumbnailId: avatar?.id,
      },
    })
    const newUser: CurrentUser = S.shared.currentUser
      ? {
          ...S.shared.currentUser,
          ...res.data?.updateUser,
        }
      : null
    S.shared.currentUser = newUser
    if (res.error) {
      toastError({
        message: res.error.message,
      })
    }
  }
  useEffect(() => {
    handleEditData()
  }, [avatar?.url])
  return (
    <Flex style={tw`p-4 px-[15%] flex-col items-center text-[16px] w-full`}>
      <Flex style={tw`flex justify-between items-center w-full py-4`}>
        <Avatar
          style={tw.style('w-26 h-26 border-2', {
            fontSize: '45px',
          })}
          src={avatar?.url}
        >
          {getInitials(name ?? '')}
        </Avatar>
        <Flex gap={8}>
          <Button onClick={() => setAvatar({ url: '', id: '' })}>
            <DeleteOutlined style={tw`text-red-500`} />
          </Button>

          <Upload
            resourceType='avatar'
            url={avatar?.url}
            type='avatar'
            display='image'
            onChange={data => {
              setAvatar({
                url: data.url,
                id: data.id,
              })
            }}
            onDeleted={() => setAvatar({ url: '', id: '' })}
            isHiddenImage
          />
        </Flex>
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex justify-between w-full items-center`}>
        <Flex style={tw`flex-col w-[50%] p-4`} gap={8}>
          <div>Name </div>
          {!isEditName ? (
            <div style={tw`text-gray-500`}>{name}</div>
          ) : (
            <Input value={name} onChange={e => setName(e.target.value)} />
          )}
        </Flex>
        {!isEditName ? (
          <Button onClick={() => setIsEditName(true)}>Edit</Button>
        ) : (
          <Flex gap={8}>
            <Button onClick={() => setIsEditName(false)}>Cancel</Button>
            <Button
              type='primary'
              onClick={() => {
                handleEditData()
                setIsEditName(false)
              }}
            >
              Save
            </Button>
          </Flex>
        )}
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex-col w-full p-4`} gap={8}>
        <div>Email </div>
        <div style={tw`text-gray-500`}>{currentAccount?.user?.email}</div>
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex justify-between w-full items-center`}>
        <Flex style={tw`flex-col w-[50%] p-4`} gap={8}>
          <div style={tw.style({ textWrap: 'nowrap' })}>Language learn </div>
          {!isEditLanguageLearn ? (
            <div style={tw`text-gray-500`}>{languageLearn}</div>
          ) : (
            <Select
              value={languageLearn}
              options={[
                { label: 'English', value: 'English' },
                { label: 'Spanish', value: 'Spanish' },
              ]}
              onChange={e => setLanguageLearn(e as LanguageType)}
            />
          )}
        </Flex>
        {!isEditLanguageLearn ? (
          <Button onClick={() => setIsEditLanguageLearn(true)}>Edit</Button>
        ) : (
          <Flex gap={8}>
            <Button onClick={() => setIsEditLanguageLearn(false)}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => {
                handleEditData()
                setIsEditLanguageLearn(false)
              }}
            >
              Save
            </Button>
          </Flex>
        )}
      </Flex>
      <hr style={tw`w-full`} />
      <Flex style={tw`flex justify-between w-full items-center`}>
        <Flex style={tw`flex-col w-[50%] p-4`} gap={8}>
          <div style={tw.style({ textWrap: 'nowrap' })}>Native language </div>
          {!isEditNativeLanguage ? (
            <div style={tw`text-gray-500`}>{languageLearn}</div>
          ) : (
            <Select
              value={nativeLanguage}
              options={[
                { label: 'English', value: 'English' },
                { label: 'Spanish', value: 'Spanish' },
              ]}
              onChange={e => setNativeLanguage(e as LanguageType)}
            />
          )}
        </Flex>
        {!isEditNativeLanguage ? (
          <Button onClick={() => setIsEditNativeLanguage(true)}>Edit</Button>
        ) : (
          <Flex gap={8}>
            <Button onClick={() => setIsEditNativeLanguage(false)}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => {
                handleEditData()
                setIsEditNativeLanguage(false)
              }}
            >
              Save
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
