export const currencyOption = [
  {
    label: 'VND',
    value: 'VND',
  },
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'JPY',
    value: 'JPY',
  },
  {
    label: 'CNY',
    value: 'CNY',
  },
  {
    label: 'KRW',
    value: 'KRW',
  },
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'AUD',
    value: 'AUD',
  },
  {
    label: 'SGD',
    value: 'SGD',
  },
  {
    label: 'MYR',
    value: 'MYR',
  },
  {
    label: 'THB',
    value: 'THB',
  },
]
