import type { FC } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { Image } from '#components/base/Image'
import { tw } from '#components/utils/tw'
import type { GameQuestionProps } from '#types/games'

export const CorrectPhraseQuestion: FC<GameQuestionProps> = ({ question }) => {
  const { width } = useWindowDimensions()
  return (
    <View style={tw`flex-1 flex-col items-center p-4`}>
      <Image
        source={{
          uri: question.media?.url || '',
        }}
        style={tw.style('rounded-2xl', {
          height: width * 0.5625,
          width: width - 32,
        })}
        resizeMode='cover'
      />
    </View>
  )
}
