import type React from 'react'

type Props = {
  children?: React.ReactNode
  degree?: number
  colorX?: string
  colorY?: string
  opacity?: number
}

export const LinearGradientColor: React.FC<Props> = ({
  children,
  degree = 180,
  colorX = '#1AB8E6 0%',
  colorY = '#3B89EB 100%',
  opacity = 1,
}) => (
  <div
    style={{
      background: `linear-gradient(${degree}deg, ${colorX}, ${colorY})`,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity,
    }}
  >
    {children}
  </div>
)
