import { View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'

type Props = {
  handleStartTest: () => void
}
export const OverView = ({ handleStartTest }: Props) => {
  const textItems = [
    'This test takes between 5-10 minutes.',
    'If you want, take the test later from your setting.',
    'Make sure you can hear the audio clearly.',
    'Make sure you have a good internet connection.',
  ]
  return (
    <View style={tw`flex-1 bg-background-light-1 dark:bg-background-dark-1`}>
      <Header />
      <View style={tw`mt-6 flex-1 px-4`}>
        <View style={tw`items-center mb-2`}>
          <SystemIcon size={180} type='SVG' name='overViewLevelTest' />
          <Text style={tw`mt-2`} specialType='Headline3'>
            Before you start
          </Text>
        </View>
        {textItems.map((item, index) => (
          <View key={index} style={tw`flex-row mt-3`}>
            <SystemIcon
              variant='Bold'
              color={tw.color('primary-400')}
              type='SAX'
              name='TickCircle'
            />
            <Text style={tw`mx-2.5`} specialType='paragraph1'>
              {item}
            </Text>
          </View>
        ))}
      </View>
      <View style={tw`p-4`}>
        <Button onPress={handleStartTest} tone='primary'>
          Start test
        </Button>
      </View>
    </View>
  )
}
