import type { TouchableOpacityProps } from 'react-native'
import { TouchableOpacity } from 'react-native'
import type { SvgProps } from 'react-native-svg'

import {
  Apple,
  ApplePay,
  ArrowsClockwise,
  Card,
  CardPay,
  Check,
  Congratulate,
  Doc,
  Facebook,
  Google,
  HandSwipe,
  Item1,
  Item2,
  Item3,
  Item4,
  OverViewLevelTest,
  PaymentGiftHeader,
  PayPal,
  Play,
  SnailIcon,
  TickCircle,
  Warning,
  X,
} from './SVG'

const IconSvgComponent = {
  apple: Apple,
  'arrows-clockwise': ArrowsClockwise,
  check: Check,
  congratulate: Congratulate,
  document: Doc,
  facebook: Facebook,
  google: Google,
  overViewLevelTest: OverViewLevelTest,
  warning: Warning,
  'hand-swipe': HandSwipe,
  play: Play,
  snail: SnailIcon,
  x: X,
  paymentGiftHeader: PaymentGiftHeader,
  card: Card,
  cardPay: CardPay,
  applePay: ApplePay,
  paypal: PayPal,
  item1: Item1,
  item2: Item2,
  item3: Item3,
  item4: Item4,
  tickCircle: TickCircle,
}

export type IconSvgComponentProps = keyof typeof IconSvgComponent

export type IconSvgProps = {
  name: IconSvgComponentProps
  color?: SvgProps['color']
  size?: number
  onPress?: () => void
  buttonProps?: TouchableOpacityProps
}

export const IconSvg: React.FC<IconSvgProps> = ({
  name,
  size = 24,
  color,
  onPress,
  buttonProps,
}) => {
  const Icon = IconSvgComponent[name]
  return (
    <TouchableOpacity disabled={!onPress} {...buttonProps} onPress={onPress}>
      <Icon width={size} height={size} color={color} />
    </TouchableOpacity>
  )
}
