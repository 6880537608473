import type React from 'react'
import { View } from 'react-native'
import type { Style } from 'twrnc'

import type { IconSaxName } from '#components/base/IconSax'
import { IconSax } from '#components/base/IconSax'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useDeviceDimensions } from '#components/utils/useDeviceDimensions'

export type MessageProps = {
  type: 'success' | 'error' | 'warning'
  message?: string
  containerStyle?: Style
  iconStyle?: Style
  contentStyle?: Style
  messageStyle?: Style
}

export const ToastMessage: React.FC<MessageProps> = ({
  type,
  message,
  containerStyle,
  iconStyle,
  contentStyle,
  messageStyle,
}) => {
  const { width } = useDeviceDimensions()
  const typeStyle = {
    success: 'border-success-300 ',
    error: 'border-error-300 ',
    warning: 'border-alert-300 ',
  }
  const iconComponent: Record<MessageProps['type'], IconSaxName> = {
    success: 'TickCircle',
    error: 'CloseCircle',
    warning: 'InfoCircle',
  }
  const color: Record<MessageProps['type'], string> = {
    success: tw.color('success-500') || 'green-500',
    error: tw.color('error-300') || 'red-500',
    warning: tw.color('warning-400') || 'yellow-500',
  }
  const backgroundColor: Record<MessageProps['type'], string> = {
    success: tw.color('success-50') || 'green-50',
    error: tw.color('error-50') || 'red-50',
    warning: tw.color('warning-50') || 'yellow-50',
  }

  return (
    <View
      style={tw.style(
        `px-4 py-3 my:4 bg-background-light-1 rounded-full flex-row max-w-192 w-[${width - 72}px]`,
        { backgroundColor: backgroundColor[type] },
        typeStyle[type],
        containerStyle,
      )}
    >
      <View style={tw.style(iconStyle)}>
        <IconSax name={iconComponent[type]} size={20} color={color[type]} />
      </View>
      <View style={tw.style('flex-1 flex-col mx-2', contentStyle)}>
        {message && (
          <Text
            style={tw.style('text-text-1', { fontWeight: 500 }, messageStyle)}
            numberOfLines={2}
            specialType='paragraph2'
            color={color[type]}
          >
            {message}
          </Text>
        )}
      </View>
    </View>
  )
}
