import { zodResolver } from '@hookform/resolvers/zod'
import type { UploadFile } from 'antd'
import type { RcFile } from 'antd/es/upload'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TouchableOpacity, View } from 'react-native'
import { z } from 'zod'

import { CropImage } from '#admin/components/widgets/CropImage'
import { Avatar } from '#components/base/Avatar'
import { Button } from '#components/base/Button/Button'
import { Text } from '#components/base/Text'
import { FormField } from '#components/form/FormField'
import { Input } from '#components/form/Input'
import { useOverlay } from '#components/overlay/hooks'
import { getUserName } from '#components/utils/getUserName'
import { toastError, toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { gql } from '#graphql/urql'
import { useCurrentUser } from '#graphql/utils/useCurrentUser'
import { replace } from '#navigator/helpers'
import { S } from '#store'
import type { ResponseFile } from '#types/media'

import { ActionSheetImage } from './ActionSheetImage'

const FormSchema = z.object({
  name: z.string({ required_error: 'Name is required' }),
  email: z.string({ required_error: 'Email is required' }),
})

type EditProFileValue = z.infer<typeof FormSchema>

export const EditProfile = observer(() => {
  const [u] = useCurrentUser()

  const { openActionsheet } = useOverlay()

  const { control, watch, setValue } = useForm<EditProFileValue>({
    resolver: zodResolver(FormSchema),
    shouldFocusError: true,
  })

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(
    S.shared?.currentUser?.thumbnail?.url,
  )
  const imageInputRef = useRef<HTMLInputElement | null>(null)
  const [fileUpload, setFileUpload] = useState<UploadFile | null>(null)
  const [thumbnailId, setThumbnailId] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const nameValue = watch('name')

  useEffect(() => {
    const name = getUserName({
      email: S.shared.currentUser?.email || '',
      name: S.shared.currentUser?.name || '',
    })
    setValue('name', name)
    setValue('email', S.shared.currentUser?.email ?? '')
    setThumbnailId(S.shared.currentUser?.thumbnailId ?? '')
  }, [])

  const bottomSheet = () => {
    openActionsheet(ActionSheetImage, {
      onDeleteAvatar: handleDeleteAvatar,
      onUploadAvatar: handleUploadAvatar,
    })
  }

  const handleButtonPress = async () => {
    const res = await gql.updateUser({
      data: { name: nameValue, thumbnailId },
    })
    if (res.data?.updateUser) {
      S.shared.currentUser = res.data?.updateUser
      toastSuccess({ message: 'Profile updated successfully' })
    } else {
      toastError({ message: 'Profile update failed. Please try again' })
    }
  }

  const handleDeleteAvatar = () => {
    setAvatarUrl('')
  }

  const handleUploadAvatar = async () => {
    imageInputRef.current?.click()
  }

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      const fileToUpLoad = convertFileToUploadFile(file)
      setFileUpload(fileToUpLoad)
      setIsOpen(true)
    }
  }
  const convertFileToUploadFile = (file: File): UploadFile => {
    const uploadFile: UploadFile = {
      uid: file.name,
      name: file.name,
      type: file.type,
      size: file.size,
      originFileObj: file as RcFile,
    }

    return uploadFile
  }

  if (!u) {
    return null
  }

  const handleChangePassWord = () => {
    replace('App', { screen: 'ChangePassword' })
  }

  return (
    <View style={tw.style('flex-1 flex-col')}>
      <Header isDark title='Edit profile' />
      <View style={tw`items-center px-4 flex-1 mt-2`}>
        <Avatar
          size='large'
          name={S.shared.currentUser?.name}
          style={tw`w-20 h-20 rounded-full border`}
          source={{
            uri: avatarUrl,
          }}
        />
        <Button
          titleColor={tw.color('primary-400')}
          tone='plain'
          onPress={bottomSheet}
        >
          Set new photo
        </Button>
        <input
          type='file'
          accept='image/*'
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id='imageUpload'
          ref={imageInputRef}
        />
        <View style={tw`w-full gap-y-6 mt-8`}>
          <FormField name='name' control={control}>
            <Input />
          </FormField>

          <FormField name='email' control={control}>
            <Input editable={false} />
          </FormField>
          <TouchableOpacity onPress={handleChangePassWord}>
            <Text specialType='paragraph1' color={tw.color('primary-400')}>
              Change password
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={tw.style('pb-4 px-4')}>
        <Button
          disabled={
            (thumbnailId === S.shared.currentUser?.thumbnailId &&
              nameValue === S.shared.currentUser?.name) ||
            !nameValue
            // !avatarUrl ||
            // avatarUrl
          }
          onPress={handleButtonPress}
        >
          Save
        </Button>
      </View>

      <CropImage
        fileUpload={fileUpload as UploadFile}
        handleCancel={() => setIsOpen(false)}
        isOpen={isOpen}
        setImageCrop={(value: ResponseFile) => {
          setThumbnailId(value.id)
          setAvatarUrl(value.url)
        }}
      />
    </View>
  )
})
