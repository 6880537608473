import { useEffect, useState } from 'react'
import { Animated, Easing, Platform } from 'react-native'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

import { Icon } from './Icon'

export type SpinnerProps = {
  containerStyle?: Style
  style?: Style
}

export const Spinner = ({ containerStyle, style }: SpinnerProps) => {
  const [r] = useState(new Animated.Value(0))

  useEffect(() => {
    const rotator = Animated.loop(
      Animated.timing(r, {
        toValue: 1,
        duration: 1500,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== 'web',
      }),
    )
    rotator.start()
    return rotator.stop
  }, [])

  const rotate = r.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  })

  return (
    <Animated.View
      style={tw.style(containerStyle, { transform: [{ rotate }] })}
    >
      <Icon provider='Feather' name='loader' style={style} />
    </Animated.View>
  )
}
