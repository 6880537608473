import type { NonNullish } from '##/shared/ts'
import { setToken } from '#apis'
import type { DarkMode } from '#components/dark/DarkModeProvider'
import type { CurrentAuthSessionQuery } from '#graphql/codegen'
import { gql, setUrqlHeaders } from '#graphql/urql'
import { GameTimes, PercentageTopic } from '#types/games'

import type { Persisted } from '.'

export type LoginResult = NonNullish<
  Required<CurrentAuthSessionQuery>['currentAuthSession']
>
export type CurrentUser = Required<LoginResult>['user'] | undefined

export type ResultPercentageTopic = {
  count: number
  countKnown: number
  countPhraseUnknown: number
  countPhrase: number
  countVocabulary: number
  countVocabularyUnKnown: number
  fetching: boolean
  percent: number
  currentTopicInUserId: string
  percentGame: number
  percentListening: number
  percentSpeaking: number
}

export class SharedStore {
  static persisted: Persisted<SharedStore> = {
    authToken: 'string',
    darkMode: 'string',
    isTipsLearned: 'boolean',
    isTipsTopicSpeak: 'boolean',
    tasksId: 'string',
    autoCorrectCount: 'number',
  }

  private _authToken = ''
  private _autoCorrectCount = 0
  get authToken() {
    return this._authToken
  }
  set authToken(t: string) {
    this._authToken = t
    setUrqlHeaders(this)
    setToken(t)
  }

  darkMode?: DarkMode
  currentUser?: CurrentUser
  private _tasksId: string = localStorage.getItem('tasksId') || '[]'

  get tasksId() {
    return this._tasksId
  }

  set tasksId(id: string) {
    this._tasksId = id
    localStorage.setItem('tasksId', id)
  }

  getTasksIdArray = (): string[] => {
    try {
      return JSON.parse(this._tasksId) || []
    } catch (e) {
      console.error('Error parsing tasksId:', e)
      return []
    }
  }

  get autoCorrectCount() {
    return this._autoCorrectCount.valueOf()
  }

  set autoCorrectCount(number: number) {
    this._autoCorrectCount = number
    localStorage.setItem('autoCorrectCount', number.toString())
  }

  point: number = 0
  setPoint = (p: number) => {
    this.point = p
  }
  pointLevelTest: number = 0
  setPointLevelTest = (p: number) => {
    this.pointLevelTest = p
  }
  wrongNext: number = 0
  setWrongNext = (p: number) => {
    this.wrongNext = p
  }

  checkWrongNext = (isTrue: boolean) => {
    if (isTrue) {
      this.wrongNext = 0
    } else {
      this.wrongNext++
    }
  }

  isTipsLearned: boolean = false
  isTipsTopicSpeak: boolean = false

  resultPercentageTopic: ResultPercentageTopic = {
    count: 0,
    countKnown: 0,
    countPhraseUnknown: 0,
    countPhrase: 0,
    countVocabulary: 0,
    countVocabularyUnKnown: 0,
    fetching: false,
    percent: 0,
    currentTopicInUserId: '',
    percentGame: 0,
    percentListening: 0,
    percentSpeaking: 0,
  }

  currentPlayingAudio: HTMLAudioElement | null = null

  percentageOfTopic = async ({ topicId }: { topicId: string }) => {
    const currentUserId = this.currentUser?.id || ''
    this.resultPercentageTopic = {
      ...this.resultPercentageTopic,
      fetching: true,
    }
    const resp = await gql.searchPercent(
      {
        filterQuestion: {
          topicId,
          wordId: '',
        },
        filterTopicInUser: {
          topicId,
          userLearnId: currentUserId,
        },
        filterWordInUser: {
          topicId,
          userId: currentUserId,
          percentListen_gt: 0,
        },
      },
      { requestPolicy: 'network-only' },
    )

    const countWordInUser = await gql.checkWordInUser(
      {
        filter: { topicId, userId: currentUserId, wordId_ne: '' },
        filterKnown: {
          topicId,
          times: GameTimes.TimeMax,
          userId: currentUserId,
          wordId_ne: '',
        },
        filterUnKnown: {
          topicId,
          times: GameTimes.TimeMin,
          userId: currentUserId,
          wordId_ne: '',
        },
        filterVocabularyUnknown: {
          topicId,
          userId: currentUserId,
          word_some: { isVocabulary: true },
        },
        filterVocabulary: {
          topicId,
          times: GameTimes.TimeMax,
          userId: currentUserId,
          word_some: { isVocabulary: true },
        },
        filterPhraseUnKnown: {
          topicId,
          userId: currentUserId,
          word_some: { isVocabulary: false },
        },
        filterPhrase: {
          topicId,
          times: GameTimes.TimeMax,
          userId: currentUserId,
          word_some: { isVocabulary: false },
        },
      },
      { requestPolicy: 'network-only' },
    )
    // Game
    const totalCountGame = countWordInUser.data?.count ?? 0
    const countKnown = countWordInUser.data?.countKnown ?? 0
    const checkPercent = countKnown ? countKnown / totalCountGame : 0
    const game: number =
      checkPercent >= PercentageTopic.PercentageGame
        ? PercentageTopic.Game
        : Math.round(checkPercent * PercentageTopic.Game)
    // Listen
    const totalCountListen = resp.data?.countQuestion ?? 0
    const point = resp.data?.wordInUser[0]?.percentListen ?? 0
    const listen: number =
      point / PercentageTopic.MaxListen >= PercentageTopic.PercentageListen
        ? PercentageTopic.Listen
        : point > 0
          ? Math.round((point / totalCountListen) * PercentageTopic.Listen)
          : 0
    // Speak
    const speak = 0
    // Percent
    const p: number = game + listen + speak
    let topicInUserId = resp.data?.topicInUser[0]?.id ?? ''

    const currentPercent = resp.data?.topicInUser[0]?.percent ?? 0

    if (!topicInUserId) {
      const res = await gql.createTopicInUser({
        data: { topicId, userLearnId: currentUserId },
      })
      topicInUserId = res.data?.createTopicInUser.id ?? ''
    }

    this.resultPercentageTopic = {
      ...this.resultPercentageTopic,
      count: totalCountGame,
      countKnown,
      countPhraseUnknown: countWordInUser.data?.countPhraseUnknown ?? 0,
      countPhrase: countWordInUser.data?.countPhrase ?? 0,
      countVocabulary: countWordInUser.data?.countVocabulary ?? 0,
      countVocabularyUnKnown: countWordInUser.data?.countVocabularyUnKnown ?? 0,
      fetching: false,
      percent: p,
      currentTopicInUserId: topicInUserId,
      percentGame: game,
      percentListening: listen,
      percentSpeaking: speak,
    }

    if (topicInUserId && currentPercent !== p) {
      await gql.updateTopicInUser({
        id: topicInUserId,
        data: {
          percent: speak,
          topicId,
          userLearnId: currentUserId,
        },
      })
    }
  }
}
