import Slider from '@react-native-community/slider'
import { useState } from 'react'
import { View } from 'react-native'

import { images } from '#assets'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export type SliderOption = {
  label: string
  value: number
}

type SliderProps = {
  options: SliderOption[]
  selectedValue: number
  setSelectedValue: (value: number) => void
  onSlidingComplete: (value: number) => void
}

export const SliderBar = ({
  options,
  selectedValue,
  setSelectedValue,
  onSlidingComplete,
}: SliderProps) => {
  const [sliderWidth, setSliderWidth] = useState(0)

  const handleLayout = (event: any) => {
    const { width } = event.nativeEvent.layout
    setSliderWidth(width)
  }

  const calculateMarkerLeft = (value: number) => {
    const leftPercentage =
      ((value - options[0].value) * 100) /
      (options[options.length - 1]?.value - options[0].value)
    return (leftPercentage / 100) * sliderWidth
  }

  return (
    <View style={tw`mt-2 items-center`} onLayout={handleLayout}>
      <View style={tw`w-full h-12 z-1 px-4`}>
        <Slider
          style={tw`w-full h-12`}
          minimumValue={options[0]?.value ?? 0}
          maximumValue={options[options.length - 1]?.value ?? 2}
          value={selectedValue}
          minimumTrackTintColor={tw.color('primary-400')}
          maximumTrackTintColor={'#E0E0E0'}
          thumbImage={{ uri: images.silderThumbIcon }}
          thumbTintColor={tw.color('background-light-2')}
          onValueChange={value => {
            setSelectedValue(value)
          }}
          onSlidingComplete={onSlidingComplete}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        />
      </View>

      {options.map((option, index) => {
        if (selectedValue === option.value) {
          return null
        }

        let markerLeft = calculateMarkerLeft(option.value)

        if (index === 0) {
          markerLeft += 20
        } else if (index === options.length - 1) {
          markerLeft -= 20
        }

        const isGreaterThanSelected = option.value > selectedValue

        return (
          <View
            key={index}
            style={{
              position: 'absolute',
              left: markerLeft,
              transform: [{ translateX: -8 }],
              top: 16,
              width: 16,
              height: 16,
              borderRadius: 8,
              backgroundColor: 'white',
              borderWidth: 3,
              borderColor: isGreaterThanSelected
                ? tw.color('neutral-300')
                : tw.color('primary-400'),
              zIndex: 3,
            }}
          />
        )
      })}

      {/* Labels */}
      <View style={tw`flex-row justify-between w-full mt-2 px-2`}>
        {options.map((option, index) => (
          <View
            key={index}
            style={tw`${index === 0 ? 'pl-0' : ''} ${
              index === options.length - 1 ? 'pr-0' : ''
            } items-center `}
          >
            <Text
              specialType='paragraph2'
              color={tw.color(
                selectedValue === option.value ? 'primary-400' : 'text-2',
              )}
            >
              {option.label}
            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}
