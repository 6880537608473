import { observer } from 'mobx-react-lite'
import type React from 'react'
import { View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import { goBack } from '#navigator/helpers'

type Pr = {
  closeActionsheet: () => void
}

export const ActionSheetCancelGame: React.FC<Pr> = observer(
  ({ closeActionsheet }) => {
    const handleContinue = () => {
      closeActionsheet()
    }

    const handleStop = async () => {
      closeActionsheet()
      goBack()
    }
    return (
      <ActionSheet
        containerStyle={tw`bg-background-light-1 flex-1`}
        isLoading={false}
      >
        <View style={tw.style('pt-6 pb-2.5 items-center')}>
          <SystemIcon name='warning' type='SVG' size={74} />
        </View>
        <View
          style={tw.style('pb-5 w-full flex items-center justify-center px-10')}
        >
          <Text specialType='Headline4' textAlign='center'>
            'Are you sure you want to exit? All your progress will be lost if
            you leave now.'
          </Text>
        </View>
        <View style={tw`flex-col flex-1 w-full`}>
          <Button
            onPress={handleContinue}
            tone='secondary'
            size='medium'
            style={tw`w-full mb-4 bg-warning-400`}
            titleColor={tw.color('text-4')}
          >
            Continue
          </Button>
          <Button
            onPress={handleStop}
            size='medium'
            style={tw`w-full bg-background-light-white`}
            tone='plain'
          >
            Stop
          </Button>
        </View>
      </ActionSheet>
    )
  },
)
