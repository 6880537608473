class WebAudioManager {
  private audio: HTMLAudioElement = new Audio()
  private url: string = ''
  private playing: boolean = false

  private onEnd = () => {
    this.audio.currentTime = 0
    this.playing = false
    if (this.onPlaybackEnd) {
      this.onPlaybackEnd()
    }
  }

  private onTimeUpdate = () => {
    const currentTime = this.getCurrentTime()
    if (this.onTimeUpdateCallback) {
      this.onTimeUpdateCallback(currentTime)
    }
  }

  private onPlaybackEnd?: () => void
  private onTimeUpdateCallback?: (currentTime: number) => void

  setUrl = (url: string) => {
    if (!url) {
      return
    }
    if (this.audio.currentTime > 0) {
      this.audio.pause()
      this.playing = false
    }
    this.audio.currentTime = 0
    this.audio.src = url
    this.url = url
    this.audio.load()
  }

  play = async (
    rate: number,
    onEnd?: () => void,
    onTimeUpdate?: (currentTime: number) => void,
  ) => {
    if (!this.url) {
      return
    }
    this.audio.playbackRate = rate
    this.audio.onended = () => {
      this.onEnd()
      if (onEnd) {
        onEnd()
      }
    }
    this.audio.ontimeupdate = () => {
      this.onTimeUpdate()
      if (onTimeUpdate) {
        onTimeUpdate(this.getCurrentTime())
      }
    }
    this.onPlaybackEnd = onEnd
    this.onTimeUpdateCallback = onTimeUpdate
    await this.audio.play()
    this.playing = true
  }

  pause = () => {
    this.audio.pause()
    this.playing = false
  }

  stop = () => {
    if (this.audio.currentTime > 0) {
      this.audio.pause()
      this.audio.currentTime = 0
    }
    this.playing = false
    this.audio.onended = null
    this.audio.ontimeupdate = null

    if (this.onPlaybackEnd) {
      this.onPlaybackEnd()
    }
  }

  setTime = (time: number) => {
    // ham tua thoi gian
    this.audio.currentTime = time
  }

  getCurrentTime = (): number => (this.audio ? this.audio.currentTime : 0)

  getIsPlaying = (): boolean => this.playing
}

export const audioManager = new WebAudioManager()
