import { View } from 'react-native'

import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

export const LaunchScreen = () => (
  <LinearGradientColor>
    <View style={tw`flex-1 justify-center items-center`}>
      <Text
        specialType='Headline1'
        color={tw.color('text-4')}
        style={tw`text-[64px]`}
      >
        CODY
      </Text>
    </View>
  </LinearGradientColor>
)
