import { useEffect, useState } from 'react'

import { audioManager } from './audioManager'

export type AudioManagerProps = {
  url: string
}

export type ResultAudioManager = {
  isPlay: boolean
  play: (rate: number) => void
  stop: () => void
}

export const useAudioManager = ({
  url,
}: AudioManagerProps): ResultAudioManager => {
  const [isPlay, setIsPlay] = useState<boolean>(false)

  useEffect(() => {
    if (url) {
      audioManager.setUrl(url)
      handlePlayAudio(1)
    }

    return () => {
      audioManager.stop()
    }
  }, [url])

  const handlePlayAudio = async (rate: number) => {
    if (!isPlay) {
      playAudio(rate)
      setIsPlay(true)
    }
  }

  const playAudio = (rate: number) => {
    audioManager.play(rate, () => {
      setIsPlay(false)
    })
  }

  const handleStop = () => {
    audioManager.stop()
    setIsPlay(false)
  }

  return {
    isPlay,
    play: handlePlayAudio,
    stop: handleStop,
  }
}
