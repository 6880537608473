import { ulid } from 'ulidx'

import { isValidAudioUrl, isValidImageUrl } from '#components/utils/checkMedia'
import { gql } from '#graphql/urql'
import type {
  AnswerGame,
  GamesProps,
  GenerateProps,
  QuestionGame,
} from '#types/games'
import { EasyGame } from '#types/games'

import { getItemInList } from './getItemInList'

export const generateRearrangement = async ({
  item,
  isPhrase,
}: GenerateProps): Promise<GamesProps> => {
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const resp = await gql.searchTranslate({
    filter: { wQAId: item.wordId },
  })
  const searchTranslate = resp.data?.searchTranslate || []
  let translation = ''
  if (searchTranslate.length > 0) {
    translation = searchTranslate[0]?.text || ''
  }
  const questionData: QuestionGame = {
    text: item.text,
    media: getItemInList(audios)?.media || null,
    type: EasyGame.rearrangement,
    translation,
    index: 0,
    isPhrase,
  }
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const answerCorrect: AnswerGame = {
    audio: getItemInList(audios)?.media || null,
    image: getItemInList(images)?.media || null,
    isCorrect: true,
    text: item.text,
    translation: '',
    type: EasyGame.rearrangement,
    id: ulid(),
    answer: item.text,
  }
  return {
    question: questionData,
    answers: [answerCorrect],
  }
}
