import { EditOutlined } from '@ant-design/icons'
import { Button, Flex, Select, Spin, Typography } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { useEffect, useState } from 'react'

import { useDebounceValue } from '#admin/components/utils/useDebounceValue'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useCountWord, useSearchTopic, useSearchWord } from '#graphql/codegen'
import { ConvertLanguage } from '#types/language'
import type { SearchWordItem } from '#types/word'

import { TranslateDetailModal } from './TranslateDetailModal'

export const WordAndPhrases = () => {
  const [searchTopic, setSearchTopic] = useState('')
  const [filterTopic, setFilterTopic] = useState<string[]>([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const { openModal } = useOverlay()
  const [, setInputTopic, debouncedKeyword] = useDebounceValue<string>('', 1000)

  useEffect(() => {
    setSearchTopic(debouncedKeyword)
  }, [debouncedKeyword, setSearchTopic])

  const debounceFetcher = (value: string) => {
    setInputTopic(value)
  }

  useEffect(() => {
    setSearchTopic('')
    setCurrentPage(1)
  }, [filterTopic])

  const [countWord, refetchCount] = useCountWord({
    variables: {
      filter: {
        AND: [
          searchKeyword
            ? {
                vocabAndPhrase_iLike: `%${searchKeyword}%`,
              }
            : {},
          filterTopic.length > 0
            ? {
                topic_some: { name_in: filterTopic },
              }
            : {},
          {
            topic_some: { status_in: ['Pending', 'Active'] },
          },
        ],
      },
    },
  })

  const [word, refetchWord] = useSearchWord({
    variables: {
      filter: {
        AND: [
          searchKeyword
            ? {
                vocabAndPhrase_iLike: `%${searchKeyword}%`,
              }
            : {},
          filterTopic.length > 0
            ? {
                topic_some: { name_in: filterTopic },
              }
            : {},
          {
            topic_some: { status_in: ['Pending', 'Active'] },
          },
        ],
      },
      order: ['vocabAndPhrase_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [topic, refetchTopic] = useSearchTopic({
    variables: {
      filter: { name_iLike: `%${searchTopic}%` },
      order: ['name_asc'],
      page: { limit: 10, offset: 0 },
    },
  })

  const searchWord: SearchWordItem[] = word.data?.searchWord ?? []

  const onModalOk = () => {
    refetchWord({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })
    refetchTopic({ requestPolicy: 'network-only' })
  }

  const handleEditData = (record: SearchWordItem, type: string) => {
    openModal(TranslateDetailModal, {
      onOk: onModalOk,
      isUpdate: false,
      containerProps: {
        closeOnOverlayClick: false,
      },
      word: {
        id: record.id,
        name: record.vocabAndPhrase,
      },
      type,
      translateData: {
        id: record.translate?.id,
        name: record.translate?.text,
      },
      languageTopic: record.topic?.languageTopic,
    })
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (_, record) => (
      <Flex style={tw`flex justify-center`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            handleEditData(record, 'word')
          }}
          className='p-0 bg-transparent'
        />
      </Flex>
    ),
  }

  const columns: ColumnType<SearchWordItem>[] = [
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
      render: (_, record) => <Typography>{record.topic?.name}</Typography>,
    },
    {
      title: 'Word and Phrases',
      dataIndex: 'wordAndPhrases',
      key: 'text',
      render: (_, record) => <Typography>{record.vocabAndPhrase}</Typography>,
    },
    {
      title: 'Language translate',
      dataIndex: 'languageTranslate',
      key: 'languageTranslate',
      render: (_, record) => (
        <Typography>
          {`${record.topic?.languageTopic ?? ''} -> ` +
            (ConvertLanguage[record.translate?.suportLanguage ?? ''] ?? '')}
        </Typography>
      ),
    },
    {
      title: 'Translate',
      dataIndex: 'translate',
      key: 'translate',
      render: (_, record) => <Typography>{record.translate?.text}</Typography>,
    },
    actionsColumn,
  ]

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
      <Flex gap={8}>
        <div style={tw`w-full`}>
          <Typography>Topic</Typography>
          <Select
            mode='multiple'
            defaultValue={filterTopic}
            onSearch={debounceFetcher}
            notFoundContent={topic.fetching ? <Spin size='small' /> : null}
            style={{
              width: '100%',
            }}
            options={topic.data?.searchTopic.map(e => ({ value: e.name }))}
            onChange={e => setFilterTopic(e)}
          />
        </div>
        <div style={tw`w-full`}>
          <Typography>Word and Phrases</Typography>
          <SearchButton
            keyword={(k: string) => {
              setSearchKeyword(k)
              setCurrentPage(1)
            }}
          />
        </div>
      </Flex>

      <PaginatedTable<SearchWordItem>
        dataSource={searchWord}
        columns={columns}
        loading={word.fetching}
        total={countWord.data?.countWord ? countWord.data?.countWord : 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'50vh'}
      />
    </Flex>
  )
}
