import type { IconProps } from 'iconsax-react-native'
import {
  ArchiveTick,
  ArrowDown2,
  ArrowLeft,
  ArrowLeft2,
  ArrowRight,
  ArrowRight2,
  ArrowUp,
  ArrowUp2,
  Camera,
  Category,
  CloseCircle,
  Edit,
  Edit2,
  Eye,
  EyeSlash,
  FlashCircle,
  Gallery,
  HambergerMenu,
  Headphone,
  InfoCircle,
  Key,
  Keyboard,
  LampCharge,
  Lock1,
  LoginCurve,
  MessageQuestion,
  Microphone2,
  Moon,
  Notepad2,
  Notification,
  Pause,
  Play,
  Send,
  Send2,
  Setting2,
  ShieldTick,
  Sms,
  Speaker,
  Star,
  Star1,
  TaskSquare,
  Teacher,
  TickCircle,
  Translate,
  Trash,
  VolumeHigh,
} from 'iconsax-react-native'
import type { ColorValue, TouchableOpacityProps } from 'react-native'
import { TouchableOpacity } from 'react-native'

export type IconSaxName =
  | 'Key'
  | 'ArrowRight2'
  | 'Notification'
  | 'CloseCircle'
  | 'TickCircle'
  | 'Trash'
  | 'HambergerMenu'
  | 'Send'
  | 'Send2'
  | 'FlashCircle'
  | 'Edit'
  | 'Edit2'
  | 'Eye'
  | 'EyeSlash'
  | 'Gallery'
  | 'Camera'
  | 'Translate'
  | 'ArrowLeft2'
  | 'Category'
  | 'Setting2'
  | 'Teacher'
  | 'LampCharge'
  | 'Microphone2'
  | 'Play'
  | 'VolumeHigh'
  | 'Keyboard'
  | 'TaskSquare'
  | 'Speaker'
  | 'Headphone'
  | 'InfoCircle'
  | 'Lock1'
  | 'LoginCurve'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowDown2'
  | 'Star'
  | 'Star1'
  | 'ArchiveTick'
  | 'Moon'
  | 'ShieldTick'
  | 'Pause'
  | 'MessageQuestion'
  | 'Sms'
  | 'Notepad2'
  | 'ArrowUp2'
  | 'ArrowUp'

const IconComponents: Record<IconSaxName, React.FC<IconProps>> = {
  Key,
  ArrowRight2,
  Notification,
  CloseCircle,
  TickCircle,
  Trash,
  HambergerMenu,
  Send,
  Send2,
  FlashCircle,
  Edit,
  Edit2,
  Eye,
  EyeSlash,
  Gallery,
  Camera,
  Translate,
  ArrowLeft2,
  Category,
  Setting2,
  Teacher,
  LampCharge,
  Microphone2,
  Play,
  Keyboard,
  TaskSquare,
  Headphone,
  InfoCircle,
  VolumeHigh,
  Speaker,
  Lock1,
  LoginCurve,
  ArrowRight,
  ArrowLeft,
  ArrowDown2,
  Star,
  Star1,
  ArchiveTick,
  Moon,
  ShieldTick,
  Pause,
  MessageQuestion,
  Sms,
  Notepad2,
  ArrowUp2,
  ArrowUp,
}

export type IconSaxProps = IconProps & {
  name: IconSaxName
  color?: ColorValue
  size?: number
  onPress?: () => void
  buttonProps?: TouchableOpacityProps
}

export const IconSax: React.FC<IconSaxProps> = ({
  name,
  color = '#292D32',
  variant = 'Linear',
  size = 24,
  onPress,
  buttonProps,
}) => {
  const IconComponent = IconComponents[name]
  if (!IconComponent) {
    return null
  }
  return (
    <TouchableOpacity disabled={!onPress} {...buttonProps} onPress={onPress}>
      <IconComponent color={color} variant={variant} size={size} />
    </TouchableOpacity>
  )
}
