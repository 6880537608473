import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import {
  Animated,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native'
import type { NavigationState, SceneRendererProps } from 'react-native-tab-view'
import { TabView } from 'react-native-tab-view'

import { Avatar } from '#components/base/Avatar'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { NotificationIcon } from '#components/widgets/NotificationIcon'
import { S } from '#store'

import { CompletedRoute } from './CompletedRoute'
import { TopicsRoute } from './TopicsRoute'

type Route = {
  key: string
  title: string
}

type State = NavigationState<Route>

export const Topics = observer(() => {
  const layout = useWindowDimensions()

  const [tabIndex, setTabIndex] = useState<number>(0)
  const [routes] = useState<Route[]>([
    { key: 'lessons', title: 'Lessons' },
    { key: 'completed', title: 'Completed' },
  ])

  const renderItem =
    ({
      navigationState,
      position,
    }: {
      navigationState: State
      position: Animated.AnimatedInterpolation<number>
    }) =>
    ({ route, index }: { route: Route; index: number }) => {
      const inputRange = navigationState.routes.map((_, i) => i)
      const activeOpacity = position.interpolate({
        inputRange,
        outputRange: inputRange.map((i: number) => (i === index ? 1 : 0)),
      })
      const inactiveOpacity = position.interpolate({
        inputRange,
        outputRange: inputRange.map((i: number) => (i === index ? 0 : 1)),
      })

      return (
        <View style={tw.style('flex-1 py-3 items-center')}>
          <Animated.View
            style={[
              tw.style('items-center justify-center'),
              { opacity: inactiveOpacity },
            ]}
          >
            <View style={tw.style('items-center justify-center px-1.5')}>
              <Text
                specialType='Headline3'
                textAlign='center'
                color={tw.color('text-3')}
              >
                {route.title}
              </Text>
              <View style={tw.style('w-full h-1 mt-1')} />
            </View>
          </Animated.View>
          <Animated.View
            style={[
              tw.style('items-center justify-center absolute inset-0'),
              { opacity: activeOpacity },
            ]}
          >
            <View style={tw.style('items-center justify-center px-1.5')}>
              <Text
                specialType='Headline3'
                textAlign='center'
                color={tw.color('primary-400')}
              >
                {route.title}
              </Text>
              <View
                style={tw.style('w-full h-1 rounded-full bg-primary-400 mt-1')}
              />
            </View>
          </Animated.View>
        </View>
      )
    }

  const renderTabBar = (
    props: SceneRendererProps & { navigationState: NavigationState<Route> },
  ) => (
    <View
      style={tw`flex-row justify-between items-center mt-2 px-4 pb-4 border-b border-neutral-200`}
    >
      <Avatar
        size='large'
        name={S.shared.currentUser?.name}
        style={tw`w-12 h-12 rounded-full`}
        source={{ uri: S.shared.currentUser?.thumbnail?.url ?? '' }}
      />
      <View style={tw`flex-1 flex-row items-center px-2 justify-center`}>
        {props.navigationState.routes.map((route: Route, index: number) => (
          <TouchableWithoutFeedback
            key={route.key}
            onPress={() => props.jumpTo(route.key)}
          >
            {renderItem(props)({ route, index })}
          </TouchableWithoutFeedback>
        ))}
      </View>
      <NotificationIcon />
    </View>
  )

  const renderScene = ({ route }: { route: Route }) => {
    switch (route.key) {
      case 'lessons':
        return <TopicsRoute />
      case 'completed':
        return <CompletedRoute />
      default:
        return null
    }
  }

  return (
    <View style={tw.style('flex-1')}>
      <TabView
        navigationState={{ index: tabIndex, routes }}
        renderScene={renderScene}
        onIndexChange={setTabIndex}
        initialLayout={{ width: layout.width }}
        renderTabBar={renderTabBar}
        lazy
      />
    </View>
  )
})
