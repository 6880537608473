import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import type { LayoutChangeEvent } from 'react-native'
import { TouchableOpacity, View } from 'react-native'

import { Text } from '#components/base/Text'
import { shuffleArray } from '#components/utils/random'
import { tw } from '#components/utils/tw'

export type RearrangementAnswerProps = {
  word: { text: string; isPhrase: boolean } | null
  currentIndex: number
  onChangeData?: (isTrue: boolean) => void
}
type SelectedWord = {
  wordItem: string
  index: number
}

export type RearrangementRef = {
  hint: () => void
  handleIDontKnowClick: () => void
}

export const RearrangementAnswer = forwardRef<
  RearrangementRef,
  RearrangementAnswerProps
>(({ word, currentIndex, onChangeData }, ref) => {
  const [selectedWords, setSelectedWords] = useState<SelectedWord[]>([])
  const [heightUnderlined, setHeightUnderlined] = useState<number>(10)
  const [words, setWords] = useState<string[]>([])

  useEffect(() => {
    const w = !word?.isPhrase
      ? word?.text.split('') ?? []
      : word?.text.split(' ') ?? []
    const d = w.map(item => item.toLowerCase())
    const wordsRandom = shuffleArray(d)
    setWords(wordsRandom)
    setSelectedWords([])
  }, [word?.text, currentIndex])

  useImperativeHandle(ref, () => ({ hint, handleIDontKnowClick }), [
    selectedWords,
  ])

  const handleWordClick = async (index: number) => {
    const newSelectedWords = [
      ...selectedWords,
      { wordItem: words[index], index },
    ]
    setSelectedWords(newSelectedWords)
    const selectedWordsOnly = newSelectedWords.map(sw => sw.wordItem)
    if (words.length == selectedWordsOnly.length) {
      const vocab = !word?.isPhrase
        ? selectedWordsOnly.join('')
        : selectedWordsOnly.join(' ')
      const isCorrectAnswer = vocab.toLowerCase() === word?.text?.toLowerCase()
      onChangeData?.(isCorrectAnswer)
    }
  }

  const handleRemoveClick = (index: number) => {
    const newSelectedWords = selectedWords.filter(
      (_, wordIndex) => wordIndex !== index,
    )
    setSelectedWords(newSelectedWords)
  }

  const handleIDontKnowClick = () => {
    const w = !word?.isPhrase
      ? word?.text.split('') ?? []
      : word?.text.split(' ') ?? []
    const d = w.map(value => value.toLowerCase())

    const occurrencesMap = new Map<string, number[]>()
    d.forEach((item, index) => {
      if (!occurrencesMap.has(item)) {
        occurrencesMap.set(item, [])
      }
      occurrencesMap.get(item)?.push(index)
    })

    const newAnswers: SelectedWord[] = []
    occurrencesMap.forEach((indices, wordItem) => {
      words.forEach((i, idx) => {
        if (i === wordItem) {
          newAnswers.push({ wordItem, index: idx })
        }
      })
    })
    setSelectedWords(newAnswers)
    onChangeData?.(false)
  }

  const hint = () => {
    const w = !word?.isPhrase
      ? word?.text.split('') ?? []
      : word?.text.split(' ') ?? []
    const d = w.map(value => value.toLowerCase())
    const indexedA = d.map((item, index) => ({ wordItem: item, index }))
    if (selectedWords.length === indexedA.length) {
      return
    }
    let currentIdx = -1
    for (let i = 0; i < selectedWords.length; i++) {
      if (indexedA[i].wordItem !== selectedWords[i].wordItem) {
        break
      } else {
        currentIdx = i
      }
    }
    const newAnswers = indexedA.slice(0, currentIdx + 2).map(answer => {
      const found = words.findIndex(i => i === answer.wordItem)
      return { ...answer, index: found }
    })
    setSelectedWords(newAnswers)
    if (newAnswers?.length === words.length) {
      onChangeData?.(true)
    }
  }

  const getLayout = (event: LayoutChangeEvent) => {
    const height = event.nativeEvent.layout.height
    setHeightUnderlined(height + 32)
  }

  return (
    <View style={tw`flex-1 flex-col px-4`}>
      <View
        style={tw.style(
          'w-full py-4 mb-5 mt-7 flex-row flex-wrap border border-2xl border-neutral-200 bg-background-light-1 rounded-2xl',
          { height: heightUnderlined, alignContent: 'flex-start' },
        )}
      >
        {selectedWords.map(({ wordItem }, index) => (
          <TouchableOpacity
            key={index}
            style={[
              tw`m-1 p-4 h-7.5 bg-white rounded-5 items-center justify-center border border-neutral-200`,
            ]}
            onPress={() => handleRemoveClick(index)}
          >
            <Text specialType='paragraph1' style={tw`text-base`}>
              {wordItem}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      <View onLayout={getLayout} style={tw`w-full flex-row flex-wrap`}>
        {words.map((item, index) => (
          <TouchableOpacity
            key={index}
            style={[
              tw.style(
                selectedWords.some(selected => selected.index === index)
                  ? 'm-1.5 p-4 h-7.5 bg-neutral-200 rounded-5 justify-center border border-neutral-200'
                  : 'm-1.5 p-4 h-7.5 bg-white rounded-5 justify-center border border-neutral-200',
              ),
            ]}
            onPress={() => handleWordClick(index)}
            disabled={selectedWords.some(selected => selected.index === index)}
          >
            <Text
              style={tw.style(
                selectedWords.some(selected => selected.index === index)
                  ? 'text-neutral-200'
                  : 'text-black',
              )}
              specialType='paragraph1'
            >
              {item}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
})
