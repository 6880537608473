import type { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { MotiView } from 'moti'
import { Pressable, useWindowDimensions, View } from 'react-native'

import { Icon } from '#components/base/Icon'
import { useDarkBg, useDarkColor, useDarkText } from '#components/dark/hooks'
import { tw } from '#components/utils/tw'
import { Profile } from '#screens/Profile'
import { Topics } from '#screens/Topics'

import type { HomeBottomTabParamList } from './types'

const tabs = [
  {
    name: 'Topics',
    component: Topics,
    iconProvider: 'Ionicons',
    iconName: 'home-outline',
  },
  {
    name: 'Profile',
    component: Profile,
    iconProvider: 'Feather',
    iconName: 'user',
  },
] as const

const top = 8
const left = 16
const tabBarHeight = 56
const tabHeight = tabBarHeight

const BottomTabBar = ({ state, navigation }: MaterialTopTabBarProps) => {
  const bg = useDarkBg()
  const txt = useDarkText()
  const dark = useDarkColor()
  const { width } = useWindowDimensions()
  const tabBarWidth = width
  const tabWidth = tabBarWidth / tabs.length

  return (
    <View
      style={tw.style(
        'relative w-full flex-row shadow-md border-t-[1px]',
        bg,
        `border-[${dark('gray-800', 'gray-100')}]`,
        { width: tabBarWidth, height: tabBarHeight },
      )}
    >
      <MotiView
        animate={{ left: state.index * tabWidth + left }}
        transition={{ duration: 150, type: 'timing' }}
        style={tw.style('absolute rounded-3xl bg-primary-500/20', {
          width: tabWidth - left * 2,
          height: tabHeight - top * 2,
          top,
        })}
      />
      {state.routes.map((r, i) => {
        const isFocused = state.index === i
        const tabInfo = tabs.find(t => t.name === r.name)!

        const handlePress = () => {
          const e = navigation.emit({
            type: 'tabPress',
            target: r.key,
            canPreventDefault: true,
          })
          if (!isFocused && !e.defaultPrevented) {
            navigation.navigate(r.name, r.params)
          }
        }

        return (
          <Pressable
            key={r.key}
            style={tw.style('items-center justify-center', {
              width: tabWidth,
              height: tabHeight,
            })}
            onPress={handlePress}
          >
            <Icon
              provider={tabInfo.iconProvider}
              name={tabInfo.iconName}
              style={tw.style(
                `${txt} text-xl`,
                isFocused && 'text-primary-500',
              )}
            />
          </Pressable>
        )
      })}
    </View>
  )
}

const BottomTab = createMaterialTopTabNavigator<HomeBottomTabParamList>()
export const HomeBottomTab = () => (
  <BottomTab.Navigator
    tabBarPosition='bottom'
    tabBar={props => <BottomTabBar {...props} />}
  >
    {tabs.map(route => (
      <BottomTab.Screen
        key={route.name}
        name={route.name}
        component={route.component}
        options={{ lazy: true }}
      />
    ))}
  </BottomTab.Navigator>
)
