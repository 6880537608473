import type React from 'react'
import type { FlatListProps } from 'react-native'
import { View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { tw } from '#components/utils/tw'
import type { TasksItem } from '#graphql/codegen'

import { TaskItem as TaskItemComp } from './TaskItem'

type ITasksProps = {
  data: TasksItem[]
  ListHeaderComponent?: FlatListProps<TasksItem>['ListHeaderComponent']
  contentContainerStyle?: FlatListProps<TasksItem>['contentContainerStyle']
  check: string[]
}

export const ListTask: React.FC<ITasksProps> = ({
  data,
  ListHeaderComponent,
  contentContainerStyle,
  check,
}) => {
  const renderItem = ({ item, index }: { item: TasksItem; index: number }) => (
    <TaskItemComp item={item} index={index} checkTaskItem={check} />
  )

  const renderItemSeparator = () => (
    <View style={tw`h-[0.5px] bg-neutral-200 my-3`} />
  )

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      ItemSeparatorComponent={renderItemSeparator}
      ListHeaderComponent={ListHeaderComponent}
      contentContainerStyle={contentContainerStyle}
    />
  )
}
