import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { TouchableOpacity } from 'react-native'
import type { Style } from 'twrnc'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { S } from '#store'
import type { AnswerItemProps, LayoutState } from '#types/games'

const layoutStyle: Record<LayoutState, Style> = {
  True: tw`border-success-500 bg-success-50 border-2`,
  False: tw`border-error-300 border-2`,
  Unselected: tw``,
}

export const AnswerItem: FC<AnswerItemProps> = observer(
  ({ item, onPressAnswer, layoutStates, hint, index }) => {
    const native = S.shared.currentUser?.nativeLanguage
    const learn = S.shared.currentUser?.languageLearn
    return (
      <TouchableOpacity
        onPress={onPressAnswer}
        disabled={hint.length > 0 ? hint.includes(index) : false}
        style={tw.style(
          'rounded-2xl px-4 border border-neutral-200 w-full flex-row items-center justify-center',
          layoutStyle[layoutStates],
          item.type === 'yesNo' ? 'py-18' : 'py-7',
        )}
      >
        {item.type === 'correctTranslation' &&
        native != learn &&
        item.translation ? (
          <Text specialType='Headline4' textAlign='center' style={tw`min-h-6`}>
            {!hint.includes(index) ? item.translation : ''}
          </Text>
        ) : (
          <Text specialType='Headline4' textAlign='center' style={tw`min-h-6`}>
            {!hint.includes(index) ? item.text || '' : ''}
          </Text>
        )}
      </TouchableOpacity>
    )
  },
)
