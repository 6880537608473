import { useRef, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { SystemIcon } from '#components/base/SystemIcon'
import { tw } from '#components/utils/tw'
import type { FooterButtonType } from '#types/chat'

import { BreathingButton } from './BreathButton'

type Props = {
  selectedButton: FooterButtonType
  onButtonPress: (buttonType: FooterButtonType) => void
  bufferCallback: (buffer: string) => void
}

const arrayBufferToBase64 = (buffer: Uint8Array) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export const Footer = ({
  selectedButton,
  onButtonPress,
  bufferCallback,
}: Props) => {
  const [r, setR] = useState<boolean>(false)
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)

  const audioContextRef = useRef<AudioContext | null>(null)
  const analyserNodeRef = useRef<AnalyserNode | null>(null)
  const dataArrayRef = useRef<Uint8Array | null>(null)

  const state = useRef<FooterButtonType>(null)

  const requestMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const recorder = new MediaRecorder(stream)
      setMediaRecorder(recorder)

      const audioContext = new (window.AudioContext ||
        (window as any).webkitAudioContext)()
      audioContextRef.current = audioContext

      const analyserNode = audioContext.createAnalyser()
      analyserNode.fftSize = 2048
      analyserNodeRef.current = analyserNode

      const source = audioContext.createMediaStreamSource(stream)
      source.connect(analyserNode)

      const bufferLength = analyserNode.frequencyBinCount
      dataArrayRef.current = new Uint8Array(bufferLength)

      recorder.ondataavailable = event => {
        if (event.data.size > 0) {
          const reader = new FileReader()
          reader.readAsArrayBuffer(event.data)

          reader.onload = () => {
            const arrayBuffer = reader.result as ArrayBuffer
            const uint8Array = new Uint8Array(arrayBuffer)
            const base64String = arrayBufferToBase64(uint8Array)
            console.log('state', state.current)

            if (state.current !== 'cancel') {
              bufferCallback(base64String)
            }
            state.current === null
          }
        }
      }

      recorder.onstop = () => {
        setR(false)
      }

      recorder.start()
      setPermissionGranted(true)
    } catch (error) {
      console.error('Microphone access denied or error occurred', error)
      setPermissionGranted(false)
    }
  }

  const handleStopRecording = () => {
    mediaRecorder?.stop()
    setR(false)
  }
  const handleStopRecordingWithoutBuffer = () => {
    state.current = 'cancel'
    mediaRecorder?.stop()
    setR(false)
    handlePress('cancel')
  }

  const handlePress = (buttonType: FooterButtonType) => {
    onButtonPress(buttonType)
    if (buttonType === 'speak') {
      setR(prev => !prev)
      if (!r) {
        if (permissionGranted) {
          mediaRecorder?.start()
        } else {
          requestMicPermission()
        }
      } else {
        handleStopRecording()
      }
    }
  }

  return (
    <View
      style={tw.style('flex-col p-4 bg-background-light-white rounded-t-3xl ')}
    >
      {/* <View style={tw.style('items-center pb-4')}>
        <Text specialType='Title' color={tw.color('text-2')}>
          Tap to record
        </Text>
      </View> */}

      <View style={tw`flex-row justify-center items-center `}>
        {selectedButton !== 'speak' ? (
          <ButtonIcon
            icon={{
              type: 'SAX',
              name: 'Keyboard',
              color:
                selectedButton === 'input'
                  ? tw.color('primary-400')
                  : tw.color('icon'),
            }}
            tone='third'
            bg={tw.color('background-light-2')}
            style={tw.style(
              selectedButton === 'input'
                ? 'border border-solid border-primary-400'
                : 'border-0',
              { boxShadow: '' },
            )}
            onPress={() => handlePress('input')}
          />
        ) : (
          <ButtonIcon
            icon={{
              type: 'SVG',
              name: 'x',
              color: tw.color('icon'),
            }}
            tone='third'
            bg={tw.color('background-light-2')}
            style={tw.style({ boxShadow: '' })}
            onPress={() => handleStopRecordingWithoutBuffer()}
          />
        )}

        <View>
          <TouchableOpacity
            style={tw`flex-1 rounded-full overflow-hidden mx-6 p-3`}
            onPress={() => handlePress('speak')}
          >
            {selectedButton !== 'speak' ? (
              <View
                style={tw.style(
                  'py-5 px-12 flex-1 rounded-full bg-primary-400',
                )}
              >
                <SystemIcon
                  type='SAX'
                  size={40}
                  name='Microphone2'
                  variant='Bold'
                  color={'white'}
                />
              </View>
            ) : (
              <View style={tw.style('flex-1 ')}>
                <BreathingButton />
              </View>
            )}
          </TouchableOpacity>
        </View>

        <ButtonIcon
          icon={{
            type: 'SAX',
            name: 'LampCharge',
            color:
              selectedButton === 'suggest'
                ? tw.color('primary-400')
                : tw.color('icon'),
          }}
          tone='third'
          bg={tw.color('background-light-2')}
          style={tw.style(
            selectedButton === 'suggest'
              ? 'border border-solid border-primary-400'
              : 'border-0',
            { boxShadow: '' },
            selectedButton === 'speak' ? { opacity: 0 } : '',
          )}
          disabled={selectedButton === 'speak'}
          onPress={() => handlePress('suggest')}
        />
      </View>
    </View>
  )
}
