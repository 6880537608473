import { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { FlatList } from '#components/base/FlatList'
import { Image } from '#components/base/Image'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import { useSearchProduct } from '#graphql/codegen'
import type { ProductItem } from '#types/product'

import { ActionSheetStripe } from './ActionSheetStripe'
import { PaymentRadioButton } from './PaymentRadioButton'

export const Payment = () => {
  const [data] = useSearchProduct({ variables: { filter: { active: true } } })
  const products = data?.data?.searchProduct || []

  const [selectedPlanId, setSelectedPlanId] = useState<string>('')
  const [selectedPlan, setSelectedPlan] = useState<ProductItem | null>(null)

  const { openActionsheet } = useOverlay()

  useEffect(() => {
    if (products.length > 0) {
      const firstProduct = products[0]
      setSelectedPlanId(firstProduct.id)
      setSelectedPlan(firstProduct)
    }
  }, [products])

  useEffect(() => {
    const plan = products.find(product => product.id === selectedPlanId)
    setSelectedPlan(plan || null)
  }, [selectedPlanId, products])

  const onButtonPress = () => {
    openActionsheet(ActionSheetStripe, { selectedPlan })
  }

  const BenefitItem = ({
    features,
  }: {
    features?: { image?: string; title?: string; description?: string }[]
  }) => (
    <View style={tw.style('flex-col gap-2')}>
      {features?.map((feature, index) => (
        <View key={index} style={tw.style('flex-col gap-2')}>
          <View style={tw.style('flex-row gap-2')}>
            <View style={tw.style('w-11 h-11')}>
              <Image
                source={{ uri: feature.image }}
                width={44}
                height={44}
                style={tw.style({ aspectRatio: 1 / 1 })}
              />
            </View>
            <View style={tw.style('flex-1')}>
              <Text specialType='Title'>{feature.title}</Text>
              <Text specialType='Note'>{feature.description}</Text>
            </View>
          </View>
          {index !== features.length - 1 ? (
            <Divider style={tw.style('border-neutral-200')} />
          ) : null}
        </View>
      ))}
    </View>
  )

  const renderBenefitItem = () => {
    const features = selectedPlan?.marketingFeatures?.features
    return <BenefitItem features={features} />
  }

  const renderPlanItem = ({ item }: { item: ProductItem }) => (
    <PaymentRadioButton
      key={item.id}
      title={item.name}
      description={item.description}
      price={item.price?.unitAmount || 0}
      selected={selectedPlanId === item.id}
      onSelect={() => setSelectedPlanId(item.id)}
      discount={0}
    />
  )

  const renderItemSeparator = () => <View style={tw.style('h-3 w-full')} />

  return (
    <View style={tw.style('flex-1 bg-background-light-2')}>
      <Header isBack iconType='arrow' />
      <ScrollView
        contentContainerStyle={tw`p-4 flex-grow`}
        showsVerticalScrollIndicator={false}
      >
        <View style={tw.style('items-center pb-6')}>
          <SystemIcon type='SVG' name='paymentGiftHeader' size={160} />
          <View style={tw.style('flex-row justify-center')}>
            {selectedPlan ? (
              <>
                <Text specialType='Headline2'>
                  {selectedPlan.titleGif || ''}{' '}
                </Text>
                <Text specialType='Headline2' color={tw.color('primary-400')}>
                  {selectedPlan.titlePlansGif || ''}
                </Text>
              </>
            ) : null}
          </View>
          <Text specialType='paragraph2' textAlign='center'>
            {selectedPlan?.subTitleGif || ''}
          </Text>
        </View>
        <View style={tw.style('flex-row items-center pb-4')}>
          <Divider style={tw.style('border-neutral-200')} />
          <View style={tw.style('px-1')}>
            <Text specialType='Headline4' color={tw.color('primary-400')}>
              Key benefits
            </Text>
          </View>
          <Divider style={tw.style('border-neutral-200')} />
        </View>
        <View
          style={tw.style('bg-background-light-white rounded-2xl mb-4 p-4')}
        >
          {renderBenefitItem()}
        </View>
        <FlatList
          data={products}
          renderItem={renderPlanItem}
          ItemSeparatorComponent={renderItemSeparator}
        />
      </ScrollView>
      <View style={tw.style('p-4 bg-background-light-2')}>
        <Button onPress={onButtonPress} style={tw.style('mb-3')}>
          Subscribe now
        </Button>
        <View style={tw.style('flex-row self-center')}>
          <Text specialType='Title' color={tw.color('text-2')}>
            Cancel anytime.{' '}
          </Text>
          <Text specialType='Title' color={tw.color('text-2')}>
            Term{' '}
          </Text>
          <Text specialType='Title' color={tw.color('text-2')}>
            Privacy
          </Text>
        </View>
      </View>
    </View>
  )
}
