import type { FC } from 'react'
import { useState } from 'react'
import {
  Animated,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native'
import type { NavigationState, SceneRendererProps } from 'react-native-tab-view'
import { TabView as TabViewRN } from 'react-native-tab-view'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

import { WordOrPhrase } from './WordOrPhrase'

type Props = { topicId: string }

type Route = {
  key: string
  title: string
}

type State = NavigationState<Route>

export const TabView: FC<Props> = ({ topicId }) => {
  const layout = useWindowDimensions()
  // const MAX_WIDTH = layout.width - 32
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [routes] = useState<Route[]>([
    { key: 'words', title: 'Words' },
    { key: 'phrases', title: 'Phrases' },
  ])

  const renderScene = ({ route }: { route: Route }) => {
    switch (route.key) {
      case 'words':
        return <WordOrPhrase topicId={topicId} isPhrase={false} />
      case 'phrases':
        return <WordOrPhrase topicId={topicId} isPhrase />
      default:
        return null
    }
  }

  const renderItem =
    ({
      navigationState,
      position,
    }: {
      navigationState: State
      position: Animated.AnimatedInterpolation<number>
    }) =>
    ({ route, index }: { route: Route; index: number }) => {
      const inputRange = navigationState.routes.map((_, i) => i)
      const activeOpacity = position.interpolate({
        inputRange,
        outputRange: inputRange.map((i: number) => (i === index ? 1 : 0)),
      })
      const inactiveOpacity = position.interpolate({
        inputRange,
        outputRange: inputRange.map((i: number) => (i === index ? 0 : 1)),
      })

      return (
        <View style={tw.style('flex-1 py-3 items-center')}>
          <Animated.View
            style={[
              tw.style('items-center justify-center'),
              { opacity: inactiveOpacity },
            ]}
          >
            <Text
              specialType='Title'
              textAlign='center'
              color={tw.color('text-1')}
            >
              {route.title}
            </Text>
          </Animated.View>
          <Animated.View
            style={[
              tw.style(
                'items-center justify-center absolute inset-0 rounded-full bg-background-light-white',
              ),
              { opacity: activeOpacity },
            ]}
          >
            <Text
              specialType='Title'
              textAlign='center'
              color={tw.color('primary-400')}
            >
              {route.title}
            </Text>
          </Animated.View>
        </View>
      )
    }

  const renderTabBar = (
    props: SceneRendererProps & { navigationState: NavigationState<Route> },
  ) => (
    <View
      style={tw`flex-row justify-between bg-background-light-2 mx-4 p-1 rounded-full`}
    >
      {props.navigationState.routes.map((route: Route, index: number) => (
        <TouchableWithoutFeedback
          key={route.key}
          onPress={() => props.jumpTo(route.key)}
        >
          {renderItem(props)({ route, index })}
        </TouchableWithoutFeedback>
      ))}
    </View>
  )

  return (
    <TabViewRN
      navigationState={{ index: tabIndex, routes }}
      renderScene={renderScene}
      onIndexChange={setTabIndex}
      initialLayout={{ width: layout.width }}
      renderTabBar={renderTabBar}
      lazy
    />
  )
}
