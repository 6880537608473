import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'
import { GameContainer } from '#components/widgets/Games/GameContainer'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import type { LanguageType } from '#types/language'

import { OverView } from './OverView'
import { ReviewLevelTest } from './ReviewLevelTest'
import { useRandomLevelTest } from './useRandomLevelTest'

export const LevelTest = observer(({}: AppStackScreenProps<'LevelTest'>) => {
  const languageLearn =
    (S.shared.currentUser?.languageLearn as LanguageType) ?? 'English'

  const updateLevelUser = (level: string) => {
    if (S.shared.currentUser) {
      const user = S.shared.currentUser
      S.shared.currentUser = {
        ...user,
        level: { isShow: level !== 'A1', current: level },
      }
    }
  }
  const {
    answers,
    currentIndex,
    question,
    loading,
    totalCounts,
    onChangeData,
    isPopup,
    handleCloseReview,
    typeReview,
    item,
    nextItem,
  } = useRandomLevelTest({ language: languageLearn, updateLevelUser })

  useEffect(() => {
    if (currentIndex === 0) {
      S.shared.setPointLevelTest(0)
    }
  }, [currentIndex])

  const onChangeAnswer = async (isTrue: boolean) => {
    if (isTrue) {
      S.shared.setPointLevelTest(S.shared.pointLevelTest + 1)
    }
  }

  const renderContent = () => {
    switch (isPopup) {
      case 'OverView':
        return <OverView handleStartTest={handleCloseReview} />

      case 'Review':
        return (
          <ReviewLevelTest
            handleClose={handleCloseReview}
            type={typeReview}
            level={S.shared.currentUser?.level.current}
          />
        )

      default:
        return (
          <GameContainer
            isShowPopupClose
            currentIndex={currentIndex}
            total={totalCounts}
            question={question}
            answers={answers}
            loading={loading}
            onChangeIndex={() => onChangeData(S.shared.pointLevelTest)}
            onChangeAnswer={onChangeAnswer}
            type={item?.type === 'listen' ? 'Listen' : 'Test'}
            nextType={nextItem?.type === 'listen' ? 'Listen' : 'Test'}
            topic={item?.topic}
          />
        )
    }
  }

  return <View style={tw`flex-1`}>{renderContent()}</View>
})
