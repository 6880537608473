import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type ModalProps = {
  title: string
  content?: string
  onConfirm?: (modalId?: string) => void
  closeModal: (modalId?: string) => void
  children?: ReactNode
  disableCancel?: boolean
  disableOk?: boolean
  childrenStyle?: React.CSSProperties
  showOnlyCloseButton?: boolean
  autoClose?: boolean
  modalId?: string
  isLoading?: boolean
}

export const ModalLayout = ({
  title,
  content,
  onConfirm,
  closeModal,
  children,
  disableCancel,
  disableOk,
  childrenStyle,
  showOnlyCloseButton,
  autoClose = true,
  modalId,
  isLoading,
}: ModalProps) => {
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    if (onConfirm) {
      setLoading(true)
      try {
        await onConfirm(modalId)
        autoClose && closeModal(modalId)
      } finally {
        setLoading(false)
      }
    } else {
      autoClose && closeModal(modalId)
    }
  }

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !showOnlyCloseButton && !disableOk) {
        handleConfirm()
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [showOnlyCloseButton, onConfirm])

  return (
    <Flex
      style={tw.style(
        'bg-white p-6 rounded-lg shadow-lg min-w-[25%] w-auto max-w-[50%] h-auto max-h-[80%] flex-col relative',
      )}
    >
      <Text specialType='Title'>{title}</Text>
      <div style={tw.style({ overflowY: 'scroll' })}>
        {content && <Text specialType='paragraph1'>{content}</Text>}
        {children && (
          <Flex
            style={{ ...tw`mt-4 w-full flex-1 flex-col`, ...childrenStyle }}
          >
            {children}
          </Flex>
        )}
      </div>

      {showOnlyCloseButton ? (
        <Button
          style={tw`absolute top-2 right-2 border-none bg-transparent text-xl`}
          icon={<CloseOutlined />}
          onClick={() => closeModal(modalId)}
        />
      ) : (
        <Flex style={tw`flex-row justify-end mt-4`}>
          <Button
            style={tw`mx-2 w-20`}
            onClick={() => closeModal(modalId)}
            disabled={disableCancel || isLoading || loading}
          >
            Cancel
          </Button>
          <Button
            style={tw`mx-2 w-20`}
            type='primary'
            onClick={handleConfirm}
            loading={isLoading || loading}
            disabled={disableOk ?? false}
          >
            OK
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
