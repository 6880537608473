import { gql } from '#graphql/urql'
import type { WordOrPhraseItem } from '#types/word'

export const fetchDataWordOrPhrase = async (
  topicId: string,
  isPhrase: boolean,
): Promise<WordOrPhraseItem[]> => {
  const respWords = await gql.searchWord(
    {
      filter: { topicId, isVocabulary: !isPhrase },
    },
    { requestPolicy: 'network-only' },
  )

  const wordsData = respWords.data?.searchWord || []
  if (!wordsData.length) {
    return []
  }

  const wordsId = wordsData.map(w => w.id)

  const resourceInWord = await gql.searchResourceInWord(
    {
      filter: { wordId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const resource = resourceInWord.data?.searchResourceInWord || []

  const respTranslate = await gql.searchTranslate(
    {
      filter: { wQAId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const translate = respTranslate.data?.searchTranslate || []

  const wordOrPhrase: WordOrPhraseItem[] = wordsData.map(item => {
    const mediaItems = resource.filter(media => media.wordId === item.id)
    return {
      ...item,
      media: mediaItems.length > 0 ? mediaItems : [],
      translation: translate.filter(i => i.wQAId === item.id),
    }
  })

  return wordOrPhrase
}
