import { observer } from 'mobx-react-lite'
import type React from 'react'
import { Platform, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { IconSax } from '#components/base/IconSax'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'

type Pr = {
  closeActionsheet: () => void
  onDeleteAvatar?: () => void
  onUploadAvatar?: () => void
}

export const ActionSheetImage: React.FC<Pr> = observer(
  ({ closeActionsheet, onDeleteAvatar, onUploadAvatar }) => (
    <ActionSheet isLoading={false}>
      <View style={tw.style('flex-1 w-full px-2 pt-6 gap-4 pb-4')}>
        {Platform.OS !== 'web' && (
          <TouchableOpacity
            style={tw.style('flex-row items-center gap-6 w-full h-12')}
          >
            <IconSax name='Camera' size={24} />
            <Text specialType='paragraph1' style={tw.style('flex-wrap')}>
              Take a picture
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={() => {
            closeActionsheet()
            onUploadAvatar?.()
          }}
          style={tw.style('flex-row items-center gap-6 w-full h-12')}
        >
          <IconSax name='Gallery' size={24} />
          <Text specialType='paragraph1' style={tw.style(' flex-wrap')}>
            Take from gallery
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            closeActionsheet()
            onDeleteAvatar?.()
          }}
          style={tw.style('flex-row items-center gap-6 w-full h-12')}
        >
          <IconSax name='Trash' size={24} />
          <Text specialType='paragraph1' style={tw.style(' flex-wrap')}>
            Delete
          </Text>
        </TouchableOpacity>
      </View>
    </ActionSheet>
  ),
)
