import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { LanguageType, Level } from '#types/language'
import type { SearchTopicItem } from '#types/topic'

import { ItemTopic } from './ItemTopic'
import { getFilter } from './utils'

export const TopicsRoute = observer(() => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize] = useState(6)
  const [topics, setTopics] = useState<
    Array<SearchTopicItem & { levelLabel?: boolean }>
  >([])
  const [total, setTotal] = useState<number>(0)
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false)
  const [lastLevelFromPreviousPage, setLastLevelFromPreviousPage] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (S.shared.currentUser?.id && (fetching || loading || isLoadMore)) {
      fetchData()
    }
  }, [
    S.shared.currentUser?.id,
    fetching,
    loading,
    isLoadMore,
    S.shared.currentUser?.level.current,
  ])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
    }
  }, [S.shared.currentUser?.level.current])

  const fetchData = async () => {
    try {
      const response = await gql.searchTopics(
        {
          filter: {
            AND: [
              ...getFilter(
                (S.shared.currentUser?.level.current as Level) ?? 'A1',
                (S.shared.currentUser?.languageLearn as LanguageType) ??
                  'English',
              ),
              {
                OR: [
                  {
                    topicInUser_some: {
                      percent_lt: 100,
                      userLearnId: S.shared.currentUser?.id,
                    },
                  },
                  {
                    topicInUser_none: {
                      userLearnId: S.shared.currentUser?.id,
                    },
                  },
                ],
              },
            ],
          },
          order: ['level_asc', 'ordinalNumber_asc'],
          page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
        },
        { requestPolicy: 'network-only' },
      )
      const newData: SearchTopicItem[] = response.data?.searchTopics ?? []
      const processedData = processTopicsWithLabels(
        newData,
        lastLevelFromPreviousPage,
      )
      setTotal(response.data?.count ?? 0)
      setTopics(prevTopics =>
        currentPage < 2 ? processedData : [...prevTopics, ...processedData],
      )
      setLastLevelFromPreviousPage(newData[newData.length - 1]?.level)
    } finally {
      setLoading(false)
      setIsLoadMore(false)
    }
  }

  const onRefresh = () => {
    if (!fetching) {
      setCurrentPage(1)
      setFetching(true)
    }
  }

  const onEndReached = () => {
    const totalLoadedItems = currentPage * pageSize
    if (totalLoadedItems < total) {
      setCurrentPage(currentPage + 1)
      setIsLoadMore(true)
    }
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: SearchTopicItem & { levelLabel?: boolean }
    index: number
  }) => (
    <ItemTopic
      item={item}
      index={index}
      label={item.levelLabel ? getLevelDescription(item.level).label : ''}
      color={getLevelDescription(item.level).color}
    />
  )

  const renderItemSeparator = () => <View style={tw`h-3`} />

  return (
    <FlatList
      data={topics}
      renderItem={renderItem}
      keyExtractor={item => (item.levelLabel ? `label-${item.level}` : item.id)}
      contentContainerStyle={tw`py-4`}
      ItemSeparatorComponent={renderItemSeparator}
      refreshing={fetching}
      onRefresh={onRefresh}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.5}
    />
  )
})

const processTopicsWithLabels = (
  t: SearchTopicItem[],
  lastLevelFromPrevPage?: string,
) => {
  const topicsWithLabels: Array<SearchTopicItem & { levelLabel?: boolean }> = []
  let lastLevel: string | undefined = lastLevelFromPrevPage

  t.forEach((topic, index) => {
    if (topic.level !== lastLevel) {
      topicsWithLabels.push({
        ...topic,
        levelLabel:
          index === 0 && topic.level === lastLevelFromPrevPage ? false : true,
      })
      lastLevel = topic.level
    } else {
      topicsWithLabels.push({
        ...topic,
        levelLabel: false,
      })
    }
  })

  return topicsWithLabels
}

const getLevelDescription = (level: string) => {
  switch (level) {
    case 'A1':
      return { label: 'A1 - Beginner', color: 'green' }
    case 'A2':
      return { label: 'A2 - Elementary', color: 'green' }
    case 'B1':
      return { label: 'B1 - Intermediate', color: 'purple' }
    case 'B2':
      return { label: 'B2 - UpperIntermediate', color: 'purple' }
    default:
      return { label: level, color: 'gray' }
  }
}
