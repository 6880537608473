import type React from 'react'
import { useWindowDimensions, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { SearchAiTutorItem } from '#types/aiTutor'

import type { TaskDataItem } from '../data'
import { ListTask } from './ListTask'

type ITasksProps = {
  closeActionsheet: () => void
  data: TaskDataItem
  check: string[]
  aiTutor: SearchAiTutorItem | null
}

export const Tasks: React.FC<ITasksProps> = ({
  closeActionsheet,
  data,
  check,
}) => {
  const { height } = useWindowDimensions()

  return (
    <View
      style={tw.style(
        'bg-background-light-1 dark:bg-background-dark-1 gap-6 px-2 py-3 w-full',
      )}
    >
      <View style={tw`flex-col items-center justify-center w-full gap-2`}>
        <Text specialType='Headline2' textAlign='center'>
          Task List
        </Text>
        {/* <Text
          specialType='Subtitle'
          textAlign='center'
          color={tw.color('text-2')}
        >
          Complete these tasks to proceed to the next topic.
        </Text> */}
      </View>
      <View
        style={tw.style('mb-1 shadow-lg bg-neutral-75 rounded-2xl px-4 py-3', {
          height: height * 0.4,
          shadowColor: 'rgba(31, 176, 230, 0.15)',
        })}
      >
        <ListTask data={data.task} check={check} />
      </View>
      <Button onPress={closeActionsheet}>Got it</Button>
    </View>
  )
}
