import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import { Button, Flex, Slider, Typography } from 'antd'
import { Modal } from 'native-base'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import type { Crop, PixelCrop } from 'react-image-crop'
import ReactCrop from 'react-image-crop'

import { uploadFileWithUrl } from '#apis/files'
import { tw } from '#components/utils/tw'
import type { ResponseFile, UploadFileProps } from '#types/media'

import { toastError } from '../utils/Toast'
import { canvasPreview } from './canvasPreview'

export const centerAspectCrop = (
  cropWidth: number,
  cropHeight: number,
  width: number,
  height: number,
) =>
  ({
    x: (width - cropWidth) / 2,
    y: (height - cropHeight) / 2,
    unit: 'px',
    width: cropWidth,
    height: cropHeight,
  }) as Crop
export const CropImage = ({
  isOpen,
  handleCancel,
  setImageCrop,
  fileUpload,
}: {
  isOpen: boolean
  handleCancel: () => void
  setImageCrop: (value: ResponseFile) => void
  fileUpload: UploadFileProps
}) => {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<UploadFileProps>()
  const [scale, setScale] = useState(1)
  const [isMobile, setIsMobile] = useState(false)

  const myDiv = document.getElementById('crop-image')

  const WIDTH = isMobile ? 100 : 200
  const HEIGHT = isMobile ? 100 : 200

  const [crop, setCrop] = useState<Crop>(
    centerAspectCrop(
      WIDTH,
      HEIGHT,
      myDiv?.clientWidth ?? 0,
      myDiv?.clientHeight ?? 0,
    ),
  )

  useEffect(() => {
    const userAgent = navigator.userAgent
    const checkIsMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent,
      )
    setIsMobile(checkIsMobile)
  }, [])

  const onSelectFile = (e: UploadFileProps) => {
    if (e.originFileObj) {
      const cropCenter = centerAspectCrop(
        WIDTH,
        HEIGHT,
        myDiv?.clientWidth ?? 0,
        myDiv?.clientHeight ?? 0,
      )
      setCrop(cropCenter)
      setCompletedCrop({
        unit: 'px',
        width: WIDTH,
        height: HEIGHT,
        x: cropCenter.x,
        y: cropCenter.y,
      })
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.originFileObj)
      setFile(e.originFileObj)
      setScale(1)
    }
  }

  useEffect(() => {
    if (fileUpload) {
      onSelectFile(fileUpload)
    }
  }, [fileUpload])

  const onCancel = () => {
    handleCancel()
    setImgSrc('')
    const cropCenter = centerAspectCrop(
      WIDTH,
      HEIGHT,
      myDiv?.clientWidth ?? 0,
      myDiv?.clientHeight ?? 0,
    )
    setCrop(cropCenter)
    setCompletedCrop({
      unit: 'px',
      width: WIDTH,
      height: HEIGHT,
      x: cropCenter.x,
      y: cropCenter.y,
    })
    setFile(undefined)
    setScale(1)
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const cropCenter = centerAspectCrop(
      WIDTH,
      HEIGHT,
      myDiv?.clientWidth ?? 0,
      myDiv?.clientHeight ?? 0,
    )
    setCrop(cropCenter)
    setCompletedCrop({
      unit: 'px',
      width: WIDTH,
      height: HEIGHT,
      x: cropCenter.x,
      y: cropCenter.y,
    })
    setScale(1)
  }

  const handleOk = async () => {
    setLoading(true)
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    const newFile = new File([blob], file?.name ?? 'Image')
    const data = new FormData()
    data.append('file', newFile)
    if (data) {
      const res = await uploadFileWithUrl(data, 'image_game')
      setImageCrop(res)
      onCancel()
    } else {
      toastError({
        title: 'Error',
        message: 'Upload image failed!',
      })
    }
    setLoading(false)
    setScale(1)
  }

  const useDebounceImg = async () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        0,
      )
    }
  }

  useEffect(() => {
    const t = setTimeout(() => {
      useDebounceImg.apply(undefined, [])
    }, 300)

    return () => {
      clearTimeout(t)
    }
  }, [completedCrop, scale])

  const onChange = (value: number) => {
    setScale(value)
  }

  useEffect(() => {
    const cropCenter = centerAspectCrop(
      WIDTH,
      HEIGHT,
      myDiv?.clientWidth ?? 0,
      myDiv?.clientHeight ?? 0,
    )
    setCrop(cropCenter)
    setCompletedCrop({
      unit: 'px',
      width: WIDTH,
      height: HEIGHT,
      x: cropCenter.x,
      y: cropCenter.y,
    })
  }, [myDiv?.clientHeight, myDiv?.clientWidth])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      style={tw.style(
        isMobile
          ? 'relative flex p-6 bg-white w-[80%] h-[60%] top-[15%] left-[10%] rounded-lg'
          : 'relative flex p-6 bg-white w-[40%] h-[90%] top-[5%] left-[30%] rounded-lg',
        { gap: 12 },
      )}
    >
      <Typography style={tw`font-[600] text-[20px] mt-4`}>
        Crop image
      </Typography>
      <div style={tw`flex flex-col h-full w-full items-center gap-4`}>
        <div style={tw.style('w-[80%] max-h-[70%]', { overflow: 'scroll' })}>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={cropData =>
                setCrop({
                  height: HEIGHT,
                  width: WIDTH,
                  unit: 'px',
                  x: cropData.x,
                  y: cropData.y,
                })
              }
              circularCrop
              onComplete={c => setCompletedCrop(c)}
              aspect={1 / 1}
              minWidth={WIDTH}
              minHeight={HEIGHT}
              style={tw.style('z-1 w-full', { overflow: 'hidden' })}
            >
              <img
                ref={imgRef}
                style={{
                  transform: `scale(${scale})`,
                  width: '100%',
                }}
                alt='Crop me'
                src={imgSrc}
                onLoad={onImageLoad}
                id='crop-image'
              />
            </ReactCrop>
          )}
        </div>

        <div style={tw`w-[80%] flex justify-center z-1`}>
          <MinusCircleFilled
            style={{ cursor: 'pointer', color: '#66CCFF' }}
            onClick={() => setScale(scale - 0.1)}
          />
          <Slider
            value={scale}
            onChange={onChange}
            min={0.1}
            max={3.0}
            step={0.1}
            style={{ width: '100%' }}
          />
          <PlusCircleFilled
            style={{ cursor: 'pointer', color: '#66CCFF' }}
            onClick={() => setScale(scale + 0.1)}
          />
        </div>

        {!!completedCrop && (
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
                display: 'none',
              }}
            />
          </div>
        )}
      </div>

      <Flex
        style={tw`absolute bottom-[2%] right-[2%] flex items-end justify-end h-full`}
      >
        <Button
          style={tw`mx-2 w-20`}
          onClick={() => {
            onCancel()
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          style={tw`mx-2 w-20`}
          type='primary'
          onClick={handleOk}
          loading={loading}
          disabled={loading}
        >
          OK
        </Button>
      </Flex>
    </Modal>
  )
}
