import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'

import { toastError, toastSuccess } from '#components/utils/Toast'
import { gql } from '#graphql/urql'
import { onAppInit } from '#screens/App/onAppInit'

export const onLoginFacebook = async () => {
  const provider = new FacebookAuthProvider()
  const auth = getAuth()
  try {
    const resFacebook = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(resFacebook)
    const token = credential?.accessToken ?? ''
    if (resFacebook && token) {
      const r = await gql.loginFacebook({
        data: {
          email: resFacebook.user.email ?? '',
          name: resFacebook.user.displayName ?? '',
          accessToken: token,
          // photoURL: resFacebook.user.photoURL ?? '',
        },
      })
      if (r.error || !r.data || r.data.loginFacebook.user?.deactivate) {
        toastError({
          message: r.data?.loginFacebook.user?.deactivate
            ? 'Your account has been locked'
            : r.error?.message,
        })
        return
      }
      await onAppInit({
        currentAuthSession: r.data.loginFacebook,
      })
      toastSuccess({ message: 'Log in success' })
    } else {
      toastError({ message: 'Login Facebook failed' })
    }
  } catch (error) {
    toastError({ message: `Login failed: ${error}` })
  }
}
