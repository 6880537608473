import { observer } from 'mobx-react-lite'
import type React from 'react'
import { TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { toastError } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import { CircularProgress } from '#components/widgets/CircularProgress'
import { useCountWord, useCountWordInUser } from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import { S } from '#store'
import { PercentageTopic } from '#types/games'

type Pr = {
  closeActionsheet: () => void
  topicId: string
}

export const ActionSheetTopics: React.FC<Pr> = observer(
  ({ closeActionsheet, topicId }) => {
    const [countWord] = useCountWord({
      variables: {
        filter: { topicId },
      },
      requestPolicy: 'network-only',
    })

    const [countWordInUser] = useCountWordInUser({
      variables: {
        filter: { topicId, userId: S.shared.currentUser?.id, id_notIn: [''] },
      },
      requestPolicy: 'network-only',
    })
    const count = countWordInUser.data?.countWordInUser || 0
    const countW = countWord.data?.countWord || 0
    const navigateScreen = () => {
      const { percentGame, countVocabularyUnKnown, countVocabulary } =
        S.shared.resultPercentageTopic
      if (!countW) {
        toastError({ message: 'Error no data' })
      } else if (!count || countW > count) {
        navigate('App', { screen: 'KnownOrUnknown', params: { id: topicId } })
      } else if (percentGame < PercentageTopic.Game) {
        const isPhrase = countVocabularyUnKnown === countVocabulary
        navigate('App', { screen: 'Games', params: { topicId, isPhrase } })
      } else {
        navigate('App', { screen: 'ListenGame', params: { topicId } })
      }
    }

    const handleLearnNewWord = () => {
      navigateScreen()
      closeActionsheet()
    }

    const handleSpeakWithAi = () => {
      closeActionsheet()
      navigate('App', { screen: 'ScenarioSpeak', params: { topicId } })
    }

    return (
      <ActionSheet
        isLoading={
          countWord.fetching || S.shared.resultPercentageTopic.fetching
        }
        header='Pick what excites you!'
        headerStyle={tw`pt-4`}
        specialType='Headline2'
      >
        <View style={tw.style('flex-1 w-full px-12')}>
          <TouchableOpacity
            style={tw`w-full justify-center rounded-5 align-center py-4 px-5 bg-background-light-white`}
            onPress={handleLearnNewWord}
          >
            <View
              style={tw`flex-1 rounded-full justify-center items-center self-center mb-3 `}
            >
              <CircularProgress
                progressPercent={
                  S.shared.resultPercentageTopic.percentGame +
                  S.shared.resultPercentageTopic.percentListening
                }
                // progressPercent={100}
                size={80}
                bgColor={tw.color('background-light-2')}
                pgColor={tw.color('primary-400')}
                backgroundColor={tw.color('background-light-2')}
              >
                <SystemIcon
                  type='SAX'
                  name='Teacher'
                  variant='Bold'
                  size={40}
                  color={tw.color('primary-400')}
                />
              </CircularProgress>
            </View>
            <View style={tw.style('self-center')}>
              <Text specialType='Title' color={tw.color('primary-400')}>
                Learn lessons
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={tw`w-full justify-center mt-6 mb-6 rounded-5 align-center py-4 px-5 bg-background-light-white`}
            onPress={handleSpeakWithAi}
          >
            <View
              style={tw`flex-1 rounded-full justify-center items-center self-center mb-3 `}
            >
              <CircularProgress
                progressPercent={S.shared.resultPercentageTopic.percentSpeaking}
                size={80}
                bgColor={tw.color('secondary1-50')}
                pgColor={tw.color('secondary1-500')}
                backgroundColor={tw.color('secondary1-50')}
              >
                <SystemIcon
                  type='SAX'
                  name='Microphone2'
                  variant='Bold'
                  size={40}
                  color={tw.color('secondary1-400')}
                />
              </CircularProgress>
            </View>
            <View style={tw.style('self-center')}>
              <Text specialType='Title' color={tw.color('secondary1-500')}>
                Role play
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ActionSheet>
    )
  },
)
