import type { FC, ReactNode } from 'react'
import type { ViewStyle } from 'react-native'
import { View } from 'react-native'

import { Loading } from '#components/base/Loading'
import { ScrollView } from '#components/base/ScrollView'
import type { SpecialType } from '#components/base/Text'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type ActionSheetProps = {
  isLoading?: boolean
  header?: string
  containerStyle?: ViewStyle
  children: ReactNode
  headerStyle?: ViewStyle
  specialType?: SpecialType
  renderFooter?: () => ReactNode
}

export const ActionSheet: FC<ActionSheetProps> = ({
  isLoading,
  header,
  containerStyle,
  children,
  headerStyle,
  specialType,
  renderFooter,
}) => {
  if (isLoading) {
    return <Loading isLoading />
  }

  return (
    <View
      style={[tw`flex-1 w-full justify-center items-center`, containerStyle]}
    >
      {header && (
        <View style={[tw`w-full px-9 py-6`, headerStyle]}>
          <Text
            textAlign='center'
            specialType={specialType || 'Headline4'}
            style={tw.style('text-center', { lineHeight: '125%' })}
          >
            {header}
          </Text>
        </View>
      )}
      <ScrollView style={tw.style('w-full')}>{children}</ScrollView>
      {renderFooter && <View style={tw`w-full`}>{renderFooter()}</View>}
    </View>
  )
}
