import { EditOutlined } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { Text } from 'native-base'
import { useState } from 'react'

import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useSearchProduct } from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import type { AdminScreenProps } from '#navigator/types'
import type { SearchProductItems } from '#types/product'

import { PaymentModal } from './PaymentModal'

export type PaymentTabs = 'product' | 'introduce_plan'

export const Payment = ({
  navigation,
  route,
}: AdminScreenProps<'AdminPayment'>) => {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const { openModal } = useOverlay()

  const dateType: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const [products, refetchProduct] = useSearchProduct({
    variables: {
      filter: searchKeyword ? { name_iLike: `%${searchKeyword}%` } : {},
      order: ['name_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const searchPayment = products.data?.searchProduct ?? []
  const countPayment = products.data?.count ?? 0

  const onModalOk = () => {
    refetchProduct({ requestPolicy: 'network-only' })
  }

  const handleEditData = (product: SearchProductItems) => {
    openModal(PaymentModal, {
      onOk: onModalOk,
      isUpdate: false,
      containerProps: {
        closeOnOverlayClick: false,
      },
      selectedProduct: product,
    })
  }

  const handleAddData = () => {
    openModal(PaymentModal, {
      onOk: onModalOk,
      isUpdate: false,
      containerProps: {
        closeOnOverlayClick: false,
      },
      languageTopic: '',
    })
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (_, record) => (
      <Flex gap={4} style={tw`flex justify-center`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            handleEditData(record)
          }}
          className='p-0 bg-transparent'
        />
      </Flex>
    ),
  }

  const columns: ColumnType<SearchProductItems>[] = [
    {
      title: 'Product name',
      dataIndex: 'name',
      key: 'text',
      render: (_, record) => <Typography>{record.name}</Typography>,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'text',
      render: (_, record) => (
        <Typography>{record.active ? 'Active' : 'Pending'}</Typography>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'text',
      render: (_, record) => (
        <Typography>
          {record.price?.unitAmount + ' ' + record.price?.currency}
        </Typography>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'text',
      render: (_, record) => <Typography>{record.description}</Typography>,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <Typography style={tw`cursor-pointer`}>
          {record.createdAt.toLocaleString('en-US', dateType)?.slice(0, 10)}
        </Typography>
      ),
    },
    actionsColumn,
  ]

  const onRowClick = (record: SearchProductItems) => {
    navigate('Admin', {
      screen: 'AdminPaymentDetail',
      params: { id: record.id },
    })
  }

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
      <Text style={tw`py-6 text-6`}>Payment</Text>
      <Flex style={tw`flex-col`}>
        <SearchButton
          title='Add'
          handleAddData={handleAddData}
          keyword={(k: string) => {
            setSearchKeyword(k)
            setCurrentPage(1)
          }}
        />

        <PaginatedTable<SearchProductItems>
          dataSource={searchPayment}
          columns={columns}
          loading={products.fetching}
          total={countPayment ? countPayment : 0}
          currentPage={currentPage}
          onRowClick={onRowClick}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          scrollHeight={'60vh'}
        />
      </Flex>
    </Flex>
  )
}
