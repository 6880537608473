import { Flex, Select } from 'antd'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import type { Language, LevelType } from '#graphql/codegen'
import { useSearchTopic } from '#graphql/codegen'
import { gql } from '#graphql/urql'

type Prop = {
  closeModal: () => void
}

export const RearrangeTopic: FC<Prop> = ({ closeModal }) => {
  const [language, setLanguage] = useState<{ label: string; value: string }>({
    label: 'English',
    value: 'English',
  })
  const [level, setLevel] = useState<{ label: string; value: string }>({
    label: 'A1',
    value: 'A1',
  })
  const languageOptions = [
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
  ]
  const levelOptions = [
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' },
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
  ]

  const [topic, refetchTopic] = useSearchTopic({
    variables: {
      filter: {
        AND: [{ level: level.value }, { languageTopic: language.value }],
      },
      order: ['ordinalNumber_asc'],
      page: {},
    },
  })

  const reload = () => {
    refetchTopic({ requestPolicy: 'network-only' })
  }

  useEffect(() => {
    reload()
  }, [level, language])

  const searchTopic = topic.data?.searchTopic ?? []

  const [characters, setCharacters] = useState(searchTopic)

  useEffect(() => {
    setCharacters(searchTopic)
  }, [searchTopic])

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }
    const items = Array.from(characters)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setCharacters(items)
  }

  const checkChangePosition = () =>
    JSON.stringify(searchTopic) === JSON.stringify(characters)

  const handleUpdatePosition = () => {
    searchTopic.map(async (e, index) => {
      if (e.id !== characters[index].id) {
        await gql.movePosition({
          index,
          data: {
            id: characters[index].id,
            level: level.value as LevelType,
            languageTopic: language.value as Language,
          },
        })
      }
    })
    reload()
  }

  const handleModalCancel = () => {
    setCharacters(searchTopic)
    closeModal()
  }

  const handleModalOk = () => {
    handleUpdatePosition()
    closeModal()
  }

  return (
    <ModalLayout
      title='Rearrange topic'
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={topic.fetching || checkChangePosition()}
      autoClose={false}
    >
      <Flex style={tw`flex-1 flex-col w-full h-full`}>
        <Flex gap={8} style={tw`w-full my-4`}>
          <Select
            value={language}
            options={languageOptions}
            labelInValue
            onChange={e =>
              setLanguage({
                label: e.label,
                value: e.value,
              })
            }
            style={tw`w-full`}
          />
          <Select
            value={level}
            options={levelOptions}
            labelInValue
            onChange={e =>
              setLevel({
                label: e.label,
                value: e.value,
              })
            }
            style={tw`w-full`}
          />
        </Flex>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='characters'>
            {provided1 => (
              <div
                className='characters'
                {...provided1.droppableProps}
                ref={provided1.innerRef}
                // style={tw.style({ overflowY: 'scroll' })}
              >
                {characters.map((e, index) => (
                  <Draggable key={e.id} draggableId={e.id} index={index}>
                    {provided2 => (
                      <div
                        {...provided2.draggableProps}
                        {...provided2.dragHandleProps}
                        ref={provided2.innerRef}
                      >
                        <div
                          style={tw`flex flex-col w-[50%] m-4 p-2 bg-gray-300 items-center justify-center rounded-lg gap-4 ml-auto mr-auto`}
                        >
                          <div>
                            <img
                              src={
                                e.media?.url ??
                                'https://img.freepik.com/free-vector/loading-circles-blue-gradient_78370-2646.jpg'
                              }
                              alt='topic_image'
                              style={tw`w-[64px]`}
                            />
                          </div>
                          <div style={tw`font-semibold`}>{e.name}</div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided1.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </ModalLayout>
  )
}
