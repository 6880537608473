import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import type { TextProps as RnTextProps, TextStyle } from 'react-native'
import { StyleSheet } from 'react-native'
import { Text as RnText } from 'react-native-cross-platform-text'
import type { Style } from 'twrnc'

import { useDarkColor } from '#components/dark/hooks'
import { tw } from '#components/utils/tw'

export type TextProps = Omit<RnTextProps, 'style'> & {
  style?: Style
  textAlign?: TextStyle['textAlign']
  color?: TextStyle['color']
}

type TextStyleProviderProps = PropsWithChildren<{
  value: TextProps['style']
}>

const TextStyleContext = createContext<TextProps['style']>(undefined)

export const TextStyleProvider = ({
  value,
  children,
}: TextStyleProviderProps) => (
  <TextStyleContext.Provider value={value}>
    {children}
  </TextStyleContext.Provider>
)

// const fontWeight = {
//   normal: 400, // normal
//   500: 500, // medium
//   600: 600, // semibold
//   bold: 700, // bold
//   800: 800, // extrabold
//   900: 900, // black
// } as const

export type SpecialType =
  | 'Headline1'
  | 'Headline2'
  | 'Headline3'
  | 'Headline4'
  | 'Title'
  | 'Subtitle'
  | 'paragraph1'
  | 'paragraph2'
  | 'Note'
  | 'Note2'
  | 'Button'
  | 'smalltext'

const BASE_STYLE: { [key in SpecialType]: TextStyle } = {
  Headline1: {
    ...tw`text-left`,
    fontFamily: 'Nunito',
    fontSize: 32,
    fontWeight: '600',
    lineHeight: 40,
  },
  Headline2: {
    ...tw`text-left`,
    fontFamily: 'Nunito',
    fontSize: 26,
    fontWeight: '600',
    lineHeight: 32.5,
  },
  Headline3: {
    ...tw`text-left`,
    fontFamily: 'Nunito',
    fontSize: 24,
    fontWeight: '600',
    lineHeight: 30,
  },
  Headline4: {
    ...tw`text-left`,
    fontFamily: 'Nunito',
    fontSize: 20,
    fontWeight: '600',
    lineHeight: 25,
  },
  Title: {
    ...tw`text-left`,
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 22.5,
  },
  Subtitle: {
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 20,
  },
  paragraph1: {
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 22.5,
  },
  paragraph2: {
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 20,
  },
  Note: {
    fontFamily: 'Nunito',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 17.5,
  },
  Note2: {
    fontFamily: 'Nunito',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 17.5,
  },
  Button: {
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 22.5,
  },
  smalltext: {
    fontFamily: 'Nunito',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 15,
  },
}

export const Text = ({
  style,
  children,
  specialType,
  textAlign = 'auto',
  color,
  ...p
}: TextProps & { specialType?: SpecialType }) => {
  const dark = useDarkColor()
  const ctx = useContext(TextStyleContext)

  const stylesFromCtx = StyleSheet.flatten(ctx || {}) as Style
  const styles = StyleSheet.flatten(style) as Style

  // const weight =
  //   fontWeight[
  //     (styles?.fontWeight ||
  //       stylesFromCtx?.fontWeight ||
  //       'normal') as keyof typeof fontWeight
  //   ]

  const baseStyle = specialType ? BASE_STYLE[specialType] : {}

  return (
    <RnText
      style={tw.style(
        `text-sm leading-snug text-[${dark('body-dark', 'text-text-1')}]`,
        stylesFromCtx,
        baseStyle,
        styles,
        // { fontWeight: weight, textAlign },
        textAlign ? { textAlign } : {},
        color ? { color } : {},
      )}
      {...p}
    >
      {children}
    </RnText>
  )
}
