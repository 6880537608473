import type { FC } from 'react'
import { View } from 'react-native'

import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import type { AppStackScreenProps } from '#navigator/types'

import { TabView } from './TabView'

type Props = AppStackScreenProps<'SuggestedSpeak'>

export const SuggestedSpeak: FC<Props> = ({ route }) => {
  const { topicId } = route.params

  return (
    <View style={tw`flex-1 flex-col bg-background-light-1`}>
      <Header title='Suggested' />
      <TabView topicId={topicId} />
    </View>
  )
}
