import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { GameContainer } from '#components/widgets/Games/GameContainer'
import { useSearchTopicInUser, useSearchWordInUser } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'

import { useRandomListen } from './useRandomListen'

export const ListenGame = observer(
  ({ route }: AppStackScreenProps<'ListenGame'>) => {
    const { topicId, isCompleted } = route.params

    const [wordInUser, setWordInUser] = useState<string>('')

    const { answers, currentIndex, onChangeData, question, total, loading } =
      useRandomListen(topicId, isCompleted)

    const [d] = useSearchWordInUser({
      variables: {
        filter: {
          topicId,
          userId: S.shared.currentUser?.id,
          wordId: '',
        },
      },
    })

    const [u] = useSearchTopicInUser({
      variables: {
        filter: {
          topicId,
          userLearnId: S.shared.currentUser?.id,
        },
      },
      requestPolicy: 'network-only',
    })

    useEffect(() => {
      if (!isCompleted) {
        if (
          (!d.data?.searchWordInUser.length || !d.data?.searchWordInUser) &&
          !d.fetching
        ) {
          initCreateWordInUser()
        } else {
          setWordInUser(d.data?.searchWordInUser[0].id ?? '')
        }
      }
    }, [d.data, d.fetching, topicId])

    useEffect(() => {
      if (!isCompleted) {
        S.shared.setPoint(0)
      }
    }, [])

    const initCreateWordInUser = async () => {
      const resp = await gql.createWordInUser({
        data: {
          topicId,
          userId: S.shared.currentUser?.id,
          times: 0,
          wordId: '',
        },
      })
      setWordInUser(resp.data?.createWordInUser.id ?? '')
    }

    const onChangeAnswer = async (isTrue: boolean) => {
      if (isTrue && wordInUser && !isCompleted) {
        S.shared.setPoint(S.shared.point + 1)
        if (u.data?.searchTopicInUser[0].percent !== 100) {
          gql.updateWordInUser({
            id: wordInUser,
            data: {
              topicId,
              userId: S.shared.currentUser?.id,
              percentListen: S.shared.point,
            },
          })
        }
      }
    }

    return (
      <GameContainer
        currentIndex={currentIndex}
        total={total}
        question={question}
        answers={answers}
        loading={loading}
        onChangeIndex={onChangeData}
        onChangeAnswer={onChangeAnswer}
        type='Listen'
        nextType='Listen'
      />
    )
  },
)
