import type { FC } from 'react'
import { View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { WordOrPhraseItem } from '#types/word'

type Props = {
  item: WordOrPhraseItem
  onPress: (item: WordOrPhraseItem) => void
}

export const Item: FC<Props> = ({ item, onPress }) => (
  <View style={tw`flex-row items-center`}>
    <View style={tw`flex-1 py-2 mr-4 gap-1`}>
      <Text specialType='Title'>{item.vocabAndPhrase}</Text>
      <Text specialType='Note'>
        {item.translation.length > 0 ? item.translation[0].text : ''}
      </Text>
    </View>
    <SystemIcon
      type='SAX'
      name='VolumeHigh'
      //   variant='Bold'
      color={tw.color('text-1')}
      onPress={() => onPress(item)}
    />
  </View>
)
