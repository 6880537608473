import { useNavigation } from '@react-navigation/native'
import type React from 'react'
import { useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { audioManager } from '#components/utils/audio/audioManager'
import { toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { useSearchAiTutor } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { S } from '#store'

import { TeacherItem } from './Item'

export const ChooseTeacher: React.FC = () => {
  const navigation = useNavigation()
  const [selectedTeacherId, setSelectedTeacherId] = useState<string | null>(
    S.shared.currentUser?.aiTutorId || null,
  )

  const [aiTutor] = useSearchAiTutor()

  useEffect(
    () => () => {
      audioManager.stop()
    },
    [],
  )

  const handleSelectTeacher = (id: string) => {
    setSelectedTeacherId(id)
  }

  const isSelected = (id: string) => id === selectedTeacherId

  const handleSubmit = async (id: string) => {
    const res = await gql.updateUser({
      data: { aiTutorId: id },
    })
    if (res.data?.updateUser) {
      const newUser = S.shared.currentUser
        ? {
            ...S.shared.currentUser,
            aiTutorId: id || '',
          }
        : null
      S.shared.currentUser = newUser
      toastSuccess({ message: 'Update AI teacher successfully' })
      navigation.goBack()
    }
  }

  const onPressSpeak = (url: string) => {
    if (!url) {
      return
    }
    audioManager.setUrl(url)
    audioManager.stop()
    audioManager.play(1)
  }

  return (
    <View style={tw`flex-1 bg-gray-100`}>
      <Header isDark isBack title='AI tutor' />
      <View style={tw.style('p-4 flex-1')}>
        <FlatList
          data={aiTutor.data?.searchAiTutor}
          renderItem={({ item }) => (
            <TeacherItem
              teacher={item}
              onSelect={handleSelectTeacher}
              isSelected={isSelected(item.id)}
              onPressSelect={handleSubmit}
              onPressSpeak={onPressSpeak}
            />
          )}
          keyExtractor={item => item.id}
          showsVerticalScrollIndicator={false}
          ItemSeparatorComponent={() => <View style={tw`h-2 w-full`} />}
        />
        <Button
          onPress={() => handleSubmit(selectedTeacherId || '')}
          disabled={selectedTeacherId === S.shared.currentUser?.aiTutorId}
        >
          Speak now
        </Button>
      </View>
    </View>
  )
}
