import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { ChangePassword } from '#screens/ChangePassword'
import { Chat } from '#screens/Chat/Chat'
import { ChooseLanguage } from '#screens/ChooseLanguage'
import { ChooseLevel } from '#screens/ChooseLevel/ChooseLevel'
import { ChooseTeacher } from '#screens/ChooseTeacher/ChooseTeacher'
import { Games } from '#screens/Games/Games'
import { KnownOrUnknown } from '#screens/KnownOrUnknown/KnownOrUnknown'
import { LevelTest } from '#screens/LevelTest/LevelTest'
import { ListenGame } from '#screens/ListenGame/ListenGame'
import { Payment } from '#screens/Payment/Payment'
import { PrivacyPolicy } from '#screens/PrivacyPolicy/PrivacyPolicy'
import { Review } from '#screens/Review/Review'
import { ReviewListening } from '#screens/ReviewListening/ReviewListening'
import { ScenarioSpeak } from '#screens/ScenarioSpeak/ScenarioSpeak'
import { SuggestedSpeak } from '#screens/SuggestedSpeak/SuggestedSpeak'
import { TermsOfUse } from '#screens/TermsOfUse/TermsOfUse'
import { EditProfile } from '#screens/UpdateProfile/EditProfile'

import type { AppStackParamList } from './types'

const Stack = createNativeStackNavigator<AppStackParamList>()

export const AppNavigator = () => (
  <Stack.Navigator screenOptions={{ headerShown: false, animation: 'ios' }}>
    <Stack.Screen name='ChooseTeacher' component={ChooseTeacher} />
    <Stack.Screen name='ChooseLanguage' component={ChooseLanguage} />
    <Stack.Screen name='ChooseLevel' component={ChooseLevel} />
    <Stack.Screen name='Games' component={Games} />
    <Stack.Screen name='KnownOrUnknown' component={KnownOrUnknown} />
    <Stack.Screen name='ListenGame' component={ListenGame} />
    <Stack.Screen name='Review' component={Review} />
    <Stack.Screen name='ReviewListening' component={ReviewListening} />
    <Stack.Screen name='Chat' component={Chat} />
    <Stack.Screen name='LevelTest' component={LevelTest} />
    <Stack.Screen name='ChangePassword' component={ChangePassword} />
    <Stack.Screen name='Payment' component={Payment} />
    <Stack.Screen name='EditProfile' component={EditProfile} />
    <Stack.Screen name='ScenarioSpeak' component={ScenarioSpeak} />
    <Stack.Screen name='SuggestedSpeak' component={SuggestedSpeak} />
    <Stack.Screen name='PrivacyPolicy' component={PrivacyPolicy} />
    <Stack.Screen name='TermsOfUse' component={TermsOfUse} />
  </Stack.Navigator>
)
