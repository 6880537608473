import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { Text } from '#components/base/Text'
import { audioManager } from '#components/utils/audio/audioManager'
import { tw } from '#components/utils/tw'
import { SwipeableProgressBar } from '#components/widgets/SwipeableProgressBar'
import type { GameQuestionProps } from '#types/games'

export type ListenQuestionRef = {
  stop: () => void
  play: (rate: number) => void
}

export const ListenQuestion = forwardRef<ListenQuestionRef, GameQuestionProps>(
  ({ question, currentIndex }, ref) => {
    const { width } = useWindowDimensions()
    const [time, setTime] = useState<number>(0)
    const [progress, setProgress] = useState<number>(0)
    const [duration, setDuration] = useState<number>(0)
    const [isPlay, setIsPlay] = useState<boolean>(false)

    useEffect(() => {
      audioManager.setUrl(question.media?.url || '')
      setDuration(Math.round(question.media?.duration ?? 0))
      setTime(0)
      setProgress(0)
      return () => {
        audioManager.stop()
      }
    }, [currentIndex])

    useImperativeHandle(ref, () => ({ stop: handleStop, play: playAudio }), [
      isPlay,
      question.media?.url,
      time,
      duration,
    ])

    const handleStop = () => {
      audioManager.stop()
    }

    const handlePlayAudio = async (isPlaying: boolean) => {
      if (isPlaying) {
        audioManager.pause()
      } else {
        playAudio()
      }
      setIsPlay(s => !s)
    }

    const updateLocation = async (num: number) => {
      setIsPlay(false)
      setProgress(num)
      const numberSlider = Math.round(num * duration) / 100
      setTime(Math.round(numberSlider))
      audioManager.setTime(numberSlider)
      setIsPlay(true)
      playAudio()
    }

    const playAudio = () => {
      audioManager.play(
        1,
        () => {
          setIsPlay(false)
          setTime(0)
          setProgress(0)
        },
        times => {
          const timeProcess = duration > 0 ? (times / duration) * 100 : 0
          setTime(times)
          setProgress(timeProcess)
        },
      )
    }

    return (
      <View
        style={tw.style('flex-col mx-4 rounded-2xl p-5 bg-secondary2-300', {
          minHeight: width * 0.35,
        })}
      >
        <View style={tw`flex-1 flex-row gap-x-2 items-center `}>
          <View style={tw`w-15`}>
            <ButtonIcon
              size={32}
              onPress={() => handlePlayAudio(isPlay)}
              tone='four'
              icon={
                isPlay
                  ? { type: 'SAX', name: 'Pause', variant: 'Bold' }
                  : { name: 'play', type: 'SVG' }
              }
            />
          </View>
          <View style={tw`flex-1`}>
            <Text color={tw.color('text-4')} specialType='Headline3'>
              {question.text}
            </Text>
          </View>
        </View>
        {duration > 0 && (
          <View style={tw`flex-row items-center px-2`}>
            <View style={tw`flex-1 mr-2 ml-5.5`}>
              <SwipeableProgressBar
                locationX={66}
                onSeek={updateLocation}
                progress={progress}
              />
            </View>
            <View>
              <Text color={tw.color('text-4')} specialType='paragraph2'>
                {formatTime(Math.round(time))}
              </Text>
            </View>
          </View>
        )}
      </View>
    )
  },
)

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${String(minutes).padStart(2, '0')}:${String(
    remainingSeconds,
  ).padStart(2, '0')}`
}
