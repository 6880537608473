import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Dimensions, Platform, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ulid } from 'ulidx'

import type { FlatListRef } from '#components/base/FlatList'
import { FlatList } from '#components/base/FlatList'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { audioManager } from '#components/utils/audio/audioManager'
import { tw } from '#components/utils/tw'
import type { LevelType, TaskItemInput, TasksItem } from '#graphql/codegen'
import { useSearchTopicInUser, useSearchWordInUser } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import type { FooterButtonType } from '#types/chat'
import type { MessageItem, SearchMessageItem } from '#types/message'

import { TASK_DATA } from './data'
import { Footer } from './Footer'
import { Header } from './Header'
import { Input } from './Input'
import { ListFooter } from './ListFooter'
import { ListHeader } from './ListHeader'
import { Message } from './Message'
import { TipsChat } from './TipsChat'

type Props = AppStackScreenProps<'Chat'>
type CorrectMessageType = {
  fixedText: string
  isConfirm: boolean
  hasNoMistake: boolean
}

const addPreviousMessages = (
  messages: SearchMessageItem[],
  previousData: SearchMessageItem | null,
  fixedText: string,
): MessageItem[] => {
  const data = !previousData ? messages : [previousData, ...messages]
  return data.map((message, index) => {
    const previousMessage = index === data.length - 1 ? null : data[index + 1]
    return {
      ...message,
      previousMessage,
      fixedText,
      createdAt: new Date(message.createdAt),
    }
  })
}

const { width } = Dimensions.get('window')

export const Chat: FC<Props> = observer(({ route }) => {
  const { topicId } = route.params

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize] = useState<number>(15)
  const [total, setTotal] = useState<number>(0)
  const [data, setData] = useState<MessageItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingMessage, setLoadingMessage] = useState<boolean>(false)
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false)
  const [selectedButton, setSelectedButton] = useState<FooterButtonType>(null)
  const [playingMessageId, setPlayingMessageId] = useState<string | null>(null)
  const [buffer, setBuffer] = useState<string | null>(null)

  const [topicInUser] = useSearchTopicInUser({
    variables: {
      filter: {
        topicId,
        userLearnId: S.shared.currentUser?.id,
      },
    },
    requestPolicy: 'network-only',
  })
  const topicInUserId = topicInUser.data?.searchTopicInUser[0]?.id ?? ''

  const [wordInUser] = useSearchWordInUser({
    variables: {
      filter: { topicId, userId: S.shared.currentUser?.id },
      order: ['word_vocabAndPhrase_asc'],
      page: {},
    },
    requestPolicy: 'network-only',
  })

  const headerData = wordInUser.data?.searchWordInUser || []

  const flatListRef = useRef<FlatListRef>(null)

  useEffect(() => {
    if (topicInUserId) {
      fetchData()
    }
  }, [currentPage, topicInUserId])

  useEffect(() => {
    S.socket.initSocket()

    return () => {
      S.socket.socket?.disconnect()
      if (audioManager.getIsPlaying()) {
        audioManager.stop()
      }
    }
  }, [])

  useEffect(() => {
    const callSTT = async () => {
      const resp = await gql.speechToText({ audioBuffer: buffer })
      createMessage(resp.data?.speechToText || '')
    }
    if (buffer) {
      callSTT()
    }
  }, [buffer])

  const checkDoneTask = async () => {
    if (topicInUser.data?.searchTopicInUser[0].percent || 0 < 100) {
      const countFinishedTasks = headerData.filter(
        item => item.used === true,
      ).length
      if (countFinishedTasks / headerData.length > 0.8) {
        await gql.updateTopicInUser({
          id: topicInUserId,
          data: {
            percent: 100,
            topicId,
          },
        })
      }
    }
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const resp = await gql.searchMessageList(
        {
          filter: {
            userId: S.shared.currentUser?.id ?? '',
            topicInUserId,
          },
          order: ['createdAt_desc'],
          page: {
            limit: pageSize,
            offset: (currentPage - 1) * pageSize,
          },
        },
        { requestPolicy: 'network-only' },
      )
      if (resp.data) {
        if (resp.data.messages.length === 0) {
          createMessage('')
        } else {
          setTotal(resp.data?.total ?? 0)

          setData(current => {
            const newData = (resp.data?.messages ?? []).map(message => ({
              ...message,
              isNew: false,
              createdAt: new Date(message.createdAt),
              topicInUser: message.topicInUser
                ? {
                    ...message.topicInUser,
                    createdAt: new Date(message.topicInUser.createdAt),
                  }
                : null,
            }))
            const lastItem = data.length > 0 ? data[data.length - 1] : null
            const list = addPreviousMessages(
              newData,
              currentPage < 2 ? null : lastItem,
              '',
            )
            return currentPage < 2
              ? list
              : [...current.slice(0, current.length - 1), ...list]
          })
        }
      }

      setLoading(false)
      setIsLoadMore(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const createMessage = async (content: string) => {
    const userTempId = ulid()
    const systemTempId = ulid()

    const lastMessage = data.length > 0 ? data[0] : null
    const currentUserId = S.shared.currentUser?.id || ''

    const newUserMessage: MessageItem = {
      content,
      userId: currentUserId,
      topicInUserId: topicInUserId || '',
      sentByUser: true,
      id: userTempId,
      createdAt: new Date(),
      previousMessage: lastMessage,
      fixedText: '',
    }

    const newSystemMessage: MessageItem = {
      content: '',
      userId: currentUserId,
      topicInUserId: topicInUserId || '',
      sentByUser: false,
      id: systemTempId,
      createdAt: new Date(),
      previousMessage: newUserMessage,
      fixedText: '',
    }

    if (content !== '') {
      setData(current => [newUserMessage, ...current])
    }
    setData(current => [newSystemMessage, ...current])
    setLoadingMessage(true)

    const fixedText = await autoCorrectMessage(content)

    const currentTask: TasksItem[] = topicInUser.data?.searchTopicInUser[0]
      .topic?.level
      ? TASK_DATA[topicInUser.data?.searchTopicInUser[0].topic?.level].task ||
        []
      : []

    const currentTasksIds = S.shared.getTasksIdArray()

    const filteredTasks = currentTask
      .map(task => ({
        ...task,
        subTask: (task.subTask || []).filter(
          subTask =>
            subTask && subTask.id && !currentTasksIds.includes(subTask.id!),
        ),
      }))
      .filter(task => task && task.id && !currentTasksIds.includes(task.id!))

    const resp = await gql.createMessage({
      data: {
        content,
        topicInUserId,
        topic_chat: topicInUser.data?.searchTopicInUser[0]?.topic?.name || '',
        tasks: filteredTasks,
        botRole:
          TASK_DATA[topicInUser.data?.searchTopicInUser[0].topic?.level || '']
            ?.aiTutorRole,
        userRole:
          TASK_DATA[topicInUser.data?.searchTopicInUser[0].topic?.level || '']
            ?.userRole,
        scenario:
          TASK_DATA[topicInUser.data?.searchTopicInUser[0].topic?.level || '']
            ?.scenario,
      },
    })

    const createdMessage = resp.data?.createMessage

    if (createdMessage) {
      if (fixedText) {
        setData(current =>
          current.map(msg =>
            msg.id === userTempId
              ? {
                  ...msg,
                  fixedText,
                }
              : msg,
          ),
        )
      }

      setData(current =>
        current.map(msg =>
          msg.id === systemTempId
            ? {
                ...msg,
                id: createdMessage.id,
                content: createdMessage.content,
              }
            : msg,
        ),
      )

      callCheckTask(content)
      if (S.shared.currentUser?.autoPlay) {
        handlePlayTTS(createdMessage)
      }
    }

    setLoadingMessage(false)
  }

  const autoCorrectMessage = async (content: string) => {
    let correctedText = ''

    if (S.shared.currentUser?.repair === 2) {
      const d = await callAPIautoCorrectMessage(content)
      correctedText = d.fixedText
    } else if (
      S.shared.currentUser?.repair === 1 &&
      S.shared.autoCorrectCount === 0
    ) {
      const d = await callAPIautoCorrectMessage(content)
      if (!d.hasNoMistake) {
        correctedText = d.fixedText
      }
    }

    S.shared.autoCorrectCount = (S.shared.autoCorrectCount + 1) % 4

    return correctedText
  }

  const callAPIautoCorrectMessage = async (content: string) => {
    const res = await gql.autoCorrectMessage(
      { content },
      { requestPolicy: 'network-only' },
    )
    const dataString =
      res.data?.autoCorrectMessage ||
      '{"fixedText": "", "isConfirm": false, "hasNoMistake": false}'
    const correctMessage: CorrectMessageType = JSON.parse(dataString)
    return correctMessage
  }

  const callCheckTask = async (content: string) => {
    const currentTask: TasksItem[] = topicInUser.data?.searchTopicInUser[0]
      .topic?.level
      ? TASK_DATA[topicInUser.data?.searchTopicInUser[0].topic?.level].task ||
        []
      : []

    const currentTasksIds = S.shared.getTasksIdArray()

    const filteredTasks = currentTask
      .map(task => ({
        ...task,
        subTask: (task.subTask || []).filter(
          subTask =>
            subTask && subTask.id && !currentTasksIds.includes(subTask.id!),
        ),
      }))
      .filter(task => task && task.id && !currentTasksIds.includes(task.id!))
    const taskResp = await gql.checkTask({
      content,
      task: filteredTasks as TaskItemInput[],
      level:
        (topicInUser.data?.searchTopicInUser[0].topic?.level as LevelType) ||
        'A1',
      topicInUserId,
    })
    if (taskResp.data?.checkTask) {
      const parsedData = JSON.parse(
        taskResp.data?.checkTask.replace(/[“”]/g, '"'),
      )

      const updatedTasksIds = [...currentTasksIds, ...parsedData]
      S.shared.tasksId = JSON.stringify(updatedTasksIds)
    }

    checkDoneTask()
  }

  const onEndReached = () => {
    if (!loading && !isLoadMore && total > currentPage * pageSize) {
      setIsLoadMore(true)
      setCurrentPage(currentPage + 1)
    }
  }

  const handleSuggestPress = async () => {
    setSelectedButton(prev => (prev === 'suggest' ? null : 'suggest'))
    if (selectedButton !== 'suggest') {
      scrollToTop()
    } else {
      setSelectedButton(null)
    }
  }

  const handleShowInputPress = () => {
    setSelectedButton(prev => (prev === 'input' ? null : 'input'))
  }

  const handleSpeakPress = () => {
    setSelectedButton(prev => (prev === 'speak' ? null : 'speak'))
  }

  const scrollToTop = () => {
    flatListRef.current?.scrollToTop()
  }

  const renderItem = ({
    item,
    index,
  }: {
    item: MessageItem
    index: number
  }) => (
    <Message
      item={item}
      isLoading={loadingMessage && index === 0}
      onTTSPress={handlePlayTTS}
    />
  )

  const renderFooter = () => <ListFooter />

  const renderHeader = () =>
    selectedButton === 'suggest' ? (
      <ListHeader
        onSuggestionPress={content => {
          createMessage(content)
          handleSuggestPress()
        }}
        topicInUserId={topicInUserId}
      />
    ) : null

  const handlePlayTTS = async item => {
    try {
      if (playingMessageId === item.id) {
        audioManager.stop()
        setPlayingMessageId(null)
        return
      }

      const tts = await gql.textToSpeech(
        { text: item.content },
        { requestPolicy: 'network-only' },
      )
      const base64Audio = tts.data?.textToSpeech

      if (base64Audio) {
        const base64Uri = `data:audio/mp3;base64,${base64Audio}`
        audioManager.setUrl(base64Uri)

        await audioManager.play(S.shared.currentUser?.speed || 1)
        setPlayingMessageId(item.id)
      }
    } catch (error) {
      console.error('Error playing TTS audio:', error)
    }
  }

  const handleCancelPress = () => {
    setBuffer('')
    setSelectedButton(prev => (prev === 'cancel' ? null : 'cancel'))
  }

  return (
    <View style={tw`flex-1 flex-col`}>
      <Header
        check={S.shared.getTasksIdArray()}
        // using for testing
        // tasks={(topicInUser.data?.searchTopicInUser[0].topic?.description || '')
        //   .split(',')
        //   .map(item => item.trim())}
        tasks={
          topicInUser.data?.searchTopicInUser[0].topic?.level
            ? TASK_DATA[
                topicInUser.data?.searchTopicInUser[0].topic?.level
              ] || {
                scenario: '',
                task: [],
                userRole: '',
                aiTutorRole: '',
              }
            : {
                scenario: '',
                task: [],
                userRole: '',
                aiTutorRole: '',
              }
        }
      />
      <View style={tw`flex-1`}>
        <FlatList
          ref={flatListRef}
          data={data}
          renderItem={renderItem}
          contentContainerStyle={tw`flex-1 flex-col pt-4`}
          inverted
          onEndReached={onEndReached}
          onEndReachedThreshold={0.3}
          ListFooterComponent={renderFooter}
          ListHeaderComponent={renderHeader}
        />
      </View>

      {selectedButton === 'input' ? (
        <View>
          {width > 900 ? (
            <View
              style={tw.style(
                'flex-col pt-8 bg-background-light-white rounded-t-3xl',
              )}
            >
              <Input onSend={createMessage} disableButton={loadingMessage} />
              <TouchableOpacity
                style={[
                  tw.style('flex-row justify-center py-1 px-2 mb-6 mt-3'),
                  Platform.OS === 'web' && { cursor: 'pointer' },
                ]}
                onPress={handleShowInputPress}
              >
                <SystemIcon
                  type='SAX'
                  name='Microphone2'
                  variant='Bold'
                  color={tw.color('text-2')}
                />
                <Text specialType='Title' color={tw.color('text-2')}>
                  Voice Mode
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={tw.style('bg-background-light-white rounded-t-3xl')}>
              <Input
                onSend={createMessage}
                disableButton={loadingMessage}
                handleMicIconPress={() => setSelectedButton(null)}
                handleSuggestPress={handleSuggestPress}
              />
            </View>
          )}
        </View>
      ) : (
        <Footer
          bufferCallback={setBuffer}
          selectedButton={selectedButton}
          onButtonPress={buttonType => {
            switch (buttonType) {
              case 'suggest':
                handleSuggestPress()
                break
              case 'input':
                handleShowInputPress()
                break
              case 'speak':
                handleSpeakPress()
                break
              case 'cancel':
                handleCancelPress()
                break
            }
          }}
        />
      )}

      {!S.shared.isTipsTopicSpeak && <TipsChat />}
    </View>
  )
})
