import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import { navigate } from '#navigator/helpers'

type Pr = {
  closeActionsheet: () => void
  topicId: string
}

export const Lesson: FC<Pr> = observer(({ closeActionsheet, topicId }) => {
  const onPressButton = (type: ButtonType) => {
    closeActionsheet()
    switch (type) {
      case Type.vocabulary:
        navigate('App', {
          screen: 'Games',
          params: { topicId, isPhrase: false, isCompleted: true },
        })
        break
      case Type.phrases:
        navigate('App', {
          screen: 'Games',
          params: { topicId, isPhrase: true, isCompleted: true },
        })
        break
      case Type.listen:
        navigate('App', {
          screen: 'ListenGame',
          params: { topicId, isCompleted: true },
        })
        break
      case Type.speaking:
        navigate('App', {
          screen: 'Chat',
          params: { topicId, isCompleted: true },
        })
        break
      default:
        break
    }
  }

  return (
    <ActionSheet>
      <View style={tw.style('gap-3 py-6 px-2')}>
        <Button
          type='vocabulary'
          title='Learn vocabulary'
          onPress={onPressButton}
        />
        <Button type='phrases' title='Learn phrases' onPress={onPressButton} />
        <Button type='listen' title='Listen' onPress={onPressButton} />
        <Button type='speaking' title='Speak with AI' onPress={onPressButton} />
      </View>
    </ActionSheet>
  )
})

enum Type {
  vocabulary = 'vocabulary',
  phrases = 'phrases',
  listen = 'listen',
  speaking = 'speaking',
}

type ButtonType = keyof typeof Type

type ButtonProps = {
  type: ButtonType
  title: string
  onPress?: (type: ButtonType) => void
}

const Button: FC<ButtonProps> = ({ type, title, onPress }) => (
  <TouchableOpacity
    style={tw`w-full justify-center rounded-5 align-center py-4 px-5 bg-background-light-white`}
    onPress={() => onPress?.(type)}
  >
    <Text
      specialType='Title'
      color={tw.color('primary-400')}
      textAlign='center'
    >
      {title}
    </Text>
  </TouchableOpacity>
)
