import { useContext } from 'react'

import { OverlayContext } from './OverlayProvider'

export const useOverlay = () => {
  const r = useContext(OverlayContext)
  if (!r) {
    throw new Error('Missing overlay provider')
  }
  const {
    openModal,
    closeModal,
    closeAllModals,
    openActionsheet,
    closeActionsheet,
    closeAllActionsheets,
  } = r
  return {
    openModal,
    closeModal,
    closeAllModals,
    openActionsheet,
    closeActionsheet,
    closeAllActionsheets,
  }
}
