import type { LinkingOptions } from '@react-navigation/native'

import { rnFrontEndOrigin } from '#config'

import type { RootStackParamList } from './types'

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [rnFrontEndOrigin],
  config: {
    screens: {
      LaunchScreen: 'launch',
      App: {
        path: '/',
        screens: {
          ChooseTeacher: 'choose-teacher',
          ChooseLanguage: 'choose-language',
          ChooseLevel: 'choose-level',
          Games: 'games?:topicId',
          KnownOrUnknown: 'known-or-unknown',
          ListenGame: 'listen-game?:topicId',
          Review: 'review',
          ReviewListening: 'review-listening',
          LevelTest: 'level-test',
          Chat: ':topicId/speak',
          ChangePassword: 'change-password',
          EditProfile: 'edit-profile',
          Payment: 'payment',
          ScenarioSpeak: 'scenario-speak',
          SuggestedSpeak: 'suggested-speak',
          PrivacyPolicy: 'privacy-policy',
          TermsOfUse: 'terms-of-use',
        },
      },
      Auth: {
        path: 'auth',
        screens: {
          Login: 'login',
          ForgotPassword: 'forgot-password',
          NewPassword: 'new-password',
          Welcome: 'welcome',
          PrivacyPolicy: 'privacy-policy',
          TermsOfUse: 'terms-of-use',
        },
      },
      Home: {
        path: 'home',
        screens: {
          Topics: 'topics',
          Profile: 'profile',
        },
      },
      Admin: {
        path: 'admin',
        screens: {
          AdminUsers: 'users',
          AdminTopics: 'topics',
          AdminTopicDetail: 'topics/:id',
          AdminAITutor: 'ai-tutor',
          AdminTranslateDetail: 'translate',
          AdminTopicQues: 'topics/:topicId/:wordId',
          AdminTopicQuesDetail: 'topics/:topicId/:wordId/:quesId',
          AdminListenGame: 'listen-game/:topicId',
          AdminTags: 'tags',
          AdminPayment: 'payment',
          AdminPaymentDetail: 'payment/:id',
          AdminSettings: 'settings',
        },
      },
      AdminAuth: {
        path: 'admin/auth',
        screens: {
          AdminLogin: 'login',
        },
      },
    },
  },
}
