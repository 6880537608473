import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { AdminLayout } from '#admin/components/widgets/AdminLayout'
import { AITutor } from '#admin/screens/AITutor/AITutor'
import { ListenGame } from '#admin/screens/ListenGame/ListenGame'
import { Payment } from '#admin/screens/Payment/Payment'
import { PaymentDetail } from '#admin/screens/Payment/PaymentDetail'
import { Ques } from '#admin/screens/Ques/Ques'
import { QuesDetail } from '#admin/screens/QuesDetail/QuesDetail'
import { Settings } from '#admin/screens/Settings/Settings'
import { Tags } from '#admin/screens/Tags/Tags'
import { AdminTopicDetail } from '#admin/screens/TopicDetail/TopicDetail'
import { Topics } from '#admin/screens/Topics/Topics'
import { Translate } from '#admin/screens/Translate/Translate'
import { Users } from '#admin/screens/Users/Users'
import type { AdminParamList } from '#navigator/types'

const Stack = createNativeStackNavigator<AdminParamList>()
export const AdminNavigator = () => (
  <AdminLayout>
    <Stack.Navigator
      initialRouteName='AdminUsers'
      screenOptions={{ headerShown: false, animation: 'ios' }}
    >
      <Stack.Screen name='AdminTopics' component={Topics} />
      <Stack.Screen name='AdminUsers' component={Users} />
      <Stack.Screen name='AdminTopicDetail' component={AdminTopicDetail} />
      <Stack.Screen name='AdminTopicQues' component={Ques} />
      <Stack.Screen name='AdminTopicQuesDetail' component={QuesDetail} />
      <Stack.Screen name='AdminAITutor' component={AITutor} />
      <Stack.Screen name='AdminListenGame' component={ListenGame} />
      <Stack.Screen name='AdminTranslateDetail' component={Translate} />
      <Stack.Screen name='AdminTags' component={Tags} />
      <Stack.Screen name='AdminPayment' component={Payment} />
      <Stack.Screen name='AdminPaymentDetail' component={PaymentDetail} />
      <Stack.Screen name='AdminSettings' component={Settings} />
    </Stack.Navigator>
  </AdminLayout>
)
