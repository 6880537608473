import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { View } from 'react-native'

import { Avatar } from '#components/base/Avatar'
import { IconSax } from '#components/base/IconSax'
import { ScrollView } from '#components/base/ScrollView'
import { Text } from '#components/base/Text'
import { getUserName } from '#components/utils/getUserName'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import { S } from '#store'
import { ChooseLanguage } from '#types/language'

import type { ItemProps } from './Item'
import { Item } from './Item'
import { onLogoutPress } from './onLogoutPress'

type IdType =
  | 'subscription'
  | 'learn-language'
  | 'world-bank'
  // | 'push-notification'
  // | 'dark-mode'
  | 'private-policy'
  | 'native-language'
  | 'english-level'
  | 'help'

type ItemD = ItemProps & {
  id: IdType
  isDivider?: boolean
}

const data: ItemD[] = [
  {
    id: 'subscription',
    title: 'Subscription',
    iconLeft: 'Star',
    subTitle: 'Basic plan',
    type: 'icon',
    isDivider: true,
  },
  {
    id: 'learn-language',
    title: 'Learn language',
    iconLeft: 'Lock1',
    type: 'icon',
    isDivider: true,
  },
  {
    id: 'native-language',
    title: 'Native language',
    iconLeft: 'ArchiveTick',
    type: 'icon',
    isDivider: true,
  },
  {
    id: 'english-level',
    title: 'Level',
    iconLeft: 'ArchiveTick',
    type: 'icon',
    isDivider: true,
  },
  // {
  //   id: 'push-notification',
  //   title: 'Push notification',
  //   iconLeft: 'Notification',
  //   type: 'switch',
  // },
  // {
  //   id: 'dark-mode',
  //   title: 'Dark mode',
  //   iconLeft: 'Moon',
  //   type: 'switch',
  //   isDivider: true,
  // },
  {
    id: 'private-policy',
    title: 'Private policy',
    iconLeft: 'ShieldTick',
    type: 'icon',
  },
  {
    id: 'help',
    title: 'Help',
    iconLeft: 'ShieldTick',
    type: 'icon',
  },
]

export const Profile = observer(() => {
  const screenRedirect = (id: string) => {
    switch (id) {
      case 'learn-language': {
        navigate('App', {
          screen: 'ChooseLanguage',
          params: { type: ChooseLanguage.learn },
        })
        break
      }
      case 'native-language': {
        navigate('App', {
          screen: 'ChooseLanguage',
          params: { type: ChooseLanguage.native },
        })
        break
      }
      case 'english-level': {
        navigate('App', { screen: 'ChooseLevel', params: { isShow: true } })
        break
      }
      case 'subscription':
        navigate('App', { screen: 'Payment' })
        break
    }
  }

  const updateProfile = () => {
    navigate('App', {
      screen: 'EditProfile',
    })
  }

  const onPressClearGames = async () => {
    const respWordInUser = await gql.searchWordInUser(
      {
        filter: { userId: S.shared.currentUser?.id },
      },
      {
        requestPolicy: 'network-only',
      },
    )
    const wordInUser = respWordInUser.data?.searchWordInUser || []
    if (wordInUser.length > 0) {
      await Promise.all(
        wordInUser.map(async w => await gql.deleteWordInUser({ id: w.id })),
      )
    }
    const respTopicInUser = await gql.searchTopicInUser(
      {
        filter: { userLearnId: S.shared.currentUser?.id },
      },
      {
        requestPolicy: 'network-only',
      },
    )
    const topicInUser = respTopicInUser.data?.searchTopicInUser || []
    if (topicInUser.length > 0) {
      await Promise.all(
        topicInUser.map(async t => await gql.deleteTopicInUser({ id: t.id })),
      )
    }
  }

  const onPressClearTask = () => {
    S.shared.tasksId = ''
    gql.deleteMessage()
  }

  return (
    <View style={tw`flex-1 flex-col`}>
      <Header isBack={false} title='Profile management' />
      <ScrollView contentContainerStyle={tw`flex-1 pt-4`}>
        <View
          style={tw.style(
            'flex-col mx-4 py-4 rounded-3xl bg-neutral-75 shadow-lg gap-2',
            { shadowColor: tw.color('primary-300') },
          )}
        >
          <View style={tw.style('flex-row items-center px-4 mb-6')}>
            <Avatar
              size='large'
              name={S.shared.currentUser?.name}
              style={tw`w-12 h-12 rounded-full`}
              source={{ uri: S.shared.currentUser?.thumbnail?.url ?? '' }}
            />
            <View style={tw.style('flex-1 justify-center mx-4')}>
              <Text specialType='Headline4'>
                {getUserName({
                  email: S.shared.currentUser?.email || '',
                  name: S.shared.currentUser?.name || '',
                })}
              </Text>
              <Text specialType='paragraph1' color={tw.color('text-2')}>
                {S.shared.currentUser?.email}
              </Text>
            </View>
            <IconSax name='Edit' onPress={updateProfile} />
          </View>
          {data.map(({ id, isDivider, ...itemProps }) => (
            <Fragment key={id}>
              <Item {...itemProps} onPress={() => screenRedirect(id)} />
              {isDivider && (
                <Divider style={tw.style('bg- black border-neutral-200')} />
              )}
            </Fragment>
          ))}
        </View>
        <View style={tw.style('pt-2 px-2')}>
          <View
            style={tw.style('rounded-full bg-neutral-75 shadow-lg', {
              shadowColor: tw.color('primary-300'),
            })}
          >
            <Item
              iconLeft='Trash'
              onPress={onPressClearGames}
              title='Clear games'
              type='icon'
            />
          </View>
        </View>
        <View style={tw.style('pt-2 px-2')}>
          <View
            style={tw.style('rounded-full bg-neutral-75 shadow-lg', {
              shadowColor: tw.color('primary-300'),
            })}
          >
            <Item
              iconLeft='Trash'
              onPress={onPressClearTask}
              title='Clear tasks & messages'
              type='icon'
            />
          </View>
        </View>
        <View style={tw.style('pt-6 pb-4 px-2')}>
          <View
            style={tw.style(
              'rounded-full bg-neutral-75 shadow-xl shadow-primary-400',
            )}
          >
            <Item
              iconLeft='LoginCurve'
              onPress={onLogoutPress}
              title='Log out'
              type='icon'
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
})
