import { Form, Input, Select } from 'antd'
import type React from 'react'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import type { Languages } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { languageOptions } from '#types/language'
import type { SearchWordItem } from '#types/word'

type Prop = {
  selectedWord?: SearchWordItem
  word: {
    id: string
    name: string
  }
  type: string
  closeModal: () => void
  onOk: () => void
  translateData: {
    id: string
    name: string
  }
  languageTopic: string
}

export const TranslateDetailModal = ({
  selectedWord,
  closeModal,
  word,
  type,
  onOk,
  translateData,
  languageTopic,
}: Prop) => {
  const [translate, setTranslate] = useState<string>(translateData.name)
  const [language, setLanguage] = useState<Languages>(
    languageOptions.filter(e => e.value !== languageTopic)[0].value,
  )

  const [loading, setLoading] = useState<boolean>(false)

  const handleModalOk = async () => {
    setLoading(true)
    try {
      if (!word.id) {
        setLoading(false)
        return
      }
      let res
      if (translateData.id) {
        res = await gql.updateTranslate({
          id: translateData.id,
          data: {
            wQAId: word.id,
            text: translate,
            suportLanguage: language,
          },
          update: true,
        })
      } else {
        res = await gql.createTranslate({
          data: {
            wQAId: word.id,
            text: translate,
            suportLanguage: language,
          },
        })
      }
      if (res.error || !res.data) {
        toastError({ title: 'Error', message: res.error?.message })
        setLoading(false)
        return
      }
      toastSuccess({
        message: 'Update translate successfully',
      })
      onOk()
      closeModal()
    } catch (error) {
      toastError({ message: 'Failed to handle word' })
    }
    setLoading(false)
  }

  const handleModalCancel = () => {
    setTranslate('')
    closeModal()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && word.id) {
      handleModalOk()
    }
  }

  return (
    <ModalLayout
      title={translateData.name ? 'Edit translate' : 'Add new translate'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={!word.id || loading}
      autoClose={false}
      isLoading={loading}
    >
      <Form layout='vertical' onKeyUp={handleKeyPress} style={tw`w- pt-2`}>
        <Form.Item
          label={
            type === 'word'
              ? 'Word and Phrases'
              : type === 'answer'
                ? 'Answer'
                : 'Question'
          }
        >
          <Input value={word.name} disabled />
        </Form.Item>
        <Form.Item label='Language'>
          <Select
            value={language}
            onChange={e => setLanguage(e)}
            options={languageOptions.filter(e => e.value !== languageTopic)}
          />
        </Form.Item>
        <Form.Item label='Translated content'>
          <Input
            value={translate}
            onChange={e => {
              setTranslate(e.target.value)
            }}
          />
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
