import { DefaultTheme, NavigationContainer } from '@react-navigation/native'

import { useDarkColor } from '#components/dark/hooks'
import { LaunchScreen } from '#screens/LaunchScreen'

import { rootNavigationRef } from './helpers'
import { RootNavigator } from './RootNavigator'
import { linking } from './routers'

export const AppNavigatorContainer = () => {
  const dark = useDarkColor()

  return (
    <NavigationContainer
      ref={rootNavigationRef}
      linking={linking}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: dark('background-dark-1', 'background-light-1'),
        },
      }}
      fallback={<LaunchScreen />}
    >
      <RootNavigator />
    </NavigationContainer>
  )
}
