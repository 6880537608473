import { Toast as NbToast } from 'native-base'
import { ulid } from 'ulidx'

import type { MessageProps } from '#components/widgets/ToastMessage'
import { ToastMessage } from '#components/widgets/ToastMessage'

export const toast = ({ type, message }: MessageProps) => {
  if (!message) {
    return null
  }
  const id = ulid()

  NbToast.show({
    id,
    render: () => <ToastMessage type={type} message={message} />,
    placement: 'bottom',
  })
  return id
}

// _prefix must be used via ctx. or internally in this context folder
export const toastSuccess = ({ message }: { message?: string }) =>
  toast({ type: 'success', message })

export const toastWarning = ({ message }: { message?: string }) =>
  toast({ type: 'warning', message })

export const toastError = ({ message }: { message?: Error | string }) => {
  const m =
    typeof message === 'object' && message.message
      ? message.message
      : `${message || ''}`
  return toast({ type: 'error', message: m })
}
