import type { FC } from 'react'
import { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { TasksItem } from '#graphql/codegen'

import { SubTaskItem } from './SubTaskItem'

type TaskItemProps = {
  item: TasksItem
  index: number
  checkTaskItem: string[]
}

export const TaskItem: FC<TaskItemProps> = ({ item, index, checkTaskItem }) => {
  const [isShow, setIsShow] = useState<boolean>(false)

  const onPressShow = () => {
    setIsShow(!isShow)
  }

  const renderSubTask = ({
    item: subTaskItem,
    index: idx,
  }: {
    item: TasksItem
    index: number
  }) => <SubTaskItem item={subTaskItem} index={idx} check={checkTaskItem} />

  const renderItemSeparatorSubTask = () => <View style={tw`h-2`} />

  return (
    <View style={tw`pt-2 px-3`}>
      <TouchableOpacity
        onPress={onPressShow}
        style={tw.style('flex-row', isShow ? ' mb-2' : '')}
      >
        <Text specialType='paragraph1' color={tw.color('text-1')}>
          {`${index + 1}.`}
        </Text>
        <View style={tw`flex-1 mx-2`}>
          <Text specialType='paragraph1' color={tw.color('text-1')}>
            {item.name}
          </Text>
        </View>
        <SystemIcon type='SAX' name={isShow ? 'ArrowUp2' : 'ArrowDown2'} />
      </TouchableOpacity>
      {isShow && (
        <FlatList
          data={item.subTask}
          renderItem={renderSubTask}
          scrollEnabled={false}
          ItemSeparatorComponent={renderItemSeparatorSubTask}
        />
      )}
    </View>
  )
}
