export const images = {
  no_image: require('./images/no_image.png'),
  non_avatar: require('./images/non_avatar.png'),
  logo: require('./images/logo.png'),
  image_game: require('./images/image_game.png'),
  welcome: require('./images/welcome.png'),
  auth: require('./images/auth.jpg'),
  congratulation: require('./images/gif_congrate.gif'),
  vocabulary: require('./images/vocabulary.png'),
  speaking: require('./images/speaking.png'),
  silderThumbIcon: require('./images/thumbIcon.png'),
}
