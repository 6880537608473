import type { FC } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { TouchableOpacity, View } from 'react-native'

import { IconSax } from '#components/base/IconSax'
import { tw } from '#components/utils/tw'

type Props = {
  onPress?: () => void
  buttonStyle?: StyleProp<ViewStyle>
  isNotify?: boolean
}

export const NotificationIcon: FC<Props> = ({
  onPress,
  buttonStyle,
  isNotify = false,
}) => (
  <TouchableOpacity onPress={onPress} style={[tw`w-10 h-10`, buttonStyle]}>
    <View
      style={tw.style(
        'flex-1  justify-center items-center rounded-full bg-neutral-75',
      )}
    >
      <IconSax name='Notification' size={24} color='black' />
      {isNotify && (
        <View
          style={tw`absolute top-2.25 right-3 w-2 h-2 bg-red-500 rounded-full`}
        />
      )}
    </View>
  </TouchableOpacity>
)
