import type { FC, LegacyRef } from 'react'
import { useWindowDimensions, View } from 'react-native'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'
import type {
  AnswerGame,
  HintProps,
  LayoutState,
  SwipeType,
} from '#types/games'
import { LabelGame } from '#types/games'
import { LabelListenGame } from '#types/listenGame'
import type { QuesTionType } from '#types/question'

import type {
  RearrangementAnswerProps,
  RearrangementRef,
} from './Answer/RearrangementAnswer'
import { RearrangementAnswer } from './Answer/RearrangementAnswer'
import type { SameOrDifferentRef } from './Answer/SameOrDifferentAnswer'
import { SameOrDifferentAnswer } from './Answer/SameOrDifferentAnswer'
import { GameAnswerItem } from './AnswerItem'

type GameAnswersProps = {
  data: AnswerGame[]
  word: RearrangementAnswerProps['word']
  type: QuesTionType
  currentIndex: number
  layoutStates?: LayoutState[]
  hint?: HintProps
  // Function
  onPressAnswer?: (item: AnswerGame, index: number) => void
  onChangeAnswer?: () => void
  onChangeData?: (isTrue: boolean) => void
  onChooseAnswer?: (type: SwipeType) => void
  // Ref
  sameOrDifferentRef?: LegacyRef<SameOrDifferentRef>
  rearrangementRef?: LegacyRef<RearrangementRef>
}

export const GAME_TYPE_DATA: QuesTionType[] = ['selectImage']

export const GameAnswers: FC<GameAnswersProps> = ({
  data,
  type,
  word,
  currentIndex,
  layoutStates = [],
  hint = [],
  // Function
  onPressAnswer,
  onChangeData,
  onChooseAnswer,
  // Ref
  rearrangementRef,
  sameOrDifferentRef,
}) => {
  const { height } = useWindowDimensions()
  const renderItem = (item: AnswerGame, index: number) => (
    <GameAnswerItem
      key={`${item.id}-${index}`}
      item={item}
      type={type}
      index={index}
      onPressAnswer={() => onPressAnswer?.(item, index)}
      layoutStates={layoutStates[index]}
      hint={hint}
    />
  )

  const renderContent = () => {
    switch (type) {
      case 'rearrangement':
        return (
          <RearrangementAnswer
            word={word}
            currentIndex={currentIndex}
            onChangeData={onChangeData}
            ref={rearrangementRef}
          />
        )
      case 'sameOrDifferent':
        return (
          <SameOrDifferentAnswer
            data={data}
            ref={sameOrDifferentRef}
            onChangeData={onChooseAnswer}
            currentIndex={currentIndex}
          />
        )
      default:
        return getListAnswer(type, data).map(renderItem)
    }
  }

  return (
    <View
      style={tw.style(
        typeStyle?.[type] ? typeStyle[type] : defaultStyle,
        type === 'sameOrDifferent' ? '' : { minHeight: height * 0.5 },
      )}
    >
      {renderContent()}
    </View>
  )
}

const defaultStyle: Style = tw.style(
  'w-full flex-col items-center justify-center gap-4 p-4',
)

const typeStyle: Record<QuesTionType, Style> = {
  selectImage: tw.style(
    'w-full flex-row flex-wrap items-center justify-center gap-2 ',
  ),
  rearrangement: tw.style('w-full flex-col'),
  sameOrDifferent: tw.style(
    'h-30 w-full flex-row flex-wrap items-center justify-center',
  ),
  hearAudio: defaultStyle,
  hearVideo: defaultStyle,
  correctTranslation: defaultStyle,
  fillTheBlank: defaultStyle,
  completeTheDialogue: defaultStyle,
  multipleChoice: defaultStyle,
  yesNo: defaultStyle,
  correctPhrase: defaultStyle,
}

const getListAnswer = (type: QuesTionType, data: AnswerGame[]) => {
  if (type === 'sameOrDifferent') {
    const itemDifferent = data.filter(
      item => item.text === LabelGame.LabelDifferent,
    )
    const itemSame = data.filter(item => item.text === LabelGame.LabelDifferent)
    return [...itemDifferent, ...itemSame]
  }

  if (type === 'yesNo') {
    const itemTrue = data.filter(
      item => item.text === LabelListenGame.LabelTrue,
    )
    const itemFalse = data.filter(
      item => item.text === LabelListenGame.LabelFalse,
    )
    return [...itemTrue, ...itemFalse]
  }

  return data
}
