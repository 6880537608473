import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Animated, Easing, TouchableOpacity, View } from 'react-native'

import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { ScrollView } from '#components/base/ScrollView'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { delay } from '#components/utils/delay'
import { toastError } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { useDimensions } from '#components/utils/useDimensions'
import { Arrow } from '#components/widgets/Arrow'
import { Header } from '#components/widgets/Header'
import { gql } from '#graphql/urql'
import { navigate, replace } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import type { SwipeType } from '#types/games'
import { GameTimes } from '#types/games'

import type { ItemWord } from './Card'
import { Card } from './Card'
import { LoadingKnownOrUnknown } from './LoadingKnownOrUnknown'
import { Tips } from './Tips'

type Props = AppStackScreenProps<'KnownOrUnknown'>

export const KnownOrUnknown: FC<Props> = observer(({ route }) => {
  const topicId = route.params.id

  const { screenWidth } = useDimensions({ padding: 16 })

  const [data, setData] = useState<ItemWord[]>([])
  const [disabled, setDisabled] = useState<boolean>(false)
  const [swipeType, setSwipeType] = useState<SwipeType>('none')
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const countWord = useRef<number>(0)
  const vocabularyCount = useRef<number>(0)
  const opacityValues = useRef<Animated.Value[]>([])
  const currentIndex = useRef<number>(0)
  const colorValueLeft = useRef<Animated.Value>(new Animated.Value(0)).current
  const borderColorValueLeft = useRef<Animated.Value>(
    new Animated.Value(0),
  ).current
  const scaleValueLeft = useRef<Animated.Value>(new Animated.Value(1)).current
  const colorValueRight = useRef<Animated.Value>(new Animated.Value(0)).current
  const borderColorValueRight = useRef<Animated.Value>(
    new Animated.Value(0),
  ).current
  const scaleValueRight = useRef<Animated.Value>(new Animated.Value(1)).current

  const fetchData = async () => {
    setLoading(true)
    try {
      const wordInUserRes = await gql.searchWordInUser(
        {
          filter: {
            topicId,
            userId: S.shared.currentUser?.id,
            times_lt: GameTimes.TimeMax,
          },
          order: 'times_asc',
        },
        { requestPolicy: 'network-only' },
      )

      const dataWordInUser = wordInUserRes.data?.searchWordInUser || []

      const wordIds = dataWordInUser
        .map(item => item.word?.id)
        .filter((id): id is string => !!id)

      const wordRes = await gql.searchWord(
        {
          filter: {
            topicId,
            id_notIn: wordIds,
          },
          order: ['vocabAndPhrase_asc'],
          page: {},
        },
        { requestPolicy: 'network-only' },
      )

      return wordRes.data?.searchWord || []
    } catch (error) {
      toastError({ message: 'Failed to fetch words' })
      return []
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const cards = await fetchData()

      const list = cards
        .map((item, idx) => ({
          ...item,
          index: idx + 1,
        }))
        .reverse()

      opacityValues.current = list.map(() => new Animated.Value(1))
      currentIndex.current = list.length - 1
      setData(list)
      setTotal(list.length)
    }

    loadData()
  }, [topicId])

  useEffect(
    () => () => {
      colorValueLeft.stopAnimation()
      borderColorValueLeft.stopAnimation()
      scaleValueLeft.stopAnimation()
      colorValueRight.stopAnimation()
      borderColorValueRight.stopAnimation()
      scaleValueRight.stopAnimation()
    },
    [],
  )

  const setKnowOrUnknown = async (card: ItemWord, times: number) => {
    try {
      const res = await gql.createWordInUser({
        data: {
          userId: S.shared.currentUser?.id ?? '',
          wordId: card.id,
          topicId,
          times,
        },
      })
      if (res.error || !res.data) {
        toastError({
          message: 'Failed to mark word as known or unknown',
        })
      }
    } catch (error) {
      toastError({
        message: 'Failed to mark word as known or unknown',
      })
    }
  }

  const redirect = async (index: number) => {
    if (index === 0) {
      await delay(500)
      const pointTopic = countWord.current / total >= 0.8
      const wordInUserRes = await gql.searchWordInUser(
        {
          filter: {
            topicId,
            userId: S.shared.currentUser?.id,
            times_lt: GameTimes.TimeMax,
            word_some: { isVocabulary: true },
          },
          order: 'times_asc',
        },
        { requestPolicy: 'network-only' },
      )

      const dataWordInUser = wordInUserRes.data?.searchWordInUser || []
      const totalVocabulary = dataWordInUser.length
      const isPhrase = !totalVocabulary

      navigate(
        'App',
        pointTopic
          ? { screen: 'ListenGame', params: { topicId } }
          : { screen: 'Games', params: { topicId, isPhrase } },
      )
      S.shared.percentageOfTopic({ topicId })
    }
  }

  const handleSwipe = (card: ItemWord, index: number, times: number) => {
    setData(currentState => currentState.filter((_, i) => i !== index))
    setKnowOrUnknown(card, times)
    if (times === GameTimes.TimeMax) {
      countWord.current = countWord.current + 1
      if (card.isVocabulary) {
        vocabularyCount.current = vocabularyCount.current + 1
      }
    }
    onStartSwipe('none')
    redirect(index)
    setDisabled(false)
  }

  const animateView = (
    colorValue: Animated.Value,
    borderColorValue: Animated.Value,
    scaleValue: Animated.Value,
    colorToValue: number,
    borderColorToValue: number,
    scaleToValue: number,
  ) =>
    Animated.parallel([
      Animated.timing(colorValue, {
        toValue: colorToValue,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(borderColorValue, {
        toValue: borderColorToValue,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(scaleValue, {
        toValue: scaleToValue,
        duration: 100,
        useNativeDriver: true,
      }),
    ])

  const swipeLeft = () => {
    Animated.parallel([
      animateView(
        colorValueRight,
        borderColorValueRight,
        scaleValueRight,
        0,
        0,
        1,
      ),
      animateView(
        colorValueLeft,
        borderColorValueLeft,
        scaleValueLeft,
        1,
        1,
        1.2,
      ),
    ]).start()
  }

  const swipeRight = () => {
    Animated.parallel([
      animateView(
        colorValueLeft,
        borderColorValueLeft,
        scaleValueLeft,
        0,
        0,
        1,
      ),
      animateView(
        colorValueRight,
        borderColorValueRight,
        scaleValueRight,
        1,
        1,
        1.2,
      ),
    ]).start()
  }

  const resetAnimation = () => {
    Animated.parallel([
      animateView(
        colorValueLeft,
        borderColorValueLeft,
        scaleValueLeft,
        0,
        0,
        1,
      ),
      animateView(
        colorValueRight,
        borderColorValueRight,
        scaleValueRight,
        0,
        0,
        1,
      ),
    ]).start()
  }

  const onStartSwipe = (type: SwipeType) => {
    if (type !== swipeType) {
      setDisabled(true)
      setSwipeType(type)
      switch (type) {
        case 'left':
          swipeLeft()
          break
        case 'right':
          swipeRight()
          break
        default:
          resetAnimation()
          break
      }
    }
  }

  const handleClick = (times: number) => {
    setDisabled(true)
    Animated.timing(opacityValues.current[data.length - 1], {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.cubic,
    }).start(() => {
      setDisabled(false)
      const card = data[data.length - 1]
      const index = data.length - 1
      handleSwipe(card, index, times)
    })
  }

  const interpolatedColorLeft = colorValueLeft.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(217, 217, 217, 0.35)', '#FFF'],
  })

  const interpolatedBorderColorLeft = borderColorValueLeft.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(217, 217, 217, 0.3)', '#FFF'],
  })

  const interpolatedColorRight = colorValueRight.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(217, 217, 217, 0.35)', '#22A978'],
  })

  const interpolatedBorderColorRight = borderColorValueRight.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(217, 217, 217, 0.3)', '#22A978'],
  })

  return (
    <LinearGradientColor>
      <View
        style={tw`flex-1 flex-col absolute top-0 left-0 right-0 bottom-0 items-center`}
      >
        <View
          style={tw.style('flex-1 flex-col h-full', {
            width: screenWidth + 56,
          })}
        >
          <View style={tw`px-2`}>
            <Header
              typeSlider='slice'
              title='Flash card'
              onPressBack={() => {
                replace('Home', { screen: 'Topics' })
              }}
              isDark={false}
            />
          </View>
          {loading ? (
            <View style={tw`flex-1 flex-col`}>
              <LoadingKnownOrUnknown />
            </View>
          ) : (
            <View style={tw`flex-1 flex-col`}>
              {data.length > 0 ? (
                <ScrollView>
                  <View style={tw.style('flex-1 flex-col')}>
                    <View
                      style={tw.style(
                        'flex-1 py-4 items-center justify-center',
                      )}
                    >
                      {data.map((item, index) => (
                        <Animated.View
                          key={item.id}
                          style={[
                            {
                              opacity: opacityValues.current[index],
                              zIndex: index,
                            },
                            tw`items-center justify-center w-full h-full absolute`,
                          ]}
                        >
                          <Card
                            key={item.id}
                            data={item}
                            onSwipeLeft={() =>
                              handleSwipe(item, index, GameTimes.TimeMax)
                            }
                            onSwipeRight={() =>
                              handleSwipe(item, index, GameTimes.TimeMin)
                            }
                            onStartSwipe={onStartSwipe}
                            total={total}
                          />
                        </Animated.View>
                      ))}
                    </View>
                    <View
                      style={tw.style(
                        'flex-row items-center justify-center self-center h-30',
                        { width: screenWidth - 40 },
                      )}
                    >
                      <View style={tw`flex-col items-center justify-center`}>
                        <TouchableOpacity
                          onPress={() => handleClick(GameTimes.TimeMax)}
                          disabled={disabled}
                          activeOpacity={1}
                        >
                          <Animated.View
                            style={[
                              {
                                backgroundColor: interpolatedColorLeft,
                                borderColor: interpolatedBorderColorLeft,
                                transform: [{ scale: scaleValueLeft }],
                              },
                              tw`w-18 h-18 rounded-full items-center justify-center border-[0.5px]`,
                            ]}
                          >
                            <SystemIcon
                              type='SAX'
                              name='EyeSlash'
                              color={swipeType === 'left' ? '#000' : '#FFF'}
                            />
                          </Animated.View>
                          <View style={tw`pt-2 items-center`}>
                            <Text
                              specialType='Title'
                              color='#FFF'
                              textAlign='center'
                            >
                              Skip
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <View style={[{ width: screenWidth * 0.4 }]}>
                        <Arrow
                          colorLarge='rgba(255, 255, 255, 0.6)'
                          colorSmall='rgba(255, 255, 255, 0.4)'
                          colorDot='#FFF'
                        />
                      </View>
                      <View style={tw`flex-col items-center justify-center`}>
                        <TouchableOpacity
                          onPress={() => handleClick(GameTimes.TimeMin)}
                          disabled={disabled}
                          activeOpacity={1}
                        >
                          <Animated.View
                            style={[
                              {
                                backgroundColor: interpolatedColorRight,
                                borderColor: interpolatedBorderColorRight,
                                transform: [{ scale: scaleValueRight }],
                              },
                              tw`w-18 h-18 rounded-full items-center justify-center border-[0.5px]`,
                            ]}
                          >
                            <SystemIcon
                              type='SAX'
                              name='Teacher'
                              color='#FFF'
                            />
                          </Animated.View>
                          <View style={tw`pt-2 items-center`}>
                            <Text
                              specialType='Title'
                              color='#FFF'
                              textAlign='center'
                            >
                              Learn
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </ScrollView>
              ) : (
                <View style={tw`flex-1 flex-col justify-center items-center`}>
                  <View
                    style={tw`self-center bg-white rounded-full w-30 h-30`}
                  />
                  <View
                    style={tw`absolute top-0 left-0 right-0 bottom-0 justify-center items-center`}
                  >
                    <SystemIcon
                      type='SAX'
                      name='TickCircle'
                      color={tw.color('success-400')}
                      size={136}
                    />
                  </View>
                </View>
              )}
            </View>
          )}
          {!S.shared.isTipsLearned && <Tips />}
        </View>
      </View>
    </LinearGradientColor>
  )
})
