import type { FC } from 'react'
import { useMemo, useState } from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { Image } from '#components/base/Image'
import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useSearchSplash } from '#graphql/codegen'
import { replace } from '#navigator/helpers'
import type { AuthStackScreenProps } from '#navigator/types'
import type { SplashItem } from '#types/splash'

type IWelcomeProps = AuthStackScreenProps<'Welcome'>

export const Welcome: FC<IWelcomeProps> = () => {
  const { width } = useWindowDimensions()
  const maxWidth = width - 92
  const maxWidth1 = maxWidth * 0.8
  const [index, setIndex] = useState<number>(0)

  const [splash] = useSearchSplash({
    variables: {
      order: ['createdAt_asc'],
    },
  })

  const data: SplashItem[] = useMemo(
    () =>
      (splash.data?.searchSplash || []).length > 0
        ? (splash.data?.searchSplash || []).map(i => ({
            ...i,
            title: i.title,
            subTitle: i.subTitle,
            url: i.thumbnail?.url || '',
          }))
        : DATA_DEFAULT,
    [JSON.stringify(splash.data?.searchSplash || [])],
  )

  const item: SplashItem = useMemo(
    () => data[index],
    [JSON.stringify(data), index],
  )

  const preItem: SplashItem = useMemo(
    () => (!index ? data[data.length - 1] : data[index - 1]),
    [JSON.stringify(data), index],
  )

  const nextItem: SplashItem = useMemo(
    () => (index === data.length - 1 ? data[0] : data[index + 1]),
    [JSON.stringify(data), index],
  )

  const redirect = () => {
    replace('Auth', { screen: 'Login' })
  }

  const onPressButton = () => {
    if (index === data.length - 1) {
      redirect()
    } else {
      setIndex(pre => pre + 1)
    }
  }

  return (
    <View style={tw`flex-1 flex-col`}>
      <LinearGradientColor />
      <View
        style={tw`flex-1 flex-col pb-4 pt-6 absolute top-0 left-0 right-0 bottom-0`}
      >
        <View style={tw.style('flex-col items-end pb-6 px-4')}>
          <TouchableOpacity
            disabled={index === data.length - 1}
            onPress={redirect}
          >
            <Text specialType='Title' color={tw.color('text-4')}>
              {index === data.length - 1 ? '' : 'Skip'}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={tw.style('flex-1 flex-col justify-center')}>
          <View
            style={tw.style(
              'overflow-hidden flex-row items-center justify-center',
              { flex: 0.65 },
            )}
          >
            <View style={tw`rounded-full`}>
              <Image
                source={
                  typeof preItem.url === 'number'
                    ? preItem.url
                    : { uri: preItem.url }
                }
                style={tw.style('rounded-3xl', {
                  width: maxWidth1,
                  height: maxWidth1 + 33,
                })}
                resizeMode='cover'
              />
              <View
                style={tw.style(
                  'absolute top-0 left-0 right-0 bottom-0 opacity-60 bg-black rounded-3xl',
                )}
              />
            </View>
            <View
              style={tw.style('mx-4 rounded-3xl overflow-hidden', {
                width: maxWidth,
                height: maxWidth + 33,
              })}
            >
              <Image
                source={
                  typeof item.url === 'number' ? item.url : { uri: item.url }
                }
                style={tw.style('rounded-3xl', {
                  width: maxWidth,
                  height: maxWidth + 33,
                })}
                resizeMode='cover'
              />
            </View>
            <View style={tw`rounded-full`}>
              <Image
                source={
                  typeof nextItem.url === 'number'
                    ? nextItem.url
                    : { uri: nextItem.url }
                }
                style={tw.style('rounded-3xl', {
                  width: maxWidth1,
                  height: maxWidth1 + 33,
                })}
                resizeMode='cover'
              />
              <View
                style={tw.style(
                  'absolute top-0 left-0 right-0 bottom-0 opacity-60 bg-black rounded-3xl',
                )}
              />
            </View>
          </View>
          <View style={tw.style('px-6 mt-6', { flex: 0.35 })}>
            <View style={tw.style('flex-row items-center justify-center')}>
              {data.map((_item, idx) => (
                <View
                  style={tw.style(
                    'h-2 rounded-full mx-0.5',
                    idx === index
                      ? 'w-8 bg-background-light-white'
                      : 'w-4 bg-separation',
                  )}
                  key={idx}
                />
              ))}
            </View>
            <View style={tw.style('gap-3 mt-4 flex-1 flex-col')}>
              <Text
                specialType='Headline1'
                // textAlign='center'
                color={tw.color('text-4')}
                numberOfLines={2}
              >
                {item.title}
              </Text>
              <Text
                specialType='Subtitle'
                // textAlign='center'
                color={tw.color('text-4')}
                numberOfLines={3}
              >
                {item.subTitle}
              </Text>
            </View>
          </View>
        </View>
        <View style={tw.style('w-full px-4')}>
          <Button tone='secondary' onPress={onPressButton}>
            {index === data.length - 1 ? "Let's start" : 'Continue'}
          </Button>
        </View>
      </View>
    </View>
  )
}

const DATA_DEFAULT: SplashItem[] = [
  {
    title: 'Welcome to Cody',
    subTitle:
      'Start your English learning journey with lessons, role-playing, and vocabulary review',
    url: images.welcome,
  },
  {
    title: 'Build Vocabulary \nwith Games',
    subTitle:
      'Play interactive games to boost your vocabulary, from choosing images to fill-in-the-bla',
    url: images.vocabulary,
  },
  {
    title: 'Practice Speaking \nwith AI',
    subTitle:
      'Improve your speaking skills by role-playing real-life scenarios with AI',
    url: images.speaking,
  },
]
