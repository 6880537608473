import { View } from 'react-native'
import type { Style } from 'twrnc'

import { Text } from '#components/base/Text'
import { useDarkColor } from '#components/dark/hooks'
import { tw } from '#components/utils/tw'

export type DividerProps = {
  dashed?: boolean
  orientation?: 'vertical' | 'horizontal'
  style?: Style
}

export type DividerWithTextProps = DividerProps & {
  containerStyle?: Style
  textStyle?: Style
  text?: 'or' | 'and' | (string & {})
}

const orientationClasses = {
  vertical: 'w-px h-full border-r-[1px]',
  horizontal: 'w-full h-px border-t-[1px]',
}

const Divider = ({
  dashed,
  orientation = 'horizontal',
  style,
}: DividerProps) => {
  const darkLight = useDarkColor()
  return (
    <View
      style={tw.style(
        `flex-1 border-[${darkLight('gray-600', 'gray-200')}]`,
        orientationClasses[orientation],
        dashed && 'border-dashed',
        style,
      )}
    />
  )
}

const WithText = ({
  containerStyle,
  text,
  textStyle,
  orientation = 'horizontal',
  ...props
}: DividerWithTextProps) => (
  <View
    style={tw.style(
      'justify-center items-center gap-x-4 gap-y-1',
      orientation === 'horizontal' && 'flex-row',
      orientation === 'vertical' && 'flex-col',
      containerStyle,
    )}
  >
    <Divider orientation={orientation} {...props} />
    <Text style={tw.style('text-gray-500 leading-relaxed text-sm', textStyle)}>
      {text}
    </Text>
    <Divider orientation={orientation} {...props} />
  </View>
)

Divider.WithText = WithText
export { Divider }
