import { ulid } from 'ulidx'

import { isValidAudioUrl, isValidImageUrl } from '#components/utils/checkMedia'
import { gql } from '#graphql/urql'
import type {
  AnswerGame,
  GamesProps,
  GameType,
  GenerateProps,
  QuestionGame,
  WordItem,
} from '#types/games'
import { HardGame, phraseGameLabel, wordGameLabel } from '#types/games'
import type { LevelTestContainer, LevelTestItem } from '#types/levelTest'
import type { QuesTionType } from '#types/question'

import { generateCorrectPhrase } from './correctPhrase'
import { generateCorrectTranslation } from './correctTranslation'
import { getItemInList } from './getItemInList'
import { generateHearAudio } from './hearAudio'
import { generateHearVideo } from './hearVideo'
import { generateRearrangement } from './rearrangement'
import { generateSameOrDifferent } from './sameOrDifferent'
import { generateSelectImage } from './selectImage'

type GenerateGames = {
  item: LevelTestItem
}

type GameFunctions = (props: GenerateProps) => Promise<GamesProps>

export const fetchGameLevelTest = async ({
  item,
}: GenerateGames): Promise<LevelTestContainer> => {
  const respWord = await gql.searchWord(
    {
      filter: {
        topic_some: {
          level: item.level,
          languageTopic: item.language,
          status: 'Active',
        },
        isVocabulary: item.type === 'word',
      },
      page: { limit: 1, offset: item.position },
    },
    { requestPolicy: 'network-only' },
  )
  const wordData = respWord.data?.searchWord || []

  const randomType = getItemInList(
    item.type === 'phrase' ? phraseGameLabel : wordGameLabel,
  )
  if (!wordData.length || !randomType) {
    const question: QuestionGame = {
      index: 0,
      media: null,
      text: '',
      translation: '',
      type: null,
    }
    return { ...item, question, answers: [] }
  }
  const word = wordData[0]

  const respTopics = await gql.searchTopic({
    filter: { id: word.topicId },
  })
  const topicData = respTopics.data?.searchTopic || []
  const topic = topicData.length > 0 ? topicData[0] : undefined

  const respWords = await gql.searchWord(
    {
      filter: {
        topicId: word.topicId,
        isVocabulary: word.isVocabulary,
      },
    },
    { requestPolicy: 'network-only' },
  )
  const wordsId = (respWords.data?.searchWord || []).map(w => w.id)

  const resourceWord = await gql.searchResourceInWord(
    {
      filter: { wordId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const media = resourceWord.data?.searchResourceInWord || []

  const respTranslate = await gql.searchTranslate(
    {
      filter: { wQAId_in: wordsId },
    },
    { requestPolicy: 'network-only' },
  )
  const translate = respTranslate.data?.searchTranslate || []

  const wordsData: WordItem[] = (respWords.data?.searchWord || []).map(w => {
    const m = media.filter(i => i.wordId === w.id)
    const t = translate.filter(i => i.wQAId === w.id)
    return { ...w, media: m, translation: t }
  })

  const type: QuesTionType = randomType.value
  const params: GenerateProps = {
    item: {
      media: media.filter(i => i.wordId === word.id),
      text: word.vocabAndPhrase,
      wordId: word.id,
      translation: translate.filter(i => i.wQAId === word.id),
    },
    data: wordsData,
    language: item.language,
    isPhrase: item.type === 'phrase',
  }
  const gamesFunc: { [key in GameType]: GameFunctions } = {
    sameOrDifferent: generateSameOrDifferent,
    selectImage: generateSelectImage,
    hearAudio: generateHearAudio,
    hearVideo: generateHearVideo,
    correctTranslation: generateCorrectTranslation,
    rearrangement: generateRearrangement,
    correctPhrase: generateCorrectPhrase,
    fillTheBlank: generateFillTheBlank,
    completeTheDialogue: generateCompleteTheDialogue,
  }
  const resultGame = await gamesFunc[type](params)

  return {
    ...item,
    topic,
    question: resultGame.question,
    answers: resultGame.answers,
    translation: translate.filter(i => i.wQAId === word.id),
  }
}

type AnswerChat = {
  text: string
  isCorrect: boolean
}

type QuestionDialogue = {
  question: { index: number; text: string }
  answers: AnswerChat[]
}

export const generateCompleteTheDialogue = async ({
  language,
  item,
}: GenerateProps): Promise<GamesProps> => {
  const resp = await gql.generateCompleteTheDialogue({
    content: item.text,
    language,
  })
  const dataStr =
    resp.data?.generateCompleteTheDialogue ||
    '{"question": {"index":0,"text": ""}, "answers": []}'
  const data: QuestionDialogue = JSON.parse(dataStr)
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const question: QuestionGame = {
    text: data.question.text || '',
    media: getItemInList(audios)?.media || null,
    type: HardGame.completeTheDialogue,
    translation: '',
    index: 0,
  }
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const answers: AnswerGame[] = data.answers.map(i => ({
    text: i.isCorrect ? item.text : i.text || '',
    isCorrect: i.isCorrect,
    audio: getItemInList(audios)?.media || null,
    image: getItemInList(images)?.media || null,
    translation: '',
    type: HardGame.completeTheDialogue,
    id: ulid(),
    answer: item.text || '',
  }))
  return { question, answers }
}

type QuestionFill = {
  question: string
  answers: AnswerChat[]
}

export const generateFillTheBlank = async ({
  language,
  isPhrase,
  item,
}: GenerateProps): Promise<GamesProps> => {
  const resp = await gql.generateFillTheBlank({
    content: item.text,
    isPhrase,
    language,
  })
  const dataStr =
    resp.data?.generateFillTheBlank || '{"question": "", "answers": []}'
  const data: QuestionFill = JSON.parse(dataStr)
  const audios = item.media.filter(i => isValidAudioUrl(i.media?.url || ''))
  const questionData: QuestionGame = {
    text: data.question || item.text,
    media: getItemInList(audios)?.media || null,
    type: HardGame.fillTheBlank,
    translation: '',
    index: 0,
  }
  const images = item.media.filter(i => isValidImageUrl(i.media?.url || ''))
  const answers: AnswerGame[] = !data
    ? []
    : data.answers.map(i => ({
        text: i.text || '',
        isCorrect: i.isCorrect,
        audio: getItemInList(audios)?.media || null,
        image: getItemInList(images)?.media || null,
        translation: '',
        type: HardGame.fillTheBlank,
        id: ulid(),
        answer: item.text || '',
      }))
  return { question: questionData, answers }
}
