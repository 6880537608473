import {
  DeleteFilled,
  PlayCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { Button, Flex, Image, Typography, Upload as UploadAntd } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import { useEffect, useState } from 'react'

import { uploadFile } from '#apis/files'
import { tw } from '#components/utils/tw'
import type { ResourceType, ResponseFile, UploadFileProps } from '#types/media'

import { ImageDefault } from '../svg/ImageDefault'
import { checkAudio } from '../utils/checkAudio'
import { checkImage } from '../utils/checkImage'
import { checkVideo } from '../utils/checkVideo'
import { getUrlToFile } from '../utils/getUrlToFile'
import { toastError, toastSuccess } from '../utils/Toast'
import { ButtonUpLoad } from './ButtonUpLoad'
import { Label } from './Label'

interface UpdateProps {
  type: 'video' | 'image' | 'audio' | 'avatar'
  resourceType: ResourceType
  required?: boolean
  display: 'image' | 'audio' | 'voice' | 'pictureCard'
  url?: string
  name?: string
  onChange?: (data: ResponseFile) => void
  onDeleted?: (file: UploadFileProps) => void
  onFileUploading?: (isLoadding: boolean) => void
  setLoadingUpload?: (value: boolean) => void
  disabled?: boolean
  isHiddenLabel?: boolean
  isHiddenImage?: boolean
}

const funcCheck: {
  [key in UpdateProps['type']]: (file: UploadFileProps) => boolean
} = {
  video: checkVideo,
  image: checkImage,
  avatar: checkImage,
  audio: checkAudio,
}

export const Upload = ({
  url,
  required,
  type,
  display,
  resourceType,
  onChange,
  onDeleted,
  name,
  onFileUploading,
  disabled,
  isHiddenLabel,
  setLoadingUpload,
  isHiddenImage,
}: UpdateProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImageUrl] = useState('')
  const [file, setFile] = useState<UploadFileProps>()

  useEffect(() => {
    if (url !== file?.url) {
      if (url) {
        const urlToFile = getUrlToFile({ url, name })
        setFile(urlToFile)
      } else {
        setFile(undefined)
      }
    }
  }, [url, name])

  const handleChange = async ({
    fileList,
  }: UploadChangeParam<UploadFileProps>) => {
    const i = fileList[fileList.length - 1]
    if (i && funcCheck[type](i)) {
      try {
        onFileUploading?.(true)
        setLoading(true)
        setLoadingUpload && setLoadingUpload(true)
        onFileUploading?.(true)
        if (i.originFileObj) {
          const response = await uploadFile(i.originFileObj, resourceType)
          const uploadedFile: UploadFileProps = {
            ...i,
            url: response.url,
            status: 'done',
          }
          setFile(uploadedFile)
          onChange?.(response)
          toastSuccess({ message: 'Uploaded file successfully.' })
        }
      } catch (error) {
        toastError({ message: 'Failed to upload file' })
      } finally {
        setLoading(false)
        setLoadingUpload && setLoadingUpload(false)
        onFileUploading?.(false)
      }
    }
  }

  const handleDelete = () => {
    setFile(undefined)
    file && onDeleted?.(file)
  }

  const handlePreview = (uploadedFile: UploadFileProps) => {
    setPreviewImageUrl(uploadedFile.url || '')
    setPreviewOpen(true)
  }

  const setPreviewImage = (visible: boolean) => {
    setPreviewOpen(visible)
    if (!visible) {
      setPreviewImageUrl('')
    }
  }

  const componentMap: { [key in UpdateProps['display']]: JSX.Element } = {
    image: (
      <ImageComp
        type={type}
        loading={loading}
        file={file}
        handleChange={handleChange}
        disabled={disabled}
        isHiddenImage={isHiddenImage}
      />
    ),
    audio: (
      <AudioComp
        type={type}
        loading={loading}
        file={file}
        handleChange={handleChange}
        handleDelete={handleDelete}
        disabled={disabled}
        isHiddenLabel={isHiddenLabel}
      />
    ),
    voice: (
      <VoiceComp
        type={type}
        loading={loading}
        file={file}
        handleChange={handleChange}
        handleDelete={handleDelete}
        required={required}
        disabled={disabled}
      />
    ),
    pictureCard: (
      <PictureCardComp
        type={type}
        loading={loading}
        file={file}
        handleChange={handleChange}
        handleDelete={handleDelete}
        handlePreview={handlePreview}
        required={required}
        isPreview={previewOpen}
        previewImage={previewImage}
        setPreview={setPreviewImage}
        disabled={disabled}
      />
    ),
  }

  return componentMap[display]
}

const toUpperCaseFirst = (str: string): string => {
  if (!str) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

type CompProps = {
  type: UpdateProps['type']
  file?: UploadFileProps
  loading: boolean
  handleChange: (data: UploadChangeParam<UploadFileProps>) => void
  disabled?: boolean
}

type ImageCompProps = CompProps & { isHiddenImage?: boolean }

const ImageComp = ({
  file,
  loading,
  handleChange,
  type,
  disabled,
  isHiddenImage,
}: ImageCompProps) => (
  <Flex gap={8} style={tw`items-center`}>
    {!isHiddenImage && (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!file?.url ? (
          <Button
            type='dashed'
            disabled
            style={tw.style('w-25 h-25 border bg-transparent', {
              cursor: 'default',
            })}
          >
            <ImageDefault />
          </Button>
        ) : (
          <Image
            style={tw`rounded-2`}
            width={100}
            height={100}
            src={file.url}
          />
        )}
      </>
    )}
    <UploadAntd
      beforeUpload={() => false}
      fileList={file ? [file] : []}
      onChange={handleChange}
      type='select'
      showUploadList={false}
      disabled={disabled || loading}
    >
      <Button
        loading={loading}
        disabled={disabled}
        icon={<UploadOutlined />}
        type='dashed'
      >
        {`Upload ${type}`}
      </Button>
    </UploadAntd>
  </Flex>
)

type AudioCompProps = CompProps & {
  handleDelete: () => void
  required?: boolean
  isHiddenLabel?: boolean
}

const AudioComp = ({
  file,
  loading,
  handleChange,
  type,
  handleDelete,
  disabled,
  isHiddenLabel,
}: AudioCompProps) => (
  <Flex gap={8} style={tw`items-center`}>
    <UploadAntd
      beforeUpload={() => false}
      fileList={file ? [file] : []}
      onChange={handleChange}
      type='select'
      showUploadList={false}
      disabled={disabled}
    >
      <Button
        icon={<UploadOutlined />}
        loading={loading}
        style={tw`w-full`}
        disabled={disabled || loading}
      >
        {`Upload ${type}`}
      </Button>
    </UploadAntd>
    {!isHiddenLabel && file && (
      <Flex gap={8} style={tw`items-center`}>
        <Typography.Link
          onClick={() => {
            file.url && window.open(file?.url)
          }}
          style={tw`mr-2 cursor-pointer text-blue-500`}
        >
          {file?.name ? file?.name : ''}
        </Typography.Link>
        <Button
          icon={<DeleteFilled style={{ color: 'red' }} />}
          disabled={disabled || loading}
          type='text'
          onClick={handleDelete}
          style={tw`w-10`}
        />
      </Flex>
    )}
  </Flex>
)

type VoiceCompProps = CompProps & {
  handleDelete: () => void
  required?: boolean
}

const VoiceComp = ({
  type,
  file,
  loading,
  handleChange,
  required,
  handleDelete,
  disabled,
}: VoiceCompProps) => (
  <Flex vertical style={tw`justify-center items-center flex-col`}>
    <UploadAntd
      beforeUpload={() => false}
      onChange={handleChange}
      onRemove={handleDelete}
      listType='picture-card'
      fileList={file ? [file] : []}
      iconRender={() => <PlayCircleOutlined />}
      style={tw`w-full h-full`}
      disabled={disabled}
    >
      {!file && (
        <ButtonUpLoad
          loading={loading}
          title={`Upload ${type}`}
          required={required}
        />
      )}
    </UploadAntd>
    {file && <Label label={toUpperCaseFirst(type)} required={required} />}
  </Flex>
)

type PictureCardCompProps = CompProps & {
  required?: boolean
  handleDelete: () => void
  handlePreview: (file: UploadFileProps) => void
  isPreview: boolean
  previewImage: string
  setPreview: (visible: boolean) => void
}

const PictureCardComp = ({
  type,
  file,
  loading,
  handleChange,
  required,
  handleDelete,
  handlePreview,
  isPreview,
  previewImage,
  setPreview,
  disabled,
}: PictureCardCompProps) => (
  <Flex vertical>
    <Flex style={tw`justify-center items-center flex-col`}>
      <UploadAntd
        beforeUpload={() => false}
        listType='picture-card'
        fileList={file ? [file] : []}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleDelete}
        style={tw`w-full h-full`}
        disabled={disabled}
      >
        {!file && (
          <ButtonUpLoad
            loading={loading}
            title={`Upload ${type}`}
            required={required}
          />
        )}
      </UploadAntd>
      {file && <Label label={toUpperCaseFirst(type)} required={required} />}
    </Flex>
    {previewImage && (
      <Image
        style={tw`w-100 h-100`}
        wrapperStyle={{ display: 'none' }}
        preview={{
          visible: isPreview,
          onVisibleChange: visible => setPreview(visible),
          afterOpenChange: visible => setPreview(visible),
        }}
        src={previewImage}
      />
    )}
  </Flex>
)
