import type { GameType, ItemGameType } from '#types/games'
import { GameTimes } from '#types/games'
import type { LanguageType, Level } from '#types/language'
import type {
  LevelTestItem,
  LevelTestType,
  RandomLevelTest,
  TotalsType,
} from '#types/levelTest'
import { levelTestCount } from '#types/levelTest'
import type { SearchWordInUser } from '#types/wordInUser'

export const getRandomList = <T extends any>(arr: T[], n: number = 5): T[] => {
  if (n <= 0 || arr.length === 0) {
    return []
  }

  if (n >= arr.length) {
    return arr
  }

  const result: T[] = []
  const copy: T[] = [...arr]

  while (result.length < n) {
    const randomIndex = Math.floor(Math.random() * copy.length)
    const [item] = copy.splice(randomIndex, 1)
    result.push(item)
  }

  return result
}

export type ElementWithCount<T> = {
  item: T
  count: number
}

type RandomListWithMaxOccurrences<T> = {
  list: T[]
  maxItems?: number
  maxOccurrences?: number
}

export const getRandomListWithMaxOccurrences = <T extends { times: number }>({
  list,
  maxItems = 10,
  maxOccurrences = 7,
}: RandomListWithMaxOccurrences<T>): T[] => {
  if (maxItems <= 0) {
    return []
  }

  const data: T[] = list.map(item => ({ ...item }))
  const copy: T[] = list.map(item => ({ ...item }))
  const result: T[] = []

  while (result.length < maxItems && copy.length > 0) {
    const randomIndex = Math.floor(Math.random() * copy.length)
    const randomElement = copy[randomIndex]

    // Add the element to the result array if it hasn't reached the max occurrences
    if (randomElement.times < maxOccurrences) {
      result.push(data[randomIndex]) // Copy the item to avoid modifying the original
      randomElement.times++
    }

    // Remove the element from the copy array and the original array if it has reached max occurrences
    if (randomElement.times >= maxOccurrences) {
      copy.splice(randomIndex, 1)
      data.splice(randomIndex, 1)
    }
  }

  return result
}

export const randomSort = (): number => Math.random() - 0.5

export const shuffleArray = <T>(array: T[]): T[] => {
  const newArray: T[] = [...array]

  // Fisher-Yates Shuffle Algorithm
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)) // Random index
    ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]] // Swap elements
  }
  return newArray
}

type ResultQuestion<T extends object> = T | null

export const generateRandomQuestion = <T extends object>(
  question: T[],
): ResultQuestion<T> => {
  if (!question.length) {
    return null
  }
  const questionIndex = Math.floor(Math.random() * question.length)
  return question[questionIndex]
}

type GenerateRandomAnswersParams<T extends object> = {
  correctAnswers: T[]
  incorrectAnswers: T[]
  numCorrect?: number
  numInCorrect?: number
  correctAnswerIndex: number
}

export const generateRandomAnswerPositions = (
  numberOfQuestions: number,
): number[] => {
  const maxCount = Math.ceil(numberOfQuestions / 4)
  const result: number[] = []
  const counts = { 0: 0, 1: 0, 2: 0, 3: 0 }

  while (result.length < numberOfQuestions) {
    const randomValue = Math.floor(Math.random() * 4)
    if (counts[randomValue] < maxCount) {
      result.push(randomValue)
      counts[randomValue]++
    }
  }

  return result
}

export const generateRandomAnswers = <T extends object>({
  correctAnswers,
  incorrectAnswers,
  numInCorrect = 3,
  correctAnswerIndex,
}: GenerateRandomAnswersParams<T>): T[] => {
  const incorrectCount = Math.min(numInCorrect, incorrectAnswers.length)
  if (!incorrectAnswers.length) {
    return correctAnswers
  }

  if (correctAnswerIndex > incorrectCount) {
    correctAnswerIndex = Math.floor(Math.random() * incorrectCount)
  }

  const shuffledIncorrectAnswers = shuffleArray(incorrectAnswers).slice(
    0,
    incorrectCount,
  )

  const result: T[] = []

  for (let i = 0; i < incorrectCount + 1; i++) {
    if (i === correctAnswerIndex) {
      result.push(correctAnswers[0])
    } else {
      result.push(shuffledIncorrectAnswers.pop() as T)
    }
  }

  return result
}

type RandomGameType = { level: number; isPhrase: boolean; type?: GameType }

type RandomGameItem = { difficulty: 'easy' | 'hard'; name: GameType }

export const getRandomGame = ({
  level,
  isPhrase,
  type,
}: RandomGameType): GameType => {
  const baseGames: RandomGameItem[] = [
    { difficulty: 'easy', name: 'sameOrDifferent' },
    { difficulty: 'easy', name: 'selectImage' },
    { difficulty: 'easy', name: 'hearAudio' },
    { difficulty: 'easy', name: 'hearVideo' },
    { difficulty: 'easy', name: 'correctTranslation' },
    { difficulty: 'hard', name: 'fillTheBlank' },
  ]

  const phraseGames: RandomGameItem[] = [
    ...baseGames,
    { difficulty: 'easy', name: 'rearrangement' },
    { difficulty: 'easy', name: 'correctPhrase' },
    { difficulty: 'hard', name: 'completeTheDialogue' },
  ]

  const games: RandomGameItem[] = isPhrase ? phraseGames : baseGames

  // Filter games based on level and difficulty
  const filteredGames = games.filter(game => {
    if (level < GameTimes.TimeHard) {
      return game.difficulty === 'easy' && game.name !== type
    } else {
      return game.difficulty === 'hard'
    }
  })

  // const filteredGames = games.filter(game => game.name === 'correctTranslation')

  // Randomly select a game from filtered games
  const randomIndex = Math.floor(Math.random() * filteredGames.length)
  const data = shuffleArray(filteredGames)
  return data[randomIndex].name
}

export const getRandomGames = (
  data: SearchWordInUser[],
  isPhrase: boolean,
): ItemGameType[] => {
  if (!data.length) {
    return []
  }
  const games: ItemGameType[] = []
  let lastEasyGame: GameType | undefined = undefined
  for (let i = 0; i < data.length; i++) {
    const item = data[i]

    const easyGame = getRandomGame({
      level: GameTimes.TimeMin,
      isPhrase,
      type: lastEasyGame,
    })

    const hardGame = getRandomGame({
      level: GameTimes.TimeHard,
      isPhrase,
    })

    games.push({ ...item, easyGame, hardGame })

    lastEasyGame = easyGame
  }
  return games
}

// Level Test

export const getRandomIndex = ({
  type,
  totalListen,
  totalWords,
  totalPhrases,
}: RandomLevelTest): number => {
  const totals: { [key in LevelTestType]: number } = {
    word: totalWords,
    phrase: totalPhrases,
    listen: totalListen,
  }
  const total = totals[type]
  return total ? Math.floor(Math.random() * total) : 0
}

type LevelTestProps = TotalsType & {
  level: Level
  language: LanguageType
}

type ResultLevelTestData = LevelTestItem[]

export const getLevelTest = ({
  level,
  totalListen,
  totalWords,
  totalPhrases,
  language,
}: LevelTestProps): ResultLevelTestData => {
  const types: LevelTestType[] = ['word', 'phrase', 'listen']
  const itemsPerType: number = levelTestCount[level]
  const result: LevelTestItem[] = []
  const positionsMap = {
    word: Array.from({ length: totalWords }, (_, i) => i),
    phrase: Array.from({ length: totalPhrases }, (_, i) => i),
    listen: Array.from({ length: totalListen }, (_, i) => i),
  }
  for (let i = 0; i < types.length * itemsPerType; i++) {
    const type = types[Math.floor(i / itemsPerType)]
    const positions = positionsMap[type]
    // Select a random position from the available positions
    const randomIndex = Math.floor(Math.random() * positions.length)
    const position = positions[randomIndex]
    // Add the selected item to the result
    result.push({ type, position, level, language, translation: [] })
    // Remove the selected position to ensure uniqueness
    positions.splice(randomIndex, 1)
  }
  return shuffleArray(result)
}
