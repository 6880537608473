import type { FC } from 'react'
import { memo, useEffect, useRef, useState } from 'react'
import { Animated, View } from 'react-native'

import { Image } from '#components/base/Image'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type Props = {
  text?: string
  imageUrl?: string
  isCorrect: boolean
  audioUrl: string | null
  onChangeIndex: () => void
  onCloseView: () => void
  isLast: boolean
}

export const ReviewAnswer: FC<Props> = memo(
  ({
    text,
    imageUrl,
    isCorrect = true,
    audioUrl,
    onChangeIndex,
    onCloseView,
    isLast,
  }) => {
    const backgroundScale = useRef(new Animated.Value(0)).current
    const backgroundScaleOpacity = useRef(new Animated.Value(0)).current

    const checkmarkScale = useRef(new Animated.Value(0)).current
    const imageScale = useRef(new Animated.Value(0)).current
    const imageScaleOpacity = useRef(new Animated.Value(1)).current
    const playAudio = useRef<HTMLAudioElement>(new Audio(audioUrl ?? ''))
    const bgPrimaryValue = useRef(new Animated.Value(0)).current
    const bgSecondaryValue = useRef(new Animated.Value(0)).current

    const xValue = useRef(new Animated.Value(0)).current

    const checkValue1 = useRef(new Animated.Value(0)).current
    const checkValue2 = useRef(new Animated.Value(0)).current

    const endValue = useRef(new Animated.Value(1)).current

    const [changeBackground, setChangeBackground] = useState<boolean>(false)

    const onPlayAudio = () => {
      if (audioUrl) {
        playAudio.current.src = audioUrl
        playAudio.current?.load()
        playAudio.current?.pause()
        playAudio.current.currentTime = 0
        playAudio.current?.play()
      }
    }

    const onPauseAudio = () => {
      playAudio.current?.pause()
      playAudio.current.currentTime = 0
    }

    useEffect(() => {
      const startAnimation = () => {
        Animated.parallel([
          animateTiming(backgroundScale, 1, 250),
          animateTiming(backgroundScaleOpacity, 1, 500),
        ]).start(() => {
          Animated.sequence([
            animateTiming(bgPrimaryValue, 1, 150),
            animateTiming(bgPrimaryValue, 0.8, 150),
            animateTiming(bgPrimaryValue, 1, 150),
            animateTiming(bgSecondaryValue, 1, 100),
            animateTiming(bgSecondaryValue, 0.8, 100),
            animateTiming(bgSecondaryValue, 1, 100),
            isCorrect
              ? Animated.sequence([
                  animateTiming(checkValue1, 1, 125, false),
                  animateTiming(checkValue2, 1, 175, false),
                ])
              : Animated.sequence([
                  animateTiming(xValue, 1, 100),
                  animateTiming(xValue, 0.8, 100),
                  animateTiming(xValue, 1, 100),
                ]),
            animateTiming(endValue, 0, 1000),
          ]).start(() => {
            setChangeBackground(true)
            onPlayAudio()
            Animated.sequence([
              animateTiming(imageScale, 1, 500),
              animateTiming(backgroundScale, 1, 500),
              animateTiming(imageScale, 1, 500),
            ]).start(() => {
              const timeOut = setTimeout(() => {
                if (!isLast) {
                  onChangeIndex()
                }
                Animated.sequence([
                  animateTiming(imageScaleOpacity, 0, 500),
                  animateTiming(backgroundScaleOpacity, 0, 250),
                ]).start(() => {
                  onPauseAudio()
                  onCloseView()
                  if (isLast) {
                    onChangeIndex()
                  }
                  clearTimeout(timeOut)
                })
              }, 1000)
            })
          })
        })
      }

      startAnimation()
    }, [
      checkmarkScale,
      imageScale,
      backgroundScale,
      audioUrl,
      onChangeIndex,
      onCloseView,
      isLast,
    ])

    const animateTiming = (
      animatedValue: Animated.Value,
      toValue: number,
      duration: number,
      useNativeDriver: boolean = true,
    ) =>
      Animated.timing(animatedValue, {
        toValue,
        duration,
        useNativeDriver,
      })

    const widthInterpolation1 = checkValue1.interpolate({
      inputRange: [0, 1],
      outputRange: ['0%', '100%'],
    })

    const widthInterpolation2 = checkValue2.interpolate({
      inputRange: [0, 1],
      outputRange: ['0%', '100%'],
    })

    return (
      <View
        style={tw.style(
          'absolute inset-0 bg-background-light-1 justify-center items-center',
        )}
      >
        <Animated.View
          style={[
            tw.style('absolute inset-0 justify-center items-center'),
            {
              backgroundColor: tw.color(
                changeBackground
                  ? 'background-light'
                  : isCorrect
                    ? 'success-50'
                    : 'error-50',
              ),
              transform: [{ scale: backgroundScale }],
              opacity: backgroundScaleOpacity,
            },
          ]}
        >
          <Animated.View
            style={[
              tw`flex-1 justify-center items-center`,
              { transform: [{ scale: endValue }], opacity: endValue },
            ]}
          >
            <Animated.View
              style={[
                tw.style('w-50 h-50 justify-center items-center rounded-full', {
                  backgroundColor: isCorrect
                    ? tw.color('success-75')
                    : tw.color('error-100'),
                  transform: [{ scale: bgPrimaryValue }],
                  opacity: bgPrimaryValue,
                }),
              ]}
            >
              <Animated.View
                style={[
                  tw.style(
                    'w-40 h-40 justify-center items-center rounded-full',
                  ),
                  {
                    backgroundColor: isCorrect
                      ? tw.color('success-300')
                      : tw.color('error-300'),
                    transform: [{ scale: bgSecondaryValue }],
                    opacity: bgSecondaryValue,
                  },
                ]}
              />
            </Animated.View>
            <Animated.View
              style={[
                tw`w-24 h-24 justify-center items-center absolute`,
                { transform: [{ scale: xValue }] },
              ]}
            >
              <View
                style={tw.style('flex-1 w-18 h-18 absolute', {
                  transform: [{ rotate: '45deg' }],
                })}
              >
                <View
                  style={tw.style(
                    'bg-white w-2.5 h-18 position-1 -right-7.75 rounded-full',
                  )}
                />
                <View
                  style={tw.style(
                    'bg-white w-18 h-2.5 position-2 -top-10.25 rounded-full',
                  )}
                />
              </View>
            </Animated.View>
            <View style={tw`flex-row flex-1 absolute`}>
              <View
                style={[
                  tw`w-10 h-2.5 justify-center items-center overflow-hidden`,
                  { transform: [{ rotate: '45deg' }], top: 12.5, right: -16.5 },
                ]}
              >
                <Animated.View
                  style={[
                    tw`absolute left-0 h-full bg-white rounded-full `,
                    { width: widthInterpolation1 },
                  ]}
                />
              </View>
              <View
                style={[
                  tw`w-20 h-2.5 justify-center items-center overflow-hidden`,
                  {
                    transform: [{ rotate: '-45deg' }],
                    top: 0,
                    right: 7.5,
                  },
                ]}
              >
                <Animated.View
                  style={[
                    tw`absolute left-0 h-full bg-white rounded-full`,
                    { width: widthInterpolation2 },
                  ]}
                />
              </View>
            </View>
          </Animated.View>
        </Animated.View>
        <Animated.View
          style={[
            tw.style('absolute flex-1 inset-0 justify-center items-center'),
            {
              transform: [{ scale: imageScale }],
              opacity: imageScaleOpacity,
            },
          ]}
        >
          {imageUrl && (
            <Image
              source={{ uri: imageUrl }}
              style={tw.style(
                'w-81.5 h-81.5 border-4 rounded-2xl mb-3  border-white',
              )}
              resizeMode='cover'
            />
          )}
          <View style={tw`px-4 items-center justify-center`}>
            <Text
              textAlign='center'
              specialType={imageUrl ? 'Headline3' : 'Headline2'}
            >
              {text}
            </Text>
          </View>
        </Animated.View>
      </View>
    )
  },
  () => true,
)
