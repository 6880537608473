import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import { ScrollView } from 'moti/build/components'
import { useEffect, useState } from 'react'
import { FlatList, TextInput, TouchableOpacity, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { ApplePay, CardPay, PayPal } from '#components/base/IconSvg/SVG'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { toastError } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Dropdown } from '#components/widgets/DropDown'
import { PUBLISHABLE_KEY_STRIPE } from '#config'
import type { Code } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import type { ProductItem } from '#types/product'

import { countries, symbol } from './country'

const stripePromise = loadStripe(PUBLISHABLE_KEY_STRIPE)

type SvgIconName = 'ApplePay' | 'CardPay' | 'PayPal'

const paymentMethods = [
  { id: 'cardPay', svg: 'CardPay' as SvgIconName },
  { id: 'paypal', svg: 'PayPal' as SvgIconName },
  { id: 'applePay', svg: 'ApplePay' as SvgIconName },
]

const IconComponent = {
  ApplePay,
  CardPay,
  PayPal,
}

const CheckoutForm: React.FC<ActionSheetStripeProps> = ({
  selectedPlan,
  closeActionsheet,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [selectedMethod, setSelectedMethod] = useState('cardPay')
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
    undefined,
  )
  const [zip, setZip] = useState('')
  const [name, setName] = useState('')
  const [cardComplete, setCardComplete] = useState<boolean>(false)
  const [expiryComplete, setExpiryComplete] = useState<boolean>(false)
  const [cvcComplete, setCvcComplete] = useState<boolean>(false)
  const [paymentData, setPaymentData] = useState({
    paymentMethodId: '',
    customerId: '',
    paymentIntent: '',
  })
  const [CPMState, setCPMState] = useState<boolean>(false)
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false)

  const handleChange = (text: string) => {
    const numericValue = text.replace(/[^0-9]/g, '')
    setZip(numericValue)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      console.error('Stripe.js has not yet loaded.')
      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    if (!cardElement) {
      console.error('CardNumberElement not found.')
      return
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        address: {
          postal_code: zip,
          country: selectedCountry,
        },
      },
    })

    if (error) {
      console.error(error.message)
    } else {
      const createResponse = await gql.createPaymentIntent({
        data: {
          amount: selectedPlan.price?.unitAmount,
          currency:
            (selectedPlan.price?.currency.toUpperCase() as Code) || 'AUD',
          payment_method_types: ['card'],
          payment_method: paymentMethod.id,
        },
      })

      const createPaymentIntentData = createResponse.data?.createPaymentIntent

      if (createPaymentIntentData) {
        setCPMState(true)
        setPaymentData({
          paymentMethodId: paymentMethod.id,
          customerId: createPaymentIntentData.customer || '',
          paymentIntent: createPaymentIntentData.paymentIntent || '',
        })
      } else {
        toastError({ message: 'createPaymentIntent failed' })
      }
    }
  }

  // Xác nhận thanh toán
  const confirmPayment = async () => {
    const { paymentMethodId, customerId, paymentIntent } = paymentData
    try {
      const confirmResponse = await gql.confirmPaymentIntent({
        data: {
          customer_id: customerId || '',
          payment_intent_id: paymentIntent || '',
          payment_method_id: paymentMethodId || '',
        },
      })
      const confirmPaymentIntentData =
        confirmResponse.data?.confirmPaymentIntent

      if (confirmPaymentIntentData) {
        setPaymentSuccess(true)
      } else {
        toastError({ message: 'confirmPaymentIntent failed' })
      }
    } catch (error) {
      console.error('Error confirming payment:', error)
      toastError({ message: 'Error confirming payment' })
    }
  }

  const navigateTopic = async () => {
    closeActionsheet()
    navigate('Home', { screen: 'Topics' })
  }

  useEffect(() => {
    const cardElement = elements?.getElement(CardNumberElement)
    const expiryElement = elements?.getElement(CardExpiryElement)
    const cvcElement = elements?.getElement(CardCvcElement)

    const handleCardChange = event => setCardComplete(event.complete)
    const handleExpiryChange = event => setExpiryComplete(event.complete)
    const handleCvcChange = event => setCvcComplete(event.complete)

    if (cardElement) {
      cardElement.on('change', handleCardChange)
    }
    if (expiryElement) {
      expiryElement.on('change', handleExpiryChange)
    }
    if (cvcElement) {
      cvcElement.on('change', handleCvcChange)
    }

    return () => {
      if (cardElement) {
        cardElement.off('change', handleCardChange)
      }
      if (expiryElement) {
        expiryElement.off('change', handleExpiryChange)
      }
      if (cvcElement) {
        cvcElement.off('change', handleCvcChange)
      }
    }
  }, [elements])

  const ItemPayment = ({
    item,
  }: {
    item: { id: string; svg: SvgIconName }
  }) => {
    const Icon = IconComponent[item.svg]
    return (
      <TouchableOpacity
        style={tw.style(
          'w-30 p-3 border border-gray-300 rounded-2xl mx-2 bg-transparent items-center',
          item.id === selectedMethod && 'border-primary-400',
        )}
        onPress={() => setSelectedMethod(item.id)}
        disabled={CPMState}
      >
        <Icon />
      </TouchableOpacity>
    )
  }

  const countryOptions = countries.map(country => ({
    label: country.name,
    value: country.code,
  }))

  const isFormValid =
    name.length > 0 &&
    zip.length > 0 &&
    selectedCountry !== undefined &&
    selectedMethod !== undefined &&
    cardComplete &&
    expiryComplete &&
    cvcComplete

  const getSymbol = currencyCode => {
    const currency = symbol.find(
      item => item.currency.toUpperCase() === currencyCode.toUpperCase(),
    )
    return currency ? currency.symbol : ''
  }

  return (
    <View style={[tw`flex-1 w-full`]}>
      {/* Header */}
      <View style={[tw`w-full px-9 py-6`]}>
        <Text
          textAlign='center'
          specialType='Headline4'
          style={tw.style('text-center', { lineHeight: '125%' })}
        >
          Checkout
        </Text>
      </View>

      <View style={[tw`flex-1 w-full justify-center items-center`]}>
        {!paymentSuccess ? (
          <>
            {/* Scrollable Content */}
            <ScrollView
              style={tw.style('w-full')}
              showsVerticalScrollIndicator={false}
            >
              <View
                style={tw.style(
                  'flex-1 p-4 bg-white rounded-lg shadow-md border border-gray-300',
                )}
              >
                {/* Payment Method Buttons */}
                <FlatList
                  data={paymentMethods}
                  horizontal
                  keyExtractor={item => item.id}
                  renderItem={({ item }) => <ItemPayment item={item} />}
                  style={tw.style('mb-4')}
                  showsHorizontalScrollIndicator={false}
                />

                {!CPMState ? (
                  <View>
                    {/* Card Input Fields */}
                    <View style={tw.style('pb-3')}>
                      <Text specialType='Title' color={tw.color('text-1')}>
                        Name on card
                      </Text>
                    </View>
                    <TextInput
                      style={tw.style('border rounded-full mb-4 py-4 px-6', {
                        fontFamily: 'Nunito-Regular',
                        fontSize: 18,
                        borderColor: tw.color('neutral-200'),
                        outline: 'none',
                      })}
                      value={name}
                      onChangeText={setName}
                    />
                    {/* Card Number, Expiry, and CVC */}
                    <View style={tw.style('pb-3')}>
                      <Text specialType='Title' color={tw.color('text-1')}>
                        Card information
                      </Text>
                    </View>
                    <View
                      style={tw.style(
                        'relative border border-gray-300 rounded-full mb-4 py-4 px-6',
                      )}
                    >
                      <CardNumberElement
                        options={{
                          placeholder: 'Card Number',
                          style: {
                            base: {
                              fontSize: '18px',
                              color: tw.color('text-2'),
                              letterSpacing: '0.05em',
                              fontFamily: 'Nunito, sans-serif',
                              '::placeholder': { color: tw.color('text-2') },
                            },
                            invalid: { color: tw.color('error-200') },
                          },
                        }}
                      />
                      <View style={tw.style('absolute right-6 top-1/4')}>
                        <SystemIcon type='SVG' name='card' />
                      </View>
                    </View>

                    <View style={tw.style('flex-row gap-2')}>
                      <View
                        style={tw.style(
                          'border border-gray-300 rounded-full mb-4 py-4 px-6 flex-1',
                        )}
                      >
                        <CardExpiryElement
                          options={{
                            style: {
                              base: {
                                fontSize: '18px',
                                color: tw.color('text-2'),
                                letterSpacing: '0.05em',
                                fontFamily: 'Nunito, sans-serif',
                                '::placeholder': { color: tw.color('text-2') },
                              },
                              invalid: { color: tw.color('error-200') },
                            },
                          }}
                        />
                      </View>
                      <View
                        style={tw.style(
                          'border border-gray-300 rounded-full mb-4 py-4 px-6 flex-1',
                        )}
                      >
                        <CardCvcElement
                          options={{
                            style: {
                              base: {
                                fontSize: '18px',
                                color: tw.color('text-2'),
                                letterSpacing: '0.05em',
                                fontFamily: 'Nunito, sans-serif',
                                '::placeholder': { color: tw.color('text-2') },
                              },
                              invalid: { color: tw.color('error-200') },
                            },
                          }}
                        />
                      </View>
                    </View>
                    {/* Country and ZIP */}
                    <View style={tw.style('pb-3')}>
                      <Text specialType='Title' color={tw.color('text-1')}>
                        Country
                      </Text>
                    </View>

                    <Dropdown
                      style={tw.style({
                        fontFamily: 'Nunito-Regular',
                        fontSize: 18,
                        padding: 10,
                      })}
                      options={countryOptions}
                      onSelect={setSelectedCountry}
                      defaultValue={selectedCountry}
                    />

                    <View style={tw.style('my-3')}>
                      <Text specialType='Title' color={tw.color('text-1')}>
                        Zip code
                      </Text>
                    </View>
                    <TextInput
                      style={tw.style('border rounded-full mb-4 py-4 px-6', {
                        fontFamily: 'Nunito-Regular',
                        fontSize: 18,
                        borderColor: tw.color('neutral-200'),
                        outline: 'none',
                      })}
                      value={zip}
                      onChangeText={handleChange}
                      keyboardType='numeric'
                    />
                  </View>
                ) : (
                  <View style={tw`bg-neutral-100 px-4 py-3 rounded-xl`}>
                    {/* Order Summary */}
                    <Text specialType='Headline3' color={tw.color('text-1')}>
                      Order summary
                    </Text>
                    <View style={tw`flex-row justify-between mt-3 mb-2`}>
                      <Text specialType='paragraph1' color={tw.color('text-1')}>
                        Package name
                      </Text>
                      <Text specialType='paragraph1' color={tw.color('text-1')}>
                        {selectedPlan.name}
                      </Text>
                    </View>

                    <View style={tw`flex-row justify-between mb-2`}>
                      <Text specialType='paragraph1' color={tw.color('text-1')}>
                        Fee
                      </Text>
                      <Text specialType='paragraph1' color={tw.color('text-1')}>
                        {`${getSymbol(selectedPlan.price?.currency)}${selectedPlan.price?.unitAmount}`}
                      </Text>
                    </View>

                    <View style={tw`flex-row justify-between`}>
                      <View style={tw`flex-row justify-between items-center`}>
                        <Text
                          specialType='Headline4'
                          color={tw.color('text-1')}
                        >
                          Total{' '}
                        </Text>
                        <Text specialType='Note' color={tw.color('text-1')}>
                          VAT included
                        </Text>
                      </View>

                      <Text specialType='Headline4' color={tw.color('text-1')}>
                        {`${getSymbol(selectedPlan.price?.currency)}${selectedPlan.price?.unitAmount}`}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </ScrollView>

            {/* Button outside of ScrollView */}
            <View style={tw.style('w-full p-4 bg-background-light-2')}>
              {!CPMState ? (
                <Button onPress={handleSubmit} disabled={!isFormValid}>
                  Continue
                </Button>
              ) : (
                <Button onPress={confirmPayment}>Subscribe now</Button>
              )}
            </View>
          </>
        ) : (
          <View style={tw.style('flex-col items-center')}>
            <SystemIcon type='SVG' name='tickCircle' size={75} />
            <View style={tw.style('mt-3 items-center gap-2')}>
              <Text specialType='Headline3' color={tw.color('primary-400')}>
                Payment success!!
              </Text>
              <Text specialType='Subtitle'>
                Congratulations on being our valued member
              </Text>
            </View>
            <View
              style={tw.style(
                'w-full mt-10 mb-5 border border-primary-400 rounded-full',
              )}
            >
              <Button onPress={navigateTopic} tone='secondary' size='large'>
                Continue Learning
              </Button>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}
type ActionSheetStripeProps = {
  selectedPlan: ProductItem
  closeActionsheet: () => void
}

export const ActionSheetStripe: React.FC<ActionSheetStripeProps> = observer(
  ({ selectedPlan, closeActionsheet }) => (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        selectedPlan={selectedPlan}
        closeActionsheet={closeActionsheet}
      />
    </Elements>
  ),
)
