import { Flex, Typography } from 'antd'
import type { FC } from 'react'

import { tw } from '#components/utils/tw'

type ILabelProps = {
  label: string
  required?: boolean
}

export const Label: FC<ILabelProps> = ({ label, required }) => (
  <Flex vertical={false}>
    <Typography>{label}</Typography>
    {required && <Typography style={tw`text-red-500`}>*</Typography>}
  </Flex>
)
