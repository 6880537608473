import { useIsFocused } from '@react-navigation/native'
import { Flex } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { AdminScreenProps } from '#navigator/types'

import { WordAndPhrases } from './WordAndPhrases'

export type TranslateDetailTabs = 'word_and_phrases' | 'question' | 'answer'

export const Translate = observer(
  ({ navigation, route }: AdminScreenProps<'AdminTranslateDetail'>) => {
    const { tab } = route.params
    const [activeKey, setActiveKey] = useState<TranslateDetailTabs>(tab)
    const isFocus = useIsFocused()

    useEffect(() => {
      if (tab !== activeKey) {
        setActiveKey(tab)
      }
    }, [tab, isFocus])

    // const onChangeTab = (key: string) => {
    //   const newTab: TranslateDetailTabs = key as TranslateDetailTabs
    //   setActiveKey(newTab)
    //   navigation.setParams({ tab: newTab })
    // }

    return (
      <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
        <Flex style={tw`flex py-5 items-center`}>
          <Text style={tw`text-6 px-2`}>Translate</Text>
        </Flex>
        {/* <Tabs
          type='card'
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={onChangeTab}
        >
          <Tabs.TabPane tab='Word and Phrases' key='word_and_phrases'> */}
        <WordAndPhrases />
        {/* </Tabs.TabPane> */}
        {/* <Tabs.TabPane tab='Question' key='question'>
            <Question />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Answer' key='answer'>
            <Answer />
          </Tabs.TabPane> */}
        {/* </Tabs> */}
      </Flex>
    )
  },
)
