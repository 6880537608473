import {
  BankFilled,
  LogoutOutlined,
  OpenAIOutlined,
  SettingOutlined,
  TagsOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useNavigationState } from '@react-navigation/native'
import { Avatar, Button, Flex, Layout, Menu, Typography } from 'antd'
import type { MenuItemType } from 'antd/es/menu/interface'
import { Translate } from 'iconsax-react'
import { observer } from 'mobx-react-lite'
import { createElement } from 'react'

import { getInitials } from '#components/utils/getInitials'
import { getUserName } from '#components/utils/getUserName'
import { tw } from '#components/utils/tw'
import { navigate } from '#navigator/helpers'
import { onLogoutPress } from '#screens/Profile/onLogoutPress'
import { S } from '#store'

const { Sider } = Layout

export const Siderbar = observer(() => {
  const routes = useNavigationState(state => state.routes)
  const r = routes.map(item => item.state?.routes).flat()
  const name = getUserName({
    name: S.shared.currentUser?.name || '',
    email: S.shared.currentUser?.email || '',
  })
  const items: MenuItemType[] = [
    {
      icon: createElement(UserOutlined),
      label: 'Users',
      key: 'AdminUsers',
      onClick: () => navigate('Admin', { screen: 'AdminUsers' }),
    },
    {
      icon: createElement(UnorderedListOutlined),
      label: 'Topics',
      key: 'AdminTopics',
      onClick: () => navigate('Admin', { screen: 'AdminTopics' }),
    },
    {
      icon: createElement(OpenAIOutlined),
      label: 'AI Tutor',
      key: 'AdminAITutor',
      onClick: () => navigate('Admin', { screen: 'AdminAITutor' }),
    },
    {
      icon: createElement(Translate, { style: { width: '15px' } }),
      label: 'Translate',
      key: 'AdminTranslateDetail',
      onClick: () =>
        navigate('Admin', {
          screen: 'AdminTranslateDetail',
          params: { tab: 'word_and_phrases' },
        }),
    },
    {
      icon: createElement(TagsOutlined),
      label: 'Tags',
      key: 'AdminTags',
      onClick: () => navigate('Admin', { screen: 'AdminTags' }),
    },
    {
      icon: createElement(BankFilled),
      label: 'Payment',
      key: 'AdminPayment',
      onClick: () =>
        navigate('Admin', {
          screen: 'AdminPayment',
        }),
    },
    {
      icon: createElement(SettingOutlined),
      label: 'Settings',
      key: 'AdminSettings',
      onClick: () => navigate('Admin', { screen: 'AdminSettings' }),
    },
  ]
  const checkPath = () => {
    const rName = r[r.length - 1]?.name || 'AdminUsers'

    if (
      ['AdminTopics', 'AdminTopicDetail', 'AdminListenGame'].includes(rName)
    ) {
      return ['AdminTopics']
    }
    return [rName]
  }

  return (
    <Sider
      breakpoint='lg'
      collapsedWidth='0'
      width={250}
      style={tw`py-4 px-4 bg-background-light-1 border-r-stone-200 border-r border-solid border-l-0 border-t-0 border-b-0`}
      theme='light'
    >
      <Flex gap='small' style={tw`pb-4 items-center`}>
        <Avatar size='large' src={S.shared.currentUser?.thumbnail?.url}>
          {getInitials(name)}
        </Avatar>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {name}
        </Typography.Title>
      </Flex>
      <Menu
        mode='inline'
        items={items}
        selectedKeys={checkPath()}
        style={{
          flex: 1,
          overflowY: 'auto',
          border: 'none',
          height: 'calc(100% - 90px)',
        }}
      />
      <Button
        type='text'
        block
        onClick={onLogoutPress}
        icon={<LogoutOutlined />}
        style={tw`px-6 justify-start items-center`}
      >
        Logout
      </Button>
    </Sider>
  )
})
