import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'

import { LoadingDots } from './LoadingDots'

type ListHeaderProps = {
  onSuggestionPress: (suggestion: string) => void
  topicInUserId: string
}

export const ListHeader: FC<ListHeaderProps> = ({
  onSuggestionPress,
  topicInUserId,
}) => {
  const [suggestionList, setSuggestionList] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getSuggestion()

    // const handleMessage = (message: string) => {
    //   setSuggestionList(extractCharacters(message))
    //   setIsLoading(false)
    // }

    // S.socket?.socket?.on('suggestMessage', handleMessage)

    // return () => {
    //   S.socket?.socket?.off('suggestMessage', handleMessage)
    // }
  }, [])

  const onPressRefresh = () => {
    getSuggestion()
  }

  const getSuggestion = async () => {
    try {
      setIsLoading(true)
      const res = await gql.suggestMessage(
        { topicInUserId },
        { requestPolicy: 'network-only' },
      )
      if (res.data) {
        setSuggestionList(JSON.parse(res.data.suggestMessage || ''))
      }
    } catch (error) {
      console.error('Error fetching suggestion:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <View style={tw`flex-col`}>
      <View style={tw`flex-col items-end mt-4 mx-4`}>
        <View style={tw`flex-col p-4 max-w-[65%] bg-neutral-75 rounded-4`}>
          <Text specialType='Subtitle'>What you can say:</Text>
          {isLoading ? (
            <View style={tw`mt-3 mb-2 gap-2`}>
              <LoadingDots />
            </View>
          ) : (
            <View style={tw`mt-3 mb-2 gap-2`}>
              {suggestionList.map((item, index) => (
                <TouchableOpacity
                  onPress={() => onSuggestionPress(item)}
                  key={index}
                  style={tw`flex-row items-center flex-wrap`}
                >
                  <Text specialType='Note' style={tw`flex-shrink max-w-full`}>
                    {index + 1}. {item}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          )}

          <View style={tw`flex-col items-end`}>
            <SystemIcon
              type='SVG'
              name='arrows-clockwise'
              onPress={onPressRefresh}
            />
          </View>
        </View>
      </View>
    </View>
  )
}
