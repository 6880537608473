import { forwardRef, useImperativeHandle } from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import type { AudioPropsRef } from '#components/utils/audio/type'
import { useAudioManager } from '#components/utils/audio/useAudioManager'
import { tw } from '#components/utils/tw'
import type { GameQuestionProps } from '#types/games'

export const AudioQuestion = forwardRef<AudioPropsRef, GameQuestionProps>(
  ({ question }, ref) => {
    const { height } = useWindowDimensions()
    const { play, stop, isPlay } = useAudioManager({
      url: question.media?.url || '',
    })

    useImperativeHandle(ref, () => ({ stop: stopAudio, play: playAudio }), [
      isPlay,
      question.media,
    ])

    const playAudio = () => {
      play(1)
    }

    const stopAudio = () => {
      stop()
    }

    return (
      <View style={tw.style('flex-col p-4 mb-4')}>
        <View style={tw`flex-1 flex-row items-center justify-center gap-4`}>
          <TouchableOpacity
            onPress={() => play(1)}
            style={tw`rounded-full border-4 border-line-2 p-6 items-center justify-center`}
          >
            <SystemIcon
              type='SAX'
              name='VolumeHigh'
              size={height * 0.1}
              color={tw.color('primary-400')}
              variant='Bold'
            />
          </TouchableOpacity>
          {question.type !== 'selectImage' && (
            <TouchableOpacity
              onPress={() => play(0.5)}
              style={tw`rounded-full border-[3px] border-line-2 p-2 items-center justify-center`}
            >
              <SystemIcon
                type='SVG'
                name='snail'
                size={height * 0.05}
                color={tw.color('primary-400')}
              />
            </TouchableOpacity>
          )}
          {question.type === 'selectImage' && (
            <Text specialType='Headline2'>{question?.text}</Text>
          )}
        </View>
      </View>
    )
  },
)
