import {
  getRandomGames,
  getRandomList,
  getRandomListWithMaxOccurrences,
  shuffleArray,
} from '#components/utils/random'
import type { TypeGame } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type {
  EasyGameType,
  GameContainer,
  GamesProps,
  GenerateHardGameProps,
  GenerateProps,
  HardGameType,
  WordItem,
} from '#types/games'
import { GameTimes } from '#types/games'
import type { LanguageType, Level } from '#types/language'
import type { SearchWordInUser } from '#types/wordInUser'

import { generateCompleteTheDialogue } from './completeTheDialogue'
import { generateCorrectPhrase } from './correctPhrase'
import { generateCorrectTranslation } from './correctTranslation'
import { generateFillTheBlank } from './fillTheBlank'
import { getItemInList } from './getItemInList'
import { generateHearAudio } from './hearAudio'
import { generateHearVideo } from './hearVideo'
import { generateRearrangement } from './rearrangement'
import { generateSameOrDifferent } from './sameOrDifferent'
import { generateSelectImage } from './selectImage'

type GenerateGames = {
  topicId: string
  userId: string
  isPhrase: boolean
  level: Level
  language: LanguageType
  isCompleted?: boolean
}

type EasyGameFunctions = (props: GenerateProps) => Promise<GamesProps>

type HardGameFunctions = (props: GenerateHardGameProps) => Promise<GamesProps>

type ResultGenerateGames = GameContainer[]

export const generateGames = async ({
  topicId,
  userId,
  isPhrase,
  language,
  isCompleted,
}: GenerateGames): Promise<ResultGenerateGames> => {
  const resp = await gql.generateGame({
    filterWordInUser: {
      topicId,
      times_lt: GameTimes.TimeMax,
      userId,
      word_some: { isVocabulary: !isPhrase },
      wordId_ne: '',
    },
    orderWordInUser: 'times_asc',
    filterWord: { topicId, isVocabulary: !isPhrase },
    filterResourceInWord: { word_some: { topicId, isVocabulary: !isPhrase } },
    filterTranslate: { Word_some: { topicId, isVocabulary: !isPhrase } },
  })
  const generateGame = resp.data

  const wordInUserList: SearchWordInUser[] = generateGame?.wordInUser || []

  if (wordInUserList.length > 0) {
    const list: SearchWordInUser[] = isCompleted
      ? shuffleArray(wordInUserList).slice(0, 5)
      : wordInUserList.slice(0, 5)
    const randomList: SearchWordInUser[] = getRandomList(list)
    const data = getRandomListWithMaxOccurrences({ list: randomList })

    const games = getRandomGames(data, isPhrase)
    const resource = generateGame?.resourceInWord || []
    const translate = generateGame?.translate || []
    const words = games.map(item => {
      const mediaItems = resource.filter(media => media.wordId === item.wordId)
      return {
        ...item,
        media: mediaItems.length > 0 ? mediaItems : [],
        translation: translate.filter(i => i.wQAId === item.wordId),
      }
    })

    const respHardGame = await gql.generateHardGame({
      data: words.map(i => ({
        id: i.id,
        text: i.word?.vocabAndPhrase || '',
        type: i.hardGame as TypeGame,
      })),
      isPhrase,
      language,
    })

    const hardGame = respHardGame.data?.generateHardGame || []

    const wordsData: WordItem[] = (generateGame?.word || []).map(w => {
      const m = resource.filter(media => media.wordId === w.id)
      const t = translate.filter(media => media.wQAId === w.id)
      return { ...w, media: m, translation: t }
    })

    const results = await Promise.all(
      words.map(async item => {
        const translation = translate.filter(i => i.wQAId === item.wordId)
        const paramsEasy: GenerateProps = {
          language,
          isPhrase,
          item: {
            ...item,
            text: item.word?.vocabAndPhrase || '',
            translation,
          },
          data: wordsData,
        }

        const easyType: { [key in EasyGameType]: EasyGameFunctions } = {
          sameOrDifferent: generateSameOrDifferent,
          selectImage: generateSelectImage,
          hearAudio: generateHearAudio,
          hearVideo: generateHearVideo,
          correctTranslation: generateCorrectTranslation,
          rearrangement: generateRearrangement,
          correctPhrase: generateCorrectPhrase,
        }
        const resultEasy = await easyType[item.easyGame](paramsEasy)
        // const resultEasy = await easyType.correctTranslation(params)
        const dataHardGame = hardGame.filter(i => i.id === item.id)
        const paramsHard: GenerateHardGameProps = {
          item: {
            ...item,
            text: item.word?.vocabAndPhrase || '',
            translation,
          },
          data: getItemInList(dataHardGame) || null,
        }
        const hardType: { [key in HardGameType]: HardGameFunctions } = {
          fillTheBlank: generateFillTheBlank,
          completeTheDialogue: generateCompleteTheDialogue,
        }
        const resultHard = await hardType[item.hardGame](paramsHard)
        // const resultHard = await hardType.completeTheDialogue(params)
        return { ...item, easy: resultEasy, hard: resultHard }
      }),
    )
    return results
  }

  return []
}
