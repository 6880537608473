import type { LegacyRef } from 'react'

import type { AudioPropsRef } from '#components/utils/audio/type'
import type { VideoPropsRef } from '#components/widgets/Video/Video'
import type { GenerateHardGameQuery } from '#graphql/codegen'

import type { LanguageType } from './language'
import type { Resources, ResourceType } from './media'
import type { QuesTionType } from './question'
import type { ResourceInWord } from './resourceInWord'
import type { SearchTopicItem } from './topic'
import type { SearchTranslateItem } from './translate'
import type { SearchWordItem } from './word'
import type { SearchWordInUser } from './wordInUser'

export enum EasyGame {
  sameOrDifferent = 'sameOrDifferent',
  selectImage = 'selectImage',
  hearAudio = 'hearAudio',
  hearVideo = 'hearVideo',
  correctTranslation = 'correctTranslation',
  rearrangement = 'rearrangement',
  correctPhrase = 'correctPhrase',
}

export type EasyGameType = keyof typeof EasyGame

export enum HardGame {
  fillTheBlank = 'fillTheBlank',
  completeTheDialogue = 'completeTheDialogue',
}

export type HardGameType = keyof typeof HardGame

export type GameType = EasyGameType | HardGameType

export const randomIndex = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min

export enum EasyGameLabel {
  SameOrDifferent = 'Same or different',
  SelectImage = 'Select the correct image',
  HearAudio = 'Tap what you hear audio',
  HearVideo = 'Tap what you hear video',
  CorrectTranslation = 'Select the correct translation',
  Rearrangement = 'Sentence/word rearrangement',
  CorrectPhrase = 'Select the correct phrase',
}

export enum HardGameLabel {
  FillTheBlank = 'Fill in the blank',
  CompleteTheDialogue = 'Complete the dialogue',
}

export const easyGameLabel: { value: GameType; label: string }[] = [
  { value: EasyGame.sameOrDifferent, label: EasyGameLabel.SameOrDifferent },
  { value: EasyGame.selectImage, label: EasyGameLabel.SelectImage },
  { value: EasyGame.hearAudio, label: EasyGameLabel.HearAudio },
  { value: EasyGame.hearVideo, label: EasyGameLabel.HearVideo },
  {
    value: EasyGame.correctTranslation,
    label: EasyGameLabel.CorrectTranslation,
  },
  { value: EasyGame.rearrangement, label: EasyGameLabel.Rearrangement },
  { value: EasyGame.correctPhrase, label: EasyGameLabel.CorrectPhrase },
]

export const hardGameLabel: { value: GameType; label: string }[] = [
  { value: HardGame.fillTheBlank, label: HardGameLabel.FillTheBlank },
  {
    value: HardGame.completeTheDialogue,
    label: HardGameLabel.CompleteTheDialogue,
  },
]

export const wordGameLabel: { value: GameType; label: string }[] = [
  ...easyGameLabel.filter(
    e =>
      e.value !== EasyGame.rearrangement && e.value !== EasyGame.correctPhrase,
  ),
  { value: HardGame.fillTheBlank, label: HardGameLabel.FillTheBlank },
]

export const phraseGameLabel: { value: GameType; label: string }[] = [
  ...easyGameLabel,
  ...hardGameLabel,
]

export const phraseGameLabelQuestion: { value: GameType; label: string }[] = [
  ...easyGameLabel,
  ...hardGameLabel,
]

export const gameLabel: { value: GameType; label: string }[] = phraseGameLabel

export type LayoutState = 'Unselected' | 'True' | 'False'

export type Answer = {
  id?: string
  isTrue: boolean
  image?: {
    name: string
    url: string
  }
  text?: string
  audio?: {
    name: string
    url: string
  }
  answerId?: string
  mediaId: string
  thumbnailId: string
}

export type GameMedia = {
  name: string
  url: string
  mediaId: string
}

export type InputQuestionGame = {
  id?: string
  wordId: string
  typeGame: GameType
  text: string
  media?: GameMedia
  thumbnail?: GameMedia
  index?: number
}

export type QuestionGameType = Extract<
  GameType,
  | 'hearVideo'
  | 'hearAudio'
  | 'correctTranslation'
  | 'sameOrDifferent'
  | 'selectImage'
  | 'completeTheDialogue'
  | 'correctPhrase'
>

export const mediaGameT: {
  [key in QuestionGameType]: 'audio' | 'video' | 'image'
} = {
  hearAudio: 'audio',
  hearVideo: 'video',
  correctTranslation: 'audio',
  sameOrDifferent: 'audio',
  selectImage: 'audio',
  completeTheDialogue: 'audio',
  correctPhrase: 'image',
}

export const resourceGameType: { [key in QuestionGameType]: ResourceType } = {
  hearAudio: 'audio',
  hearVideo: 'video',
  correctTranslation: 'audio',
  sameOrDifferent: 'image_game',
  selectImage: 'audio',
  completeTheDialogue: 'audio',
  correctPhrase: 'image_game',
}

export const disableGameT: { [key in GameType]: boolean } = {
  sameOrDifferent: false,
  selectImage: true,
  hearAudio: false,
  hearVideo: false,
  correctTranslation: false,
  rearrangement: false,
  fillTheBlank: false,
  completeTheDialogue: false,
  correctPhrase: false,
}

export enum GameTimes {
  TimeMin = 1,
  TimeMax = 7,
  TimeHard = 5,
  TimeReset = 3,
}

export enum LabelGame {
  LabelSame = 'Same',
  LabelDifferent = 'Different',
}

export type ItemGameType = SearchWordInUser & {
  easyGame: GameType
  hardGame: GameType
}

export enum PercentageTopic {
  Game = 50,
  Listen = 50,
  Speak = 100,
  PercentageGame = 0.8,
  PercentageListen = 0.6,
  PercentageSpeak = 0.8,
  MaxListen = 6,
}

export type GameContainerType = 'Game' | 'Listen' | 'Test'

export type GameQuestionRef = {}

export type GameQuestionProps = {
  question: QuestionGame
  audioRef?: LegacyRef<AudioPropsRef>
  videoRef?: LegacyRef<VideoPropsRef>
  onSwipe?: (swipe: SwipeType) => void
  onEndSwipe?: (swipe: SwipeType) => void
  currentIndex: number
  type?: GameContainerType
  topic?: SearchTopicItem
}

export type SwipeType = 'left' | 'right' | 'none'

export type HintProps = number[]

export type AnswerItemProps = {
  item: AnswerGame
  onPressAnswer: () => void
  layoutStates: LayoutState
  index: number
  hint: HintProps
}

export type QuestionGame = {
  text: string
  media: Resources | null
  type: QuesTionType | null
  translation: string
  index: number
  isPhrase?: boolean
}

export type AnswerGame = {
  id: string
  text: string
  image: Resources | null
  audio: Resources | null
  isCorrect: boolean
  translation: string
  type: QuesTionType | null
  answer: string
}

export type GamesProps = {
  question: QuestionGame
  answers: AnswerGame[]
}

export type Word = SearchWordInUser & {
  media: ResourceInWord[]
  easyGame: GameType
  hardGame: GameType
}

export type Games = {
  easy: GamesProps
  hard: GamesProps
}

export type GameContainer = Word & Games & {}

export type WordItem = Omit<SearchWordItem, 'media'> & {
  media: ResourceInWord[]
  translation: SearchTranslateItem[]
}

export type GenerateItem = {
  text: string
  media: ResourceInWord[]
  wordId: string
  translation: SearchTranslateItem[]
}

export type GenerateProps = {
  language: LanguageType
  isPhrase: boolean
  item: GenerateItem
  data: WordItem[]
}

export type GenerateHardGameItem =
  GenerateHardGameQuery['generateHardGame'] extends Array<infer T> ? T : never

export type GenerateHardGameProps = {
  item: GenerateItem
  data: GenerateHardGameItem | null
}

export const answersGame: string[] = [EasyGame.sameOrDifferent]
