import { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'

import type { IconSaxProps } from '#components/base/IconSax'
import { IconSax } from '#components/base/IconSax'
import { Switch } from '#components/base/Switch'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { SliderOption } from '#screens/Chat/ChatSetting/SliderBar'
import { SliderBar } from '#screens/Chat/ChatSetting/SliderBar'
import { S } from '#store'

export type ItemProps = {
  title: string
  subTitle?: string
  iconLeft?: IconSaxProps['name']
  type?: 'icon' | 'switch' | 'list'
  isChecked?: boolean
} & (
  | {
      type: 'list'
      options: SliderOption[]
      selectedOption?: number
    }
  | {
      type?: 'icon' | 'switch'
      options?: never
      selectedOption?: never
    }
)

export type Props = ItemProps & {
  onPress?: () => void
  onChangeSwitch?: (value: boolean) => void
  onOptionPress?: (value: number) => void
}

export const Item = ({
  onPress,
  title,
  iconLeft,
  type,
  subTitle,
  onChangeSwitch,
  isChecked = true,
  options = [],
  selectedOption,
  onOptionPress,
}: Props) => {
  const [isCheckedState, setIsCheckedState] = useState<boolean>(isChecked)
  const [selectedValue, setSelectedValue] = useState<number>(
    S.shared.currentUser?.speed ?? 1,
  )

  useEffect(() => {
    if (isChecked !== isCheckedState) {
      setIsCheckedState(isChecked)
    }
  }, [isChecked])

  useEffect(() => {
    if (type === 'list') {
      if (
        selectedOption !== undefined &&
        options.some(option => option.value === selectedOption)
      ) {
        setSelectedValue(selectedOption)
      }
    }
  }, [selectedOption, options, type])

  const onChange = () => {
    setIsCheckedState(!isCheckedState)
    onChangeSwitch?.(!isCheckedState)
  }

  const handlePress = () => {
    onPress?.()
  }

  const handleSlidingComplete = (value: number) => {
    const closestOption = options.reduce((prev, curr) =>
      Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev,
    )
    setSelectedValue(closestOption.value)
    onOptionPress?.(closestOption.value)
  }

  return (
    <View>
      <TouchableOpacity onPress={handlePress} style={tw.style('flex-col p-4')}>
        <View style={tw.style('flex-row items-center')}>
          {iconLeft && <IconSax name={iconLeft} />}
          <View style={tw.style('flex-1 mx-4')}>
            <Text specialType='paragraph1' style={tw.style('flex-wrap')}>
              {title}
            </Text>
          </View>
          <View style={tw.style('flex-row items-center')}>
            {subTitle && (
              <View style={tw.style('bg-success-300 rounded-lg py-1 px-2')}>
                <Text specialType='Note' color={tw.color('text-4')}>
                  {subTitle}
                </Text>
              </View>
            )}
            {type === 'switch' && (
              <Switch value={isCheckedState} onChange={onChange} />
            )}
            {type === 'icon' && (
              <IconSax name='ArrowRight2' color={tw.color('neutral-300')} />
            )}
          </View>
        </View>
      </TouchableOpacity>

      {type === 'list' && options.length > 0 && (
        <View style={tw.style('flex-col px-4')}>
          <SliderBar
            options={options}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            onSlidingComplete={handleSlidingComplete}
          />
        </View>
      )}
    </View>
  )
}
