import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Animated, TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { useDimensions } from '#components/utils/useDimensions'
import { Arrow } from '#components/widgets/Arrow'
import type { AnswerGame, SwipeType } from '#types/games'
import { LabelGame } from '#types/games'

type SameOrDifferentAnswerProps = {
  data: AnswerGame[]
  onChangeData?: (type: SwipeType) => void
  currentIndex?: number
}

export type SameOrDifferentRef = {
  onStartSwipe: (type: SwipeType) => void
}

export const SameOrDifferentAnswer = forwardRef<
  SameOrDifferentRef,
  SameOrDifferentAnswerProps
>(({ onChangeData, currentIndex }, ref) => {
  const { screenWidth } = useDimensions({ padding: 16 })
  const colorValueLeft = useRef<Animated.Value>(new Animated.Value(0)).current
  const borderColorValueLeft = useRef<Animated.Value>(
    new Animated.Value(0),
  ).current
  const scaleValueLeft = useRef<Animated.Value>(new Animated.Value(1)).current
  const colorValueRight = useRef<Animated.Value>(new Animated.Value(0)).current
  const borderColorValueRight = useRef<Animated.Value>(
    new Animated.Value(0),
  ).current
  const scaleValueRight = useRef<Animated.Value>(new Animated.Value(1)).current
  const [swipeType, setSwipeType] = useState<SwipeType>('none')

  useEffect(() => {
    onStartSwipe('none')
  }, [currentIndex])

  useImperativeHandle(ref, () => ({ onStartSwipe }), [swipeType])

  const animateView = (
    colorValue: Animated.Value,
    borderColorValue: Animated.Value,
    scaleValue: Animated.Value,
    toValue: number,
    scale: number,
  ) =>
    Animated.parallel([
      Animated.timing(colorValue, {
        toValue,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(borderColorValue, {
        toValue,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(scaleValue, {
        toValue: scale,
        duration: 100,
        useNativeDriver: true,
      }),
    ])

  const swipeLeft = () => {
    Animated.parallel([
      animateView(
        colorValueRight,
        borderColorValueRight,
        scaleValueRight,
        0,
        1,
      ),
      animateView(colorValueLeft, borderColorValueLeft, scaleValueLeft, 1, 1.2),
    ]).start()
  }

  const swipeRight = () => {
    Animated.parallel([
      animateView(colorValueLeft, borderColorValueLeft, scaleValueLeft, 0, 1),
      animateView(
        colorValueRight,
        borderColorValueRight,
        scaleValueRight,
        1,
        1.2,
      ),
    ]).start()
  }

  const resetAnimation = () => {
    Animated.parallel([
      animateView(colorValueLeft, borderColorValueLeft, scaleValueLeft, 0, 1),
      animateView(
        colorValueRight,
        borderColorValueRight,
        scaleValueRight,
        0,
        1,
      ),
    ]).start()
  }

  const onStartSwipe = (type: SwipeType) => {
    if (type !== swipeType) {
      setSwipeType(type)
      switch (type) {
        case 'left':
          swipeLeft()
          break
        case 'right':
          swipeRight()
          break
        default:
          resetAnimation()
          break
      }
    }
  }

  const interpolatedColorLeft = colorValueLeft.interpolate({
    inputRange: [0, 1],
    outputRange: [
      tw.color('neutral-75') || '',
      tw.color('error-300') || 'red-300',
    ],
  })

  const interpolatedColorRight = colorValueRight.interpolate({
    inputRange: [0, 1],
    outputRange: [
      tw.color('neutral-75') || '',
      tw.color('success-300') || 'green-300',
    ],
  })

  const interpolatedBorderColorLeft = borderColorValueLeft.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(217, 217, 217, 0.3)', '#FFF'],
  })

  const interpolatedBorderColorRight = borderColorValueRight.interpolate({
    inputRange: [0, 1],
    outputRange: ['rgba(217, 217, 217, 0.3)', '#22A978'],
  })

  return (
    <View
      style={tw.style('flex-row items-center justify-between', {
        width: screenWidth * 0.9,
      })}
    >
      <TouchableOpacity
        onPress={() => onChangeData?.('left')}
        activeOpacity={1}
        style={tw`flex-col items-center justify-center`}
      >
        <Animated.View
          style={[
            {
              backgroundColor: interpolatedColorLeft,
              transform: [{ scale: scaleValueLeft }],
              borderColor: interpolatedBorderColorLeft,
            },
            tw`w-14 h-14 rounded-full items-center justify-center shadow-xl shadow-primary-400`,
          ]}
        >
          <SystemIcon
            type='SVG'
            name='x'
            color={
              swipeType === 'left' ? tw.color('neutral-75') : tw.color('icon')
            }
          />
        </Animated.View>
        <View style={tw`pt-2 items-center`}>
          <Text
            specialType='Title'
            color={tw.color('text-1')}
            textAlign='center'
          >
            {LabelGame.LabelDifferent}
          </Text>
        </View>
      </TouchableOpacity>
      <View style={[{ width: screenWidth * 0.4 }]}>
        <Arrow
          colorLarge='rgba(56, 56, 56, 0.8)'
          colorSmall='rgba(0, 0, 0, 0.4)'
          colorDot='#ECECEC'
        />
      </View>
      <TouchableOpacity
        onPress={() => onChangeData?.('right')}
        activeOpacity={1}
        style={tw`flex-col items-center justify-center`}
      >
        <Animated.View
          style={[
            {
              backgroundColor: interpolatedColorRight,
              transform: [{ scale: scaleValueRight }],
              borderColor: interpolatedBorderColorRight,
            },
            tw`w-14 h-14 rounded-full items-center justify-center shadow-xl shadow-primary-400`,
          ]}
        >
          <SystemIcon
            type='SVG'
            name='check'
            color={
              swipeType === 'right' ? tw.color('neutral-75') : tw.color('icon')
            }
          />
        </Animated.View>
        <View style={tw`pt-2 items-center`}>
          <Text
            specialType='Title'
            color={tw.color('text-1')}
            textAlign='center'
          >
            {LabelGame.LabelSame}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  )
})
