import { observer } from 'mobx-react-lite'
import type { FC } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { FlatList } from '#components/base/FlatList'
import { Image } from '#components/base/Image'
import { ScrollView } from '#components/base/ScrollView'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import type { TasksItem } from '#graphql/codegen'
import { useSearchTopic } from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import type { TaskDataItem } from '#screens/Chat/data'
import { TASK_DATA } from '#screens/Chat/data'
import { TaskItem as TaskItemComp } from '#screens/Chat/Tasks/TaskItem'
import { S } from '#store'

type Props = AppStackScreenProps<'ScenarioSpeak'>

export const ScenarioSpeak: FC<Props> = observer(({ route }) => {
  const { topicId } = route.params
  const { width } = useWindowDimensions()

  const [topicData] = useSearchTopic({
    variables: { filter: { id: topicId } },
  })

  const topic =
    (topicData.data?.searchTopic || []).length > 0
      ? topicData.data?.searchTopic[0]
      : null

  const taskData: TaskDataItem | null = topic?.level
    ? TASK_DATA[topic?.level || '']
    : null

  const data: TasksItem[] = taskData?.task || []

  const onPressStartSpeaking = () => {
    navigate('App', { screen: 'Chat', params: { topicId } })
  }

  const onPressWordsOrPhrases = () => {
    navigate('App', { screen: 'SuggestedSpeak', params: { topicId } })
  }

  const renderHeader = () => (
    <View style={tw`gap-4 mb-4`}>
      <View style={tw`w-full items-center`}>
        <Image
          source={{ uri: topic?.media?.url || '' }}
          style={{
            width: width - 32,
            height: width * 0.5625,
            borderRadius: 24,
          }}
          resizeMode='cover'
        />
      </View>
      <View style={tw`gap-2`}>
        <Text specialType='Headline3'>Scenario</Text>
        <Text specialType='paragraph1'>{taskData?.scenario || ''}</Text>
      </View>
      <View style={tw`flex-row`}>
        <View style={tw`flex-1`}>
          <Text specialType='paragraph1'>AI tutor Role: </Text>
          <Text specialType='paragraph1' color={tw.color('text-2')}>
            {taskData?.aiTutorRole || ''}
          </Text>
        </View>
        <View style={tw`flex-1 items-end`}>
          <Text specialType='paragraph1'>You are: </Text>
          <Text specialType='paragraph1' color={tw.color('text-2')}>
            {taskData?.userRole || ''}
          </Text>
        </View>
      </View>
    </View>
  )

  const renderItem = ({ item, index }: { item: TasksItem; index: number }) => (
    <TaskItemComp
      item={item}
      index={index}
      checkTaskItem={S.shared.getTasksIdArray()}
    />
  )

  const renderItemSeparator = () => (
    <View style={tw`h-[0.5px] bg-neutral-200 my-3`} />
  )

  const renderListHeader = () => (
    <View
      style={tw`flex-row items-center mb-3 pb-3 border-b border-neutral-200`}
    >
      <SystemIcon type='SAX' name='TaskSquare' />
      <View style={tw`flex-1 ml-3`}>
        <Text specialType='Headline4'>Task list</Text>
      </View>
    </View>
  )

  return (
    <View style={tw`flex-1 flex-col bg-background-light-white`}>
      <Header title='Role - play speaking' />
      <View style={tw`flex-1 flex-col`}>
        <ScrollView contentContainerStyle={tw`px-4 pt-4`}>
          {renderHeader()}
          <FlatList
            data={data}
            renderItem={renderItem}
            ItemSeparatorComponent={renderItemSeparator}
            ListHeaderComponent={renderListHeader}
            contentContainerStyle={tw`p-4 border border-primary-400 rounded-2xl bg-background-light-white shadow-xl shadow-primary-400`}
          />
        </ScrollView>
      </View>
      <View style={tw`p-4 bg-background-light-white gap-1`}>
        <Button onPress={onPressStartSpeaking}>Start speaking</Button>
        <Button
          tone='plain'
          titleColor={tw.color('primary-400')}
          onPress={onPressWordsOrPhrases}
        >
          Words/phrases
        </Button>
      </View>
    </View>
  )
})
