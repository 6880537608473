import { zodResolver } from '@hookform/resolvers/zod'
import type { FC } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { z } from 'zod'

import { Button } from '#components/base/Button/Button'
import { FormField } from '#components/form/FormField'
import { InputPassword } from '#components/form/InputPassword'
import { toastError, toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { WarningPassword } from '#components/widgets/WarningPassword'
import { gql } from '#graphql/urql'
import type { AppStackScreenProps } from '#navigator/types'

const FormSchema = z.object({
  oldPassword: z.string({ required_error: 'Password is required' }),
  password: z
    .string({ required_error: 'Password is required' })
    .min(6, { message: 'Password must be at least 6 characters' }),
})

type ChangePassFormValue = z.infer<typeof FormSchema>

type Props = AppStackScreenProps<'ChangePassword'>

export const ChangePassword: FC<Props> = ({}) => {
  const { control, watch } = useForm<ChangePassFormValue>({
    resolver: zodResolver(FormSchema),
    shouldFocusError: true,
  })

  const passwordValue = watch('password')
  const oldPasswordValue = watch('oldPassword')

  const [isCheckPass, setIsCheckPass] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      const resp = await gql.changePassword({
        password: passwordValue,
        oldPassword: oldPasswordValue,
      })
      setIsLoading(false)
      if (resp.error || !resp.data) {
        toastError({
          message: resp.error?.message || 'Password change unsuccessful.',
        })
        return
      }
      toastSuccess({
        message: 'You can login again with a new password',
      })
    } catch (error) {
      toastError({
        message: 'Password change unsuccessful',
      })
    }
  }

  return (
    <View style={tw`flex-1 flex-col`}>
      <Header title='Change password' />
      <View style={tw`flex-1 flex-col px-4 pb-4 pt-6`}>
        <View style={tw`flex-1 flex-col gap-6`}>
          <FormField name='oldPassword' control={control}>
            <InputPassword autoFocus />
          </FormField>
          <FormField name='password' control={control}>
            <InputPassword />
          </FormField>
          <WarningPassword
            password={passwordValue || ''}
            onAllConditionsMet={setIsCheckPass}
          />
        </View>
        <Button
          loading={isLoading}
          disabled={!isCheckPass || !oldPasswordValue}
          onPress={handleConfirm}
        >
          Continue
        </Button>
      </View>
    </View>
  )
}
