import type React from 'react'
import { TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'

type RadioButtonProps = {
  title: string
  description: string
  price: number
  discount: number
  selected: boolean
  onSelect: () => void
}

export const PaymentRadioButton: React.FC<RadioButtonProps> = ({
  title,
  description,
  price,
  selected,
  discount,
  onSelect,
}) => (
  <TouchableOpacity
    onPress={onSelect}
    style={tw.style(
      `flex-col p-6 ${selected ? 'border-primary-400 border-[3px]' : ''} rounded-3xl gap-1 bg-background-light-white`,
    )}
  >
    <View style={tw.style('flex-row')}>
      <View style={tw`flex-col flex-1 mr-5`}>
        {discount > 0 && (
          <View
            style={tw.style(
              'bg-secondary1-50 px-6 py-1 mb-1 self-start rounded-lg',
            )}
          >
            <Text color={tw.color('secondary1-500')} specialType='Note'>
              {discount}% OFF
            </Text>
          </View>
        )}
        <View style={tw.style('flex-1')}>
          <View style={tw`flex-row items-center`}>
            {/* <Text specialType='Title'>{title}</Text> */}
            <Text
              specialType='Headline4'
              color={tw.color(selected ? 'primary-400' : 'text-1')}
            >
              {title} - ${price}
            </Text>
          </View>

          <Text specialType='Note' color={tw.color('text-2')}>
            {description}
          </Text>
        </View>
      </View>
      <View
        style={tw.style(
          'w-8 h-8 rounded-full',
          selected ? 'bg-neutral-75' : 'bg-white border border-neutral-300',
          discount ? 'self-center' : '',
        )}
      >
        {selected && (
          <SystemIcon
            name='TickCircle'
            type='SAX'
            color={tw.color('primary-400')}
            size={40}
            variant='Bold'
          />
        )}
      </View>
    </View>
  </TouchableOpacity>
)
