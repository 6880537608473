import type { FC } from 'react'
import { forwardRef, useEffect, useRef, useState } from 'react'
import type { TextInput as RNTextInput, TextInputProps } from 'react-native'
import { Dimensions, Platform, TextInput, View } from 'react-native'

import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { tw } from '#components/utils/tw'

interface InputProps extends TextInputProps {
  onSend: (content: string) => void
  disableButton?: boolean
  handleMicIconPress?: () => void
  handleSuggestPress?: () => void
}

const { width } = Dimensions.get('window')

export const Input: FC<InputProps> = forwardRef<RNTextInput, InputProps>(
  (
    {
      onSend,
      disableButton = false,
      handleMicIconPress = () => {},
      handleSuggestPress,
    },
    ref,
  ) => {
    const [message, setMessage] = useState<string>('')
    const [inputHeight, setInputHeight] = useState(56)
    const textInputRef = useRef<RNTextInput | null>(null)
    const [isFocused, setIsFocused] = useState(false)

    const handleSend = () => {
      if (message.trim()) {
        onSend(message.trim())
        setMessage('')
      }
    }

    const handleContentSizeChange = (
      contentWidth: number,
      contentHeight: number,
    ) => {
      const newHeight = Math.min(Math.max(contentHeight, 56), 128)
      setInputHeight(newHeight)
    }

    useEffect(() => {
      if (textInputRef.current) {
        textInputRef.current.focus()
      }
    }, [width])

    const handleKeyPress = (e: { nativeEvent: { key: string } }) => {
      if (e.nativeEvent.key === 'Enter' && !disableButton) {
        handleSend()
      }
    }

    return (
      <View style={tw.style('items-center justify-center')}>
        {width > 900 ? (
          <View style={tw`bg-white p-4 w-2/3 rounded-3xl`}>
            <TextInput
              ref={ref || textInputRef}
              style={[
                tw`border-none p-2 h-32 text-text-1 `,
                {
                  fontFamily: 'Nunito-Regular',
                  fontSize: 18,
                  borderColor: isFocused ? tw.color('gray-300') : 'transparent',
                  borderWidth: isFocused ? 0.5 : 0,
                  borderRadius: 8,
                },
                { outline: 'none' },
              ]}
              value={message}
              onChangeText={setMessage}
              placeholder='Type your text here'
              placeholderTextColor={tw.color('text-3')}
              multiline
              numberOfLines={4}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onSubmitEditing={handleSend}
              onKeyPress={handleKeyPress}
              editable={!disableButton}
            />
            <View style={tw`flex-row justify-end mt-2`}>
              <ButtonIcon
                icon={{
                  type: 'SAX',
                  name: 'Send',
                  color: 'white',
                  variant: 'Bold',
                }}
                tone='primary'
                onPress={handleSend}
                disabled={disableButton}
              />
            </View>
          </View>
        ) : (
          <View style={tw`p-4 flex-1 flex-row w-full`}>
            <View
              style={[
                tw.style('flex-1 p-3 rounded-full bg-white flex-row ', {
                  borderColor: tw.color('primary-400'),
                  borderWidth: 2,
                  height: inputHeight,
                }),
              ]}
            >
              <TextInput
                ref={ref || textInputRef}
                style={[
                  tw`flex-1 text-gray-600 px-2`,
                  {
                    fontFamily: 'Nunito-Bold',
                    fontSize: 18,
                    borderWidth: 0,
                    borderColor: 'transparent',
                  },
                  Platform.OS === 'web' && { outline: 'none' },
                ]}
                autoFocus
                value={message}
                onChangeText={setMessage}
                placeholder='Type your text here'
                placeholderTextColor={tw.color('gray-400')}
                multiline
                onContentSizeChange={e =>
                  handleContentSizeChange(
                    e.nativeEvent.contentSize.width,
                    e.nativeEvent.contentSize.height,
                  )
                }
                onSubmitEditing={handleSend}
                onKeyPress={handleKeyPress}
                editable={!disableButton}
              />
              <View style={tw.style('justify-center')}>
                <ButtonIcon
                  icon={{
                    type: 'SAX',
                    name: 'LampCharge',
                    color: tw.color('icon'),
                  }}
                  tone='third'
                  onPress={handleSuggestPress}
                />
              </View>
            </View>
            <View style={tw.style('pl-2 justify-center')}>
              <ButtonIcon
                icon={{
                  type: 'SAX',
                  name: message.trim() ? 'Send' : 'Microphone2',
                  color: 'white',
                  variant: 'Bold',
                }}
                style={tw.style('px-4 py-4')}
                size={'large'}
                tone='primary'
                onPress={message.trim() ? handleSend : handleMicIconPress}
                disabled={disableButton}
              />
            </View>
          </View>
        )}
      </View>
    )
  },
)
